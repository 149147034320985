.navigation {
  background-color: $c_aqua_dark-20;
  height: 100%;
  width: $mainBarWidth;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  transition: all 0.3s ease;
  z-index: 3;
  display: flex;
  flex-direction: column;

  &--color {
    &-blue {
      background-color: $c_blue_dark_10;
      //background: linear-gradient(180deg, #019cd5 0%, #001f33 100%);
    }
    &-red {
      background-color: #eb6a4f;
      //background: linear-gradient(180deg, #019cd5 0%, #001f33 100%);
    }
  }

  .menu-open & {
    width: $mainBarOpenWidth;
  }

  @import 'gradient';
  @import 'item';
  @import 'list';
  @import 'logo';
  @import 'logout';
  @import 'overlay';
  @import 'submenu';
  @import 'toggle';
}

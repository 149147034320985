&__legend {
  &-text {
    align-items: center;
    color: #a6a6a6;
    display: flex;
    margin: 0 1px;
    p {
      margin-left: .5rem;
    }
  }
  &-title {
    margin-bottom: .5rem;
    width: 100%;
  }
  &-icon {
    height: 13px;
    width: 13px;
    &--half {
      @include triangle(7.5px, 7.5px, 'up', black);
    }
    &--green {
      background-color: $c_aqua_dark_10;
    }
    &--light-green {
      margin-left: -13px;
      border-width: 0 13px 13px 0;
      border-color: transparent $c_mint2 transparent transparent;
    }
    &--light-green,
    &--yellow {
      width: 0;
      height: 0;
      border-style: solid;
    }
    &--red {
      background-color: $c_coral_dark_20;
    }
    &--yellow {
      border-width: 13px 0 0 13px;
      border-color: transparent transparent transparent $c_amber;
    }
  }
}
&__icon {
    &-left {
        fill: $c_aqua_dark_10;
        flex-shrink: 0;
        height: 40px !important;
        margin-right: 1em;
        width: 40px !important;
    }
    &-right {
        cursor: pointer;
        fill: $c_chrome_120;
        flex-shrink: 0;
        margin-left: auto;
    }
}
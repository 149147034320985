&__status-button {
  background: none;
  border: none;
  cursor: pointer;
  flex-shrink: 0;
  height: 25px;
  margin-left: 0.3rem;
  padding: 0;
  width: 25px;

  &:focus {
    outline: none;
  }

  &-icon {
    &-circle {
      fill: none;
      stroke: $c_chrome_120;
      transition: all 0.3s ease;

      .knowledge-element--complete & {
        fill: $c_aqua_dark_10;
        stroke: $c_aqua_dark_10;
      }
    }

    &-check {
      fill: $c_chrome_120;
      transition: all 0.3s ease;

      .knowledge-element--complete & {
        fill: $c_white;
      }
    }
  }
}

&__file {
  align-items: center;
  display: flex;
  min-height: 80px;
  padding: 1rem;

  &-error {
    color: $c_coral_dark_10;
    font-size: 12px;
    line-height: 1;
  }

  &-icon {
    width: 1.3rem !important;
    height: 1.3rem !important;
    fill: $c_aqua_dark_10 !important;

    .file-upload-box--error & {
      fill: $c_coral_dark_10 !important;
    }
  }

  &-icon-wrapper {
    align-items: center;
    border-radius: 3rem;
    border: 1px solid $c_aqua_dark_10;
    display: flex;
    flex-shrink: 0;
    height: 2.5rem;
    justify-content: center;
    margin-right: 1rem;
    width: 2.5rem;

    .file-upload-box--error & {
      border-color: $c_coral_dark_10 !important;
    }
  }

  &-info {
    flex-grow: 1;
  }

  &-name {
    display: flex;
    align-items: center;
  }

  &-remove {
    @extend %reset-button;
    cursor: pointer;
    margin-left: 0.5rem;

    &-icon {
      fill: $c_chrome_80;
      width: 1rem !important;
      height: 1rem !important;
    }
  }

  &-status {
    font-size: $uiFontSizeSmall;
    color: $c_txt_grey-3;
    margin-left: 0.5rem;
  }
}

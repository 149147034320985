&__persons {
    background-color: $c_chrome_240;
    display: flex;
    min-height: 20px;
    overflow: hidden;
    padding: 0 24px;
    position: relative;
    width: 100%;
    z-index: 1;
    &-icon {
        fill: $c_aqua_dark_10;
        flex-shrink: 0;
        margin-right: .5em;
        height: 17px !important;
        width: 17px !important;
    }
    &-person {
        @extend %card-border; 
        align-items: center;
        background-color: $c_white;
        display: flex;
        flex-basis: 0;
        flex-grow: 1;
        justify-content: center;
        margin-left: 6px;
        min-width: 50px;
        overflow: hidden;
        padding: 0 .5em;
    }
    &-name {
        @extend %truncate;
        color: $c_prime_dark_20;
    }
}
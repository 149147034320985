&__icon {
  flex-shrink: 0;
  &-left {
    fill: $c_chrome_170;
    margin-right: 0.5em;

    &--green {
      fill: $c_aqua_dark_10;
    }
    &--red {
      fill: $c_coral_dark_10;
    }

    .context-menu-item--form & {
      align-self: flex-start;
    }
    .context-menu-item--disabled & {
      fill: $c_chrome_200;
    }
  }
  &-right {
    fill: $c_chrome_170;
  }
}

&__cell {
  /* .simple-table--spacing-sm tbody .simple-table__row:first-of-type & {
    padding-top: 0.5rem;
} */
  .simple-table--spacing-sm & {
    padding: 0.25rem 0;
  }
  .simple-table--spacing-sm tbody tr:first-of-type & {
    padding-top: 0.5rem;
  }
  .simple-table--cell-padding-md & {
    padding: 0.5rem 0;
  }
  .simple-table--vertical-align-top & {
    vertical-align: top;
  }

  &--align {
    &-left {
      text-align: left;
    }
    &-center {
      text-align: center;
    }
    &-right {
      text-align: right;
    }
  }
}

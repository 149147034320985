.no-access {
  align-items: center;
  background: $c_aqua_dark_10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  &__title {
    color: $c_white;
  }
  &__body {
    color: $c_white;
    max-width: 43rem;
  }
}

.main-content {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: $main-header-height;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease;

  &--fullscreen {
    background-color: $c_chrome_240;
    height: 100%;
    left: 0 !important;
    position: fixed;
    top: 0;
    transition: all 0.3s ease;
    width: 100%;
  }

  &--light {
    background: $c_white;
  }

  &--show-scroll {
    overflow-y: scroll;
  }

  .main-body--splitview & {
    left: $main-aside-width;
  }

  &::-webkit-scrollbar {
    height: 0.5em;
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $c_aqua_dark_10;
    outline: 1px solid slategrey;
  }
}
.champ-link-form {
  padding: 0 1.5rem;
  text-align: center;
  margin: 3rem auto 0;
  transition: margin 0.3s ease;
  .champ-link__sidebar & {
    max-width: 419px;
  }
  .champ-link & {
    margin: 5rem auto 0;
  }
  .champ-link--active & {
    margin: 3rem auto 0;
  }
  @import 'button';
  @import 'icon';
  @import 'input';
  @import 'link';
}

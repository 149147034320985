.scrollable-tabs {
  @include custom-scrollbar();
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  overflow: auto;
  .aside & {
    margin: 1rem;
  }
  .designboard-header & {
    height: 4rem;
  }
  @import 'active-background';
  @import 'tab';
}

&__person-counter {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin-right: .5rem;
  color: $c_black;
  &:last-of-type {
    margin-right: 0;
  }
  &-text {
    margin-right: 0.25rem;
  }
}

.button {
  align-items: center;
  background: $c_prime_light_20;
  border-color: $c_prime_light_10;
  border-radius: $bd_radius_sml;
  border: none;
  color: $c_prime_dark_20;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  font-size: $fontSize-md;
  height: 3rem;
  justify-content: center;
  padding: 0 1rem;

  &:hover {
    background-color: $c_prime_light_10;
    border-color: $c_prime_light_10;
    color: $c_prime_dark_20;
  }

  &[disabled] {
    opacity: 0.7;
    cursor: auto;
    pointer-events: none;
  }
  &--full-width {
    width: 100%;
  }
  &--size {
    &-small, &-sm {
      height: 2rem;
      padding: 0 0.5rem;
    }
  }

  &--square {
    border-radius: 0;
  }

  &--color {
    &-link-style {
      background: none;
      height: auto;
      padding: 0;
      &:hover {
        background: none;
      }
    }

    &-progress {
      background-color: $c_mint2;
      border-color: darken($c_mint2, 5%);
      color: #ffffff;

      &:hover {
        background-color: darken($c_mint2, 3%);
        border-color: darken($c_mint2, 5%);
        color: #ffffff;
      }
    }

    &-inactive {
      cursor: auto !important;
      background-color: $c_chrome_230;
      border-color: $c_chrome_230;
      color: $c_chrome_210;

      &:hover {
        background-color: $c_chrome_230;
        border-color: $c_chrome_230;
        color: $c_chrome_210;
      }
    }

    &-action {
      background-color: $c_prime_dark_10;
      border-color: $c_prime_dark_10;
      color: #ffffff;

      &:hover {
        background-color: $c_prime_dark_20;
        border-color: $c_prime_dark_20;
        color: #ffffff;
      }
    }

    &-darkui {
      background-color: $ui-dark--bg;
      border-color: darken($ui-dark--bg, 3%);
      color: #ffffff;

      &:hover {
        background-color: darken($ui-dark--bg, 1%);
        border-color: darken($ui-dark--bg, 3%);
        color: #ffffff;
      }
    }

    &-deep {
      background-color: $ui-dark-bg;
      border-color: darken($ui-dark-bg, 5%);
      color: #ffffff;

      &:hover {
        background-color: darken($ui-dark-bg, 3%);
        border-color: darken($ui-dark-bg, 5%);
        color: #ffffff;
      }
    }

    &-alert {
      background-color: $c_coral_dark_10;
      border-color: darken($c_coral_dark_10, 5%);
      color: #ffffff;

      &:hover {
        background-color: darken($c_coral_dark_10, 3%);
        border-color: darken($c_coral_dark_10, 5%);
        color: #ffffff;
      }
      &.button--style-ghost {
        background-color: transparent;
        border: none;
        color: $c_coral_dark_20;
      }
    }

    &-light-grey {
      background-color: $c_chrome_230;
      border-color: $c_chrome_230;
      color: $c_chrome_100;

      &:hover {
        background-color: $c_chrome_220;
        border-color: $c_chrome_220;
        color: $c_chrome_100;
      }
    }

    &-cancel {
      background-color: $c_chrome_180;
      border-color: $c_chrome_180;
      color: #ffffff;

      &:hover {
        background-color: $c_chrome_190;
        border-color: $c_chrome_190;
        color: #ffffff;
      }
    }

    &-invisible {
      background-color: transparent;
      border-color: transparent;
      color: $c_txt_grey-0;

      .icon {
        fill: $c_txt_grey-2;
      }

      &:hover {
        background-color: transparent;
        border-color: transparent;
        color: $c_txt_grey-0;

        .icon {
          fill: $c_txt_grey-1;
        }
      }
    }

    &-shy {
      background-color: transparent;
      border-color: transparent;
      color: $c_txt_grey-0;

      &[disabled] {
        opacity: 1;
        &:hover {
          background-color: transparent;
          border-color: transparent;
        }
      }

      .icon {
        fill: $c_txt_grey-2;
      }

      &:hover {
        background-color: $c_subtle_grey_light_20;
        border-color: $c_subtle_grey_light_20;
        color: $c_txt_grey-0;

        .icon {
          fill: $c_txt_grey-1;
        }
      }

      &.active {
        background-color: darken($ui-dark-bg, 3%);
        border-color: darken($ui-dark-bg, 5%);
        color: #ffffff;
      }
    }

    &-slim {
      background-color: transparent;
      border-color: transparent;
      color: $c_txt_grey-3;

      &:hover {
        background-color: transparent;
        border-color: transparent;
        color: $c_txt_grey-0;
      }

      &.active {
        background-color: transparent;
        border-color: transparent;
        color: $c_blue_dark_10;
      }
    }
  }

  .icon {
    fill: currentColor;
  }

  @import 'icon';
}

.organisation-statusbox-role {
  border-bottom: 1px solid $c_chrome_210;
  height: 35px;
  align-items: center;
  cursor: pointer;
  display: flex;
  //flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  height: 35px;
  padding: 0 0.25rem;
  &--highlight {
    background-color: $c_yellow_light_20;
  }
  .organisation-board-unit--read-only & {
    cursor: auto;
  }

  @import 'content';
  @import 'dash';
  @import 'mentorship';
  @import 'point';
  @import 'star';
}

&__trigger {
  @extend %reset-button;
  cursor: pointer;
  flex-shrink: 0;;
  width: 3rem;
  border-left: 1px solid $c_subtle_grey_light_10;
  &-icon {
    height: 1rem;
    width: 1rem;
    fill: $c_chrome_170;    
  }
}

&__table {
  background-color: $c_white;
  flex-grow: 1;
  height: 100%;

  .ReactVirtualized__Grid,
  .ReactVirtualized__List {
    @include custom-scrollbar(0.75rem);
  }

  &__bottom-left-grid {
    &::-webkit-scrollbar {
      display: none;
    }
    &:has(div):has(.status-table__table-no-content) {
      background-color: $c_white;
      left: 0 !important;
      right: 0;
      width: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
  }
}

&__section {
  margin-bottom: 2rem;
  &-body {
    padding: 0 2rem;
  }
  &-email-text {
    margin-bottom: 1rem;
  }
  &-field {
    max-width: 500px;
    margin-bottom: 1rem;
  }
  &-header {
    align-items: center;
    display: flex;
    margin-bottom: 2em;
    border-bottom: 1px solid $c_bd_grey_base;
    padding: 1rem;
  }
  &-password-button {
  }
  &-title {
    color: $c_chrome_120;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }
  &-title-icon {
    fill: $c_chrome_120;
  }
}

.add-element-box {
  padding: 0.5rem;
  background-color: $c_white;
  display: flex;


  .editor & {
    padding: 0.5rem 1rem;
  }
  
  @import 'button';
  @import 'input';
  @import 'left';
  @import 'right';
}

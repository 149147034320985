&__count {
  position: relative;
  top: -9px;
  left: 2px;
  font-size: 11px;
  .icon-with-count--size-x-small & {
    top: -7px;
    left: 2px;
    font-size: 9px;
  }
  .icon-with-count--type-button:hover & {
    color: $c_blue_dark_10;
  }
  .icon-with-count--color-blue & {
    color: $c_blue_dark_10;
  }
  .icon-with-count--color-green & {
    color: $c_aqua_dark_20;
  }
  .icon-with-count--color-grey & {
    color: $c_chrome_120;
  }
  .icon-with-count--color-light-grey & {
    color: $c_chrome_180;
  }
  .icon-with-count--color-red & {
    color: $c_coral_dark_20;
  }
}

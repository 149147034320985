&__add-role {
  display: flex;
  height: 35px;
  overflow: hidden;
  width: 100%;

  .organisation-board-unit--read-only & {
    display: none;
  }

  &-form {
    align-items: center;
    display: flex;
    min-width: 245px;
    padding: 0 10px 0 1rem;
    width: 245px;

    &-input {
      border: none;
      font-size: 12px;
      padding-right: 5px;
      width: 100%;
    }

    &-btn {
      border: none;
      border-radius: 3px;
      font-size: 12px;
      height: auto;
      padding: 2px 10px;
    }
  }

  &-text {
    color: $c_blue_dark_20;
    font-size: 12px;
    padding: 0 5px;

    &-wrapper {
      align-items: center;
      cursor: pointer;
      display: flex;
      min-width: 245px;
      padding-left: 1rem;
      width: 245px;
    }
  }

  &-icon {
    fill: $c_aqua_dark_10;
    height: 18px !important;
    width: 18px !important;
  }
}
&__options {
  align-items: center;
  display: flex;
  margin-left: auto;
  &-add-btn {
    border: none;
    border-radius: 3px;
    height: auto;
    padding: 0.5em 1em;
  }
  &-checkbox {
  }
  &-icon-wrapper {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-right: 0.5em;
  }
  &-icon-cog {
    transition: fill 0.3s ease;
    .editor-element.expanded & {
      fill: $c_blue_dark_10;
    }
  }
  &-icon-cog,
  &-icon-delete {
    fill: $c_chrome_120;
  }
  &-checkbox {
    margin-left: 1rem;
  }
}

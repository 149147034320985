@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin box_shadow($level) {
  @if $level==1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.16);
  } @else if $level==2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.11), 0 3px 6px rgba(0, 0, 0, 0.15);
  } @else if $level==3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0, 0, 0, 0.15);
  } @else if $level==4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.17), 0 10px 10px rgba(0, 0, 0, 0.15);
  } @else if $level==5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.2), 0 15px 12px rgba(0, 0, 0, 0.15);
  }
  @if $level==-1 {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.08),
      inset 0 1px 2px rgba(0, 0, 0, 0.16);
  } @else if $level==-2 {
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.11),
      inset 0 3px 6px rgba(0, 0, 0, 0.15);
  } @else if $level==-3 {
    box-shadow: inset 0 10px 20px rgba(0, 0, 0, 0.13),
      inset 0 6px 6px rgba(0, 0, 0, 0.15);
  } @else if $level==-4 {
    box-shadow: inset 0 14px 28px rgba(0, 0, 0, 0.17),
      inset 0 10px 10px rgba(0, 0, 0, 0.15);
  } @else if $level==-5 {
    box-shadow: inset 0 19px 38px rgba(0, 0, 0, 0.2),
      inset 0 15px 12px rgba(0, 0, 0, 0.15);
  }
}

@mixin custom-scrollbar($width: 0.5em) {
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $c_aqua_dark_10;
    outline: none;
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc($height / $width) * 100%;
  }

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin triangle($width: 20px, $height: 20px, $direction: down, $color: pink) {
  width: 0;
  height: 0;

  // Right
  @if $direction==right {
    border-top: calc($height / 2) solid transparent;
    border-bottom: calc($height / 2) solid transparent;
    border-left: $width solid $color;
  }

  // Left
  @if $direction==left {
    border-top: calc($height / 2) solid transparent;
    border-bottom: calc($height / 2) solid transparent;
    border-right: $width solid $color;
  }

  // Up
  @if $direction==up {
    border-left: calc($width / 2) solid transparent;
    border-right: calc($width / 2) solid transparent;
    border-bottom: $height solid $color;
  }

  // Down
  @if $direction==down {
    border-left: calc($width / 2) solid transparent;
    border-right: calc($width / 2) solid transparent;
    border-top: $height solid $color;
  }
}

@mixin clearfix {
  &:after {
    visibility: hidden;
    display: block;
    content: '';
    clear: both;
    height: 0;
  }
}

@mixin resetAnchor {
  a {
    color: $c_black;
  }
}

@mixin resetList {
  ul,
  li,
  & ul,
  & li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

@mixin competenceMatrixCell {
  $breakpointStepSize: 80;
  $widthDividerStepSize: 2;
  border-bottom: 1px solid $c_chrome_210;
  height: 35px;
  min-width: calc(100% / 10);

  @for $i from 0 through 20 {
    @media (min-width: #{(1120+($breakpointStepSize * $i))}px) {
      min-width: calc(100% / #{(10 + ($widthDividerStepSize * $i))});
    }
  }
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

.main-body {
  height: 100%;
  &--fullscreen {
    background-color: $c_chrome_240;
    height: 100%;
    left: 0 !important;
    margin: 0;
    position: fixed;
    top: 0;
    transition: all 0.3s ease;
    width: 100%;
    z-index: 3;
  }
}

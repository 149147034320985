&__moving-actions {
  display: flex;
  padding: 1rem;
  align-items: center;
  background-color: $c_blue_light_20;

  &-left {
    flex-grow: 1;
  }
  &-right {
    button {
      margin-left: 0.5rem;
    }
  }
  &-wrapper {
    flex-shrink: 0;
  }
}

&__star {
  display: flex;
  &-icon {
    fill: $c_aqua_dark_10;
    height: 10px !important;
    width: 10px !important;
    .organisation-statusbox-role--theme-red & {
      fill: $c_coral_dark_10;
    }
    .organisation-statusbox-role--theme-grey & {
      fill: $c_chrome_210;
    }
  }
}

.password-requirements-checker {
  background-color: $c_chrome_230;
  padding: 1rem;
  border-radius: $bd_radius_sml;
  font-size: $fontSize-sm;
  margin-bottom: 1.5rem;

  @import 'requirement';
  @import 'requirements-list';
}

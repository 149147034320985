&__options {
  align-items: center;
  display: flex;
  margin-left: auto;
  &-add-btn {
    border: none;
    border-radius: 3px;
    height: auto;
    font-size: 12px;
    padding: 0.3em 12px;
  }
  &-checkbox {
    margin-left: 0.25rem;
  }
  &-delete-icon {
    cursor: pointer;
    fill: $c_coral_dark_20;
    transition: opacity 0.3s ease;
  }
  &-icon {
    fill: $c_chrome_120;
    height: 0.75rem !important;
    margin-left: 0.25rem;
    width: 0.75rem !important;
    .designboard-knowledge-element__options-icon-wrapper:hover & {
      fill: $c_prime_dark_20;
    }
    &-wrapper {
      align-items: center;
      justify-content: center;
      cursor: pointer;
      display: flex;
      height: 2.5rem;
    }
  }
  &-context-menu-icon {
    fill: $c_chrome_170;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2.5rem;
    .icon {
      margin: 0;
    }
  }

  &-role-count {
    margin-left: 0.25rem;
  }
}

.rt-editor {
  background-color: $c_chrome_250;
  border: 1px solid $c_chrome_230;

  &--invisible {
    background-color: transparent;
  }

  .modal-content__body & {
    display: flex;
    flex-direction: column;
  }

  .public-DraftStyleDefault-block {
    margin: 0 !important;
  }

  @import 'editor';
  @import 'toolbar';
}

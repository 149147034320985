&__editor {
  max-height: 400px;
  min-height: 200px;
  padding: 1rem 1rem 2.5em;
  user-select: text !important;
  .rt-editor--invisible & {
    padding: 0;
    max-height: none;
    overflow: unset;
  }
}

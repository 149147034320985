.progress-bar {
  border-radius: $bd_radius_sml;
  height: 2px;
  background-color: $c_aqua_light_10;
  margin: 5px 0;
  max-width: 200px;
  overflow: hidden;
  &--rounded {
    border-radius: 999px;
  }
  &--size {
    &-lg {
      max-width: none;
      height: 0.5rem;
    }
  }
  @import 'progress';
}

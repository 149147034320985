&__inner {
  align-items: center;
  color: $c_aqua_dark_10;
  display: flex;
  height: 32px;
  justify-content: center;
  position: relative;
  text-align: center;
  transition: all 0.3s ease;
  width: 100%;

  // All statusboxes when changing mediator
  .dashboard--changing-element-mediator .dashboard-knowledge-area--expanded & {
    cursor: auto;
    opacity: 0.2;
    &:hover {
      box-shadow: none;
    }
  }
  // All statusboxes that are not in expanded area
  .dashboard--area-expanded & {
    cursor: auto;
    opacity: 0.2;
    &:hover {
      box-shadow: none;
    }
  }
  // Statusboxes in the expanded area
  .dashboard-knowledge-area--expanded & {
    //cursor: pointer;
    opacity: 1;
    &:hover {
      @include box_shadow(2);
    }
  }
  .statusbox-knowledge-area--selected & {
    @include box_shadow(4);
    background: $c_blue_dark_20;
    color: white;
  }
  &:first-of-type {
    margin-left: 0px;
  }
  &--additional {
    cursor: pointer;
    &:hover {
      @include box_shadow(2);
    }
  }
  &--complete {
    background: $c_aqua_dark_10;
    color: $c_white;
  }
  &--incomplete {
    background: $c_mint2;
  }
  &--unlinked {
    background-color: $c_chrome_220;
  }
  &-icon {
    transition: fill 0.3s ease;
    .statusbox-knowledge-area--selected & {
      fill: $c_white !important;
    }
    .statusbox-knowledge-area__inner--complete & {
      fill: $c_white;
    }
    .statusbox-knowledge-area__inner--incomplete & {
      fill: $c_aqua_dark_10;
    }
    .statusbox-knowledge-area__inner--unlinked & {
      fill: $c_chrome_140;
    }
  }
}

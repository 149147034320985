.notification-counter {
  align-items: center;
  background: $c_coral_dark_10;
  border-radius: 0.8rem;
  color: $c_white;
  display: inline-flex;
  font-size: $fontSize-sm;
  font-weight: bold;
  height: 1.3rem;
  justify-content: center;
  min-width: 1.8rem;

  &--button {
    cursor: pointer;
  }

  // TODO refactor
  &--small {
    font-size: 9px;
    height: 12px;
    min-width: 1rem;
    padding: 2px 0.6em;
  }
  &--color {
    &-blue {
      background: $c_blue_dark_10;
    }
    &-grey {
      background-color: $c_chrome_180;
    }
  }
  &--size {
    &-small {
      font-size: 9px;
      height: 1rem;
      min-width: 1.5rem;
      padding: 2px 0.6em;
    }
  }
  @import "count";
  @import "icon";
}

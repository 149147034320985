.card-link {
  @extend %card-border;
  @include box_shadow(1);
  align-self: stretch;
  align-items: center;
  background-color: $c_white;
  cursor: pointer;
  display: flex;
  flex-basis: 100%;
  margin: 0 10px 1rem;
  min-width: 0;
  padding: 0 1rem;
  transition: all 0.3s ease;
  min-height: 3rem;
  &:hover {
    @include box_shadow(2);
  }
  @media (min-width: 1024px) {
    flex-basis: calc(100% / 2 - 20px);
  }
  @media (min-width: 1200px) {
    flex-basis: calc(100% / 3 - 20px);
  }
  @import 'icon';
  @import 'text';
  @import 'wrapper';
}

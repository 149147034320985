.load-overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  &--fixed {
    position: fixed;
    z-index: $z-index-load-overlay;
  }
  &--color-light {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

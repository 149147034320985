&__table {
  background-color: $c_white;
  flex-grow: 1;

  &-cell-required {
    color: $c_coral_dark_10;
    position: absolute;
    right: 0px;
    top: 0px;
    font-weight: bold;
    font-size: 1.5rem;
    z-index: 1;
    pointer-events: none;
    bottom: 0;
  }

  .ReactVirtualized__Grid,
  .ReactVirtualized__List {
    @include custom-scrollbar(0.75rem);
  }
}


&__highlight {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: $c_coral_dark_10;
  color: $c_white;
  .knowledge-element--valid & {
    background-color: $c_chrome_200;
    color: $c_white;
  }
  span {
    transform: translate(-50%, -50%) rotate(-90deg);
    left: 50%;
    top: 50%;
    font-size: $fontSize-xxs;
    font-weight: bold;
    text-transform: uppercase;
    text-wrap: nowrap;
    position: absolute;
    transform-origin: center;
  }
}

&__mediator {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 .5em;
    width: 100%;
    &-initials {
        border-bottom: none !important;
        text-decoration: none !important;
    }
    &-name {
        @extend %truncate;
        font-size: 12px;
    }
    &-lock {
        fill: currentColor;
        height: 12px !important;
        margin-right: 0.3em;
        vertical-align: text-top;
        width: 12px !important;
    }
}
&__title {
  @extend %card-border;
  align-items: center;
  background-color: $c_white;
  display: flex;
  border-radius: $bd_radius_sml;
  flex-basis: 0px;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0px;
  padding-left: 0.5em;
  height: 2.5rem;
  text-align: left;
  user-select: none;
  &-expand-icon {
    display: block;
    fill: $c_chrome_120;
    flex-shrink: 0;
    height: 12px !important;
    transition: transform 0.3s ease;
    width: 12px !important;
    .designboard-knowledge-area--expanded & {
      transform: rotateX(180deg);
    }
    .designboard-knowledge-area__title-expand:hover & {
      fill: $c_blue_dark_10;
      stroke-width: 1;
      stroke: $c_blue_dark_10;
    }
  }
  &-disconnect-icon {
    fill: $c_coral_dark_10;
    flex-shrink: 0;
  }
  &-expand {
    width: 1rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  &-expansion-wrapper {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    min-width: 0px;
  }
  &-icon {
    fill: $c_aqua_dark_10;
    flex-shrink: 0;
    margin-right: 0.5em;
    .designboard-knowledge-area--no-access & {
      fill: $c_chrome_180;
    }
  }
  &-input {
    background: none;
    font-size: 13px;
    height: auto;
    padding: 0;
    &:focus {
      background: none;
      box-shadow: none;
    }
  }
  &-name {
    align-items: center;
    color: $c_prime_dark_20;
    display: flex;
    font-size: 13px;
    margin-right: auto;
    min-width: 0;
    .designboard-knowledge-area--no-access & {
      color: $c_chrome_120;
    }
    &-icon {
      cursor: pointer;
      fill: $c_chrome_120;
      flex-shrink: 0;
      height: 15px !important;
      margin-left: 0.5em;
      opacity: 0;
      transition: opacity 0.3s ease;
      width: 15px !important;
      .designboard-knowledge-area__title-name:hover & {
        opacity: 1;
      }
    }
    &-text {
      @extend %truncate;
    }
  }
  &-context-menu-icon {
    fill: $c_chrome_170;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2.5rem;
    .icon {
      margin: 0;
    }
  }

  &-role-count {
    margin-left: 1rem;
  }
}

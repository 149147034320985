&__text {
  line-height: 1;
  margin-top: 0.35rem;
  font-size: $fontSize-xxs;
  color: $c_chrome_100;
  display: flex;
  align-items: center;
  .icon {
    fill: currentColor;
    margin-right: 2px;
  }
  .status-button--size-small & {
    margin-top: 2px;
  }
  .status-button--color-green &,
  .status-button--color-yellow &,
  .status-button--color-red & {
    color: $c_white;
  }
  .status-button--color-light-green & {
    color: $c_aqua_dark_10;
  }
  .status-button--color-white & {
    color: $c_chrome_120;
  }
}

&__item {
  margin: 0;
  &--child {
    padding-left: 24px;
  }
  &-text {
    max-width: 100%;
    padding: 0 24px 0 36px;
    user-select: none;
  }
  &-text-count {
    margin-left: 0.5em;
  }
  &-text-unitname {
    @extend %truncate;
  }
  &-wrapper {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 60px;
    margin-left: -100%;
    padding-left: 100%;
    transition: background-color 0.3s ease;
    user-select: none;
    &.selected {
      background-color: $c_chrome_240;
    }
  }
}

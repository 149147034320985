.person-card {
  align-items: center;
  border-bottom: 1px solid $c_bd_grey_base;
  display: flex;
  flex-shrink: 0;
  padding: 1em;
  @import 'icon';
  @import 'img';
  @import 'info';
}

&__info {
  flex-grow: 1;
    &-category {
        color: $c_txt_grey-3;
        margin-top: $gutter-xs;
    }
    &-mentor {
        color: $c_txt_grey-3;
    }
    &-mentor-icon {
        margin-right: $gutter-xs;
        margin: 0 8px;
        transform: translateY(2px);
    }
    &-title {
      color: $c_txt_blue-0;
      font-size: $fontSize-lg;
      margin-bottom: 0.2em;
    }
}
&__button {
  @extend %reset-button;
  cursor: pointer;
  align-items: center;
  display: flex;
  padding: 1rem 1.5rem;
  min-height: 3.75rem;
  width: 100%;
  transition: background-color 0.3s ease;
  &--state-active {
    background-color: $c_chrome_240;
  }
  .side-nav-item--small & {
    padding: 0 1.5rem;
    min-height: auto;
    height: 2.5rem;
  }
}

&__mediator {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 .5em;
  width: 100%;
  &-initials {
      border-bottom: none !important;
      text-decoration: none !important;
  }
  &-name {
      @extend %truncate;
      font-size: 12px;
  }
}
&__context-menu {
  &-trigger {
    width: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      margin: 0;
    }
  }
}

&__editor {
  flex-grow: 1;
  padding: 1.5em 24px 10em;
  &-help-text {
    color: $c_chrome_120;
    font-size: 12px;
    font-style: italic;
    margin: 1em 0;
  }
}

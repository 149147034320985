&__spinner {
    animation: pulse .5s ease-out infinite alternate;
    left: 50%;
    position: absolute;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;

    &-body {
        fill:#66adab;
    }
    &-eye {
        fill:#82d1c9;
    }

    @keyframes pulse {
        0% {
            transform: translate(-50%, -50%) scale(0.8);
        }
        100% {
            transform: translate(-50%, -50%) scale(1);
        }
    }
}
&__sidebar {
  &-reset-button {
    margin: 2rem 1rem;
  }
  &-bullet {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 0.25rem;
    &--color-white {
      border: 1px solid $c_bd_grey_dark;
      background-color: $c_white;
    }
    &--color-green {
      background-color: $c_aqua_dark_10;
    }
    &--color-yellow {
      background-color: $c_amber;
    }
    &--color-red {
      background-color: $c_coral_dark_10;
    }
    &--color-light-green {
      background-color: $c_mint2;
    }
  }
}

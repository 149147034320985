&__label {
  color: $c_txt_grey-3;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &--green {
    color: $c_aqua_dark_20;
  }
}

.__react_component_tooltip {
  border-radius: 3px;
  display: inline-block;
  font-size: 13px;
  left: -999em;
  opacity: 0;
  padding: 8px 21px;
  position: fixed;
  transition: opacity 0.3s ease-out;
  top: -999em;
  visibility: hidden;
  z-index: 999999 !important;
}
.__react_component_tooltip.allow_hover,
.__react_component_tooltip.allow_click {
  pointer-events: auto;
}
.__react_component_tooltip:before,
.__react_component_tooltip:after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
}
.__react_component_tooltip.show {
  opacity: 0.9;
  margin-top: 0px;
  margin-left: 0px;
  visibility: visible;
}
.__react_component_tooltip.type-dark {
  color: #fff;
  background-color: #222;
}
.__react_component_tooltip.type-dark.place-top:after {
  border-top-color: #222;
  border-top-style: solid;
  border-top-width: 6px;
}
.__react_component_tooltip.type-dark.place-bottom:after {
  border-bottom-color: #222;
  border-bottom-style: solid;
  border-bottom-width: 6px;
}
.__react_component_tooltip.type-dark.place-left:after {
  border-left-color: #222;
  border-left-style: solid;
  border-left-width: 6px;
}
.__react_component_tooltip.type-dark.place-right:after {
  border-right-color: #222;
  border-right-style: solid;
  border-right-width: 6px;
}
.__react_component_tooltip.type-dark.border {
  border: 1px solid #fff;
}
.__react_component_tooltip.type-dark.border.place-top:before {
  border-top: 8px solid #fff;
}
.__react_component_tooltip.type-dark.border.place-bottom:before {
  border-bottom: 8px solid #fff;
}
.__react_component_tooltip.type-dark.border.place-left:before {
  border-left: 8px solid #fff;
}
.__react_component_tooltip.type-dark.border.place-right:before {
  border-right: 8px solid #fff;
}
.__react_component_tooltip.type-success {
  color: #fff;
  background-color: #8dc572;
}
.__react_component_tooltip.type-success.place-top:after {
  border-top-color: #8dc572;
  border-top-style: solid;
  border-top-width: 6px;
}
.__react_component_tooltip.type-success.place-bottom:after {
  border-bottom-color: #8dc572;
  border-bottom-style: solid;
  border-bottom-width: 6px;
}
.__react_component_tooltip.type-success.place-left:after {
  border-left-color: #8dc572;
  border-left-style: solid;
  border-left-width: 6px;
}
.__react_component_tooltip.type-success.place-right:after {
  border-right-color: #8dc572;
  border-right-style: solid;
  border-right-width: 6px;
}
.__react_component_tooltip.type-success.border {
  border: 1px solid #fff;
}
.__react_component_tooltip.type-success.border.place-top:before {
  border-top: 8px solid #fff;
}
.__react_component_tooltip.type-success.border.place-bottom:before {
  border-bottom: 8px solid #fff;
}
.__react_component_tooltip.type-success.border.place-left:before {
  border-left: 8px solid #fff;
}
.__react_component_tooltip.type-success.border.place-right:before {
  border-right: 8px solid #fff;
}
.__react_component_tooltip.type-warning {
  color: #fff;
  background-color: #f0ad4e;
}
.__react_component_tooltip.type-warning.place-top:after {
  border-top-color: #f0ad4e;
  border-top-style: solid;
  border-top-width: 6px;
}
.__react_component_tooltip.type-warning.place-bottom:after {
  border-bottom-color: #f0ad4e;
  border-bottom-style: solid;
  border-bottom-width: 6px;
}
.__react_component_tooltip.type-warning.place-left:after {
  border-left-color: #f0ad4e;
  border-left-style: solid;
  border-left-width: 6px;
}
.__react_component_tooltip.type-warning.place-right:after {
  border-right-color: #f0ad4e;
  border-right-style: solid;
  border-right-width: 6px;
}
.__react_component_tooltip.type-warning.border {
  border: 1px solid #fff;
}
.__react_component_tooltip.type-warning.border.place-top:before {
  border-top: 8px solid #fff;
}
.__react_component_tooltip.type-warning.border.place-bottom:before {
  border-bottom: 8px solid #fff;
}
.__react_component_tooltip.type-warning.border.place-left:before {
  border-left: 8px solid #fff;
}
.__react_component_tooltip.type-warning.border.place-right:before {
  border-right: 8px solid #fff;
}
.__react_component_tooltip.type-error {
  color: #fff;
  background-color: #be6464;
}
.__react_component_tooltip.type-error.place-top:after {
  border-top-color: #be6464;
  border-top-style: solid;
  border-top-width: 6px;
}
.__react_component_tooltip.type-error.place-bottom:after {
  border-bottom-color: #be6464;
  border-bottom-style: solid;
  border-bottom-width: 6px;
}
.__react_component_tooltip.type-error.place-left:after {
  border-left-color: #be6464;
  border-left-style: solid;
  border-left-width: 6px;
}
.__react_component_tooltip.type-error.place-right:after {
  border-right-color: #be6464;
  border-right-style: solid;
  border-right-width: 6px;
}
.__react_component_tooltip.type-error.border {
  border: 1px solid #fff;
}
.__react_component_tooltip.type-error.border.place-top:before {
  border-top: 8px solid #fff;
}
.__react_component_tooltip.type-error.border.place-bottom:before {
  border-bottom: 8px solid #fff;
}
.__react_component_tooltip.type-error.border.place-left:before {
  border-left: 8px solid #fff;
}
.__react_component_tooltip.type-error.border.place-right:before {
  border-right: 8px solid #fff;
}
.__react_component_tooltip.type-info {
  color: #fff;
  background-color: #337ab7;
}
.__react_component_tooltip.type-info.place-top:after {
  border-top-color: #337ab7;
  border-top-style: solid;
  border-top-width: 6px;
}
.__react_component_tooltip.type-info.place-bottom:after {
  border-bottom-color: #337ab7;
  border-bottom-style: solid;
  border-bottom-width: 6px;
}
.__react_component_tooltip.type-info.place-left:after {
  border-left-color: #337ab7;
  border-left-style: solid;
  border-left-width: 6px;
}
.__react_component_tooltip.type-info.place-right:after {
  border-right-color: #337ab7;
  border-right-style: solid;
  border-right-width: 6px;
}
.__react_component_tooltip.type-info.border {
  border: 1px solid #fff;
}
.__react_component_tooltip.type-info.border.place-top:before {
  border-top: 8px solid #fff;
}
.__react_component_tooltip.type-info.border.place-bottom:before {
  border-bottom: 8px solid #fff;
}
.__react_component_tooltip.type-info.border.place-left:before {
  border-left: 8px solid #fff;
}
.__react_component_tooltip.type-info.border.place-right:before {
  border-right: 8px solid #fff;
}
.__react_component_tooltip.type-light {
  color: #222;
  background-color: #fff;
}
.__react_component_tooltip.type-light.place-top:after {
  border-top-color: #fff;
  border-top-style: solid;
  border-top-width: 6px;
}
.__react_component_tooltip.type-light.place-bottom:after {
  border-bottom-color: #fff;
  border-bottom-style: solid;
  border-bottom-width: 6px;
}
.__react_component_tooltip.type-light.place-left:after {
  border-left-color: #fff;
  border-left-style: solid;
  border-left-width: 6px;
}
.__react_component_tooltip.type-light.place-right:after {
  border-right-color: #fff;
  border-right-style: solid;
  border-right-width: 6px;
}
.__react_component_tooltip.type-light.border {
  border: 1px solid #222;
}
.__react_component_tooltip.type-light.border.place-top:before {
  border-top: 8px solid #222;
}
.__react_component_tooltip.type-light.border.place-bottom:before {
  border-bottom: 8px solid #222;
}
.__react_component_tooltip.type-light.border.place-left:before {
  border-left: 8px solid #222;
}
.__react_component_tooltip.type-light.border.place-right:before {
  border-right: 8px solid #222;
}
.__react_component_tooltip.place-top {
  margin-top: -10px;
}
.__react_component_tooltip.place-top:before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: -8px;
  left: 50%;
  margin-left: -10px;
}
.__react_component_tooltip.place-top:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  bottom: -6px;
  left: 50%;
  margin-left: -8px;
}
.__react_component_tooltip.place-bottom {
  margin-top: 10px;
}
.__react_component_tooltip.place-bottom:before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  top: -8px;
  left: 50%;
  margin-left: -10px;
}
.__react_component_tooltip.place-bottom:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  top: -6px;
  left: 50%;
  margin-left: -8px;
}
.__react_component_tooltip.place-left {
  margin-left: -10px;
}
.__react_component_tooltip.place-left:before {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  right: -8px;
  top: 50%;
  margin-top: -5px;
}
.__react_component_tooltip.place-left:after {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  right: -6px;
  top: 50%;
  margin-top: -4px;
}
.__react_component_tooltip.place-right {
  margin-left: 10px;
}
.__react_component_tooltip.place-right:before {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  left: -8px;
  top: 50%;
  margin-top: -5px;
}
.__react_component_tooltip.place-right:after {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  left: -6px;
  top: 50%;
  margin-top: -4px;
}
.__react_component_tooltip .multi-line {
  display: block;
  padding: 2px 0px;
  text-align: center;
}

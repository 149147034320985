&__left {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  &-button {
    cursor: pointer;
    flex-grow: 1;
    background-color: $c_white;
    border-right: 1px solid $c_chrome_220;
    border-width: 0 1px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0.5rem;
    overflow: hidden;
  }
  .dashboard-knowledge-element--state-selectable & {
    border: 1px dashed $c_blue_dark_20;
    cursor: pointer;
  }
  .dashboard-knowledge-element--state-selected & {
    color: $c_white !important;
    background-color: $c_blue_dark_20;
    border: 1px solid $c_blue_dark_20;
  }
}

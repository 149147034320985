.role-card {
  @extend %card-border;
  @include box_shadow(1);
  align-items: center;
  background: $c_white;
  border-radius: $bd_radius_sml;
  display: flex;
  margin-bottom: 1rem;
  min-height: 86px;
  padding: $gutter-md;
  //padding-bottom: 1.8rem;
  position: relative;
  transition: all 0.2s ease;
  &--clickable {
    &:hover {
      @include box_shadow(2);
    }
  }
  @import 'arrow';
  @import 'badge';
  @import 'info';
}

&__user-badge {
  width: 2rem;
  height: 2rem;
  border-radius: 1.5rem;
  background-color: $c_aqua_dark_10;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $c_white;
  margin-left: 0.5rem;
  .main-header__right:hover & {
    opacity: 0.7;
  }
}

.status-table-header {
  @include box-shadow(1);
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  overflow: hidden;
  height: 0px;
  transition: height .3s ease;
  &--state-visible {
    height: 4rem;
  }
  @import 'action';
  @import 'button';
  @import 'text';
}

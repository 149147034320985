// Fonts
$fontFamily: Arial, 'Helvetica Neue', Helvetica, Verdana, sans-serif;
$fontSize-xxs: 10px;
$fontSize-xs: 11px;
$fontSize-sm: 12px;
$fontSize-md: 14px;
$fontSize-lg: 20px;
$fontSize-xl: 24px;

// Gutter
$gutter-xs-value: 6;
$gutter-sm-value: 12;
$gutter-md-value: 16;
$gutter-lg-value: 20;
$gutter-xl-value: 30;
$gutter-xxl-value: 56;
$gutter-xs: #{$gutter-xs-value}px;
$gutter-sm: #{$gutter-sm-value}px;
$gutter-md: #{$gutter-md-value}px;
$gutter-lg: #{$gutter-lg-value}px;
$gutter-xl: #{$gutter-xl-value}px;
$gutter-xxl: #{$gutter-xxl-value}px;

// Styles
$bd_radius_sml: 3px;
$bd_radius_lg: 6px;

// Typography
$list_item_height: 60px;
$paragraphMaxWidth: 660px;

// UI elements
$uiSmallHeight: 34px;
$uiMediumHeight: 50px;
$uiLargeHeight: 60px;

$uiFontSizeSmall: $fontSize-sm;
$uiFontSizeMedium: $fontSize-md;
$uiFontSizeLarge: $fontSize-lg;

$uiInputPadding: $gutter-sm;

//Shared compenents
$mainBarWidth: 70px;
$mainBarOpenWidth: 270px;

$main-aside-width: 280px;
$main-aside-width-wide: 420px;
$main-header-height: 4rem;

// Columns max width
$maxColumnWidth1: 320px;
$maxColumnWidth2: 600px;

// Z Index
$z-index-crisp-chatbox: 999999;
$z-index-dropdown: 10001;
$z-index-systemlayer: 10000;
$z-index-systemlayer-modal: 10100;

$z-index-datepicker: 1010;
$z-index-modal: 1010;
$z-index-portal-root: 1010;
$z-index-modal-shade: 1009;

$z-index-full-screen-aside: 1001;
$z-index-full-screen-aside-toggle: 1001;
$z-index-header: 1002;
$z-index-full-screen-main-area: 1002;
$z-index-overlay: 1003;
$z-index-full-screen-page: 1003;
$z-index-load-overlay: 1004;
$z-index-editor-header: 1004;

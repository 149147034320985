.modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;

  @import 'body';
  @import 'closer';
  @import 'confirm-field';
  @import 'error';
  @import 'filter';
  @import 'footer';
  @import 'header';
}

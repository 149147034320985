&__bar {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 0px;
  position: relative;
  text-align: center;
  transition: all .3s ease;

  .progress-diagram:hover & {
    min-width: 30px;
  }

  &--green {
    background-color: $c_aqua_dark_10;
  }
  &--light-green {
    color: $c_aqua_dark_10;
    background-color: $c_mint2;
  }
  &--red {
    background-color: $c_coral_dark_20;
  }
  &--yellow {
    background-color: $c_amber;
  }
  &-value {
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0s ease 0s;
    z-index: 1;
    .progress-diagram:hover & {
      opacity: 1;
      transition: opacity .3s ease .3s;
    }
  }
}
&__point {
  background-color: $c_aqua_dark_10;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  .organisation-statusbox-role--theme-red & {
    background-color: $c_coral_dark_10;
  }
  .organisation-statusbox-role--theme-grey & {
    background-color: $c_chrome_210;
  }
}

&__title {
  @extend %truncate-text;
  color: $c_black;
  .side-nav-item--no-truncate & {
    overflow: visible;
    white-space: normal;
    overflow-wrap: break-word;
  }
  .side-nav-item--opaque & {
    color: $c_txt_grey-3;
  }
}

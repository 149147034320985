.dashboard-knowledge {
  align-items: flex-start;
  left: 0;
  margin-top: 136px;
  min-width: 100%;
  transition: all 0.5s ease;
  .dashboard--changing-element-mediator & {
    margin-top: 186px;
  }
  @import 'person-section';
  @import 'persons';
  @import 'scroll';
  @import 'knowledge-areas';
  @import 'knowledge-elements';
  @import 'header';
  @import 'section-title';
  @import 'title';
}

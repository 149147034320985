.slide-leave {
  transform: translateX(0);
}

.slide-leave.slide-leave-active {
  transform: translateX(-200%);
  transition: all 500ms ease-in;
  transition-delay: 500ms;
}

.slide-appear {
  transform: translateX(200%);
}

.slide-appear.slide-appear-active {
  transform: translateX(0);
  transition: all 500ms ease-in;
}

.slide-enter {
  transform: translateX(200%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0);
  transition: all 500ms ease-in;
  transition-delay: 500ms;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 200ms ease;
}

.scale {
  visibility: hidden;
}

.scale-enter {
  transform: scale(0);
  overflow: hidden;
  visibility: visible !important;
}

.scale-enter.scale-enter-active {
  transform: scale(1);
  transform-origin: left top;
  transition: transform 300ms ease;
  visibility: visible !important;
}

.scale-exit {
  transform: scale(1);
  visibility: visible;
}

.scale-exit.scale-exit-active {
  transform: scale(0);
  transform-origin: left top;
  transition: transform 300ms ease;
  visibility: visible;
}

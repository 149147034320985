&__item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  &-text {
    margin-left: 0.5rem;
    color: $c_chrome_120;
    font-size: $fontSize-sm;
  }
  &--required &-figure {
    &:before {
      content: '';
      display: block;
      height: 100%;
      width: 0.25rem;
      background-color: $c_coral_dark_10;
    }
    border: 1px solid $c_bd_grey_base;
    width: 2rem;
    height: 1rem;
  }
  &--required-completed &-figure {
    &:before {
      content: '';
      display: block;
      height: 100%;
      width: 0.25rem;
      background-color: $c_chrome_200;
    }
    border: 1px solid $c_bd_grey_base;
    width: 2rem;
    height: 1rem;
  }
}

&__title-box {
  @extend %card-styling;
  display: flex;
  align-items: center;
  &-left {
    flex-grow: 1;
  }
  &--vertical-spacing {
    &-sm {
      padding: 0.5rem 1rem;
    }
  }
}

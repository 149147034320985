.badge {
  align-items: center;
  background-color: $c_chrome_220;
  border-radius: 10rem;
  color: $c_txt_grey-3;
  display: inline-flex;
  height: 3.375rem;
  justify-content: center;
  width: 3.375rem;

  // Sizes
  &--xsmall {
    height: 2rem;
    width: 2rem;
    font-size: $fontSize-xs;
  }
  &--small {
    height: 2.5rem;
    width: 2.5rem;
    font-size: $fontSize-xs;
  }
  &--large {
    height: 5rem;
    width: 5rem;
    font-size: $fontSize-xs;
  }

  // Colors
  &--green {
    background-color: $c_aqua_dark_10;
    color: $c_white;
  }
  &--light-green {
    background-color: $c_mint2;
    color: $c_aqua_dark_10;
  }
  &--dark-grey {
    background-color: $c_chrome_200;
    color: $c_txt_grey-3;
  }
  &--red {
    background-color: $c_coral_dark_10;
    color: $c_white;
  }

  @import 'icon';
  @import 'text';
}

&__goal {
  background-color: $c_chrome_255;
  border: 1px solid $c_chrome_210;
  border-width: 0 1px 1px 0;
  min-width: 50px;
  padding: 0 0px 0 3px;
  .organisation-board-unit__name & {
    background-color: $c_aqua_dark_10;
    border: none;
    width: 61px;
  }
}
.icon-with-count {
  @extend %reset-button;
  cursor: help;
  &--type-button {
    cursor: pointer;
  }
  position: relative;
  display: flex;
  align-items: center;
  @import 'count';
  @import 'icon';
}

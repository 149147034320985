&__indicator {
  fill: $c_chrome_120;
  margin: 0 0 0 0.5rem;

  .editor-element-indicators--size-sm & {
    fill: $c_chrome_120;
    margin: 0 0 0 0.25rem;
  }
  .editor-element-indicators--size-xs & {
    fill: $c_chrome_120;
    margin: 0 0 0 0.2rem;
  }

  /* &-role-count {
    margin-left: 0.25rem;
  } */
}

.dashboard-sidebar-item {
  position: relative;
  margin: 0;
  .dashboard-sidebar-list--nested & {
    &:last-child {
      &:before {
        top: 0;
        height: 1.75rem;
      }
    }
  }
  
  .dashboard-sidebar-list--nested & {
    padding-top: 0.5rem;
    padding-left: 1rem;
    &:before {
      content: '';
      display: block;
      width: 1px;
      border-left: 1px solid $c_chrome_200;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }
  .dashboard-sidebar-list--dashed-borders & {
    &:before {
      border-left: 1px dashed $c_chrome_200;
    }
  }
  @import 'box';
  @import 'icon';
  @import 'text';
}

.organisation-settings {
  margin: 0 0 120px;
  @import 'checkbox-list';
  @import 'header';
  @import 'permission';
  @import 'permissions-list';
  @import 'section';
  @import 'radio-button';
  @import 'radio-buttons';
}

.horizontal-section {
  display: flex;
  margin-bottom: 3rem;
  &--show-separator {
    margin-bottom: 2rem;
  }
  @import 'left';
  @import 'right';
  @import 'subtitle';
  @import 'title';
}

&__button {
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: inherit;
  background: none;
  border: none;
}

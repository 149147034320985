&__slider {
  background-color: $off-bg;
  cursor: pointer;
  height: 20px;
  position: relative;
  .switch__checkbox[disabled] + & {
    opacity: 0.3;
  }
  &:before {
    background-color: $circle-bg;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    content: '';
    height: 20px;
    left: 0;
    position: absolute;
    transition: 0.4s;
    width: 20px;
    will-change: transform;
  }

  .switch--small & {
    height: 10px !important;
    &:before {
      height: 10px !important;
      width: 10px !important;
    }
  }

  .switch--round & {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }

  .switch__checkbox:checked + & {
    background-color: $on-bg;
    &:before {
      transform: translateX(20px);
    }
    .switch--small & {
      &:before {
        transform: translateX(10px);
      }
    }
  }
}

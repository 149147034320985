&__setting {
  margin-left: 1rem;
  &-radio-button {
    align-items: center;
    display: flex;
  }
  &-text {
    margin: 0 1rem;
  }
  .radio-button-group {
    .input-label {
      align-self: center;
    }
  }
}

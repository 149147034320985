&__back {
  @extend %reset-button;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: $c_blue_dark_20;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: $fontSize-xs;
  margin-bottom: 0.2rem;
  span {
    display: none;
    @include for-tablet-portrait-up {
      display: inline;
    }
  }
  &-icon {
    margin-right: 0.5rem;
    width: 1.5rem !important;
    height: 1.5rem !important;
    fill: $c_blue_dark_20;
    @include for-tablet-portrait-up {
      width: 0.75rem !important;
      height: 0.75rem !important;
    }
  }
}

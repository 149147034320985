*,
*:before,
*:after {
  box-sizing: border-box;
}

:focus {
  outline: none;
}
::-moz-focus-inner {
  border: 0;
}

html,
body {
  height: 100%;

  -webkit-overflow-scrolling: touch;
  overflow: hidden;
}

body {
  font-family: $fontFamily;
  font-size: $fontSize-md;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  background: $c_app_bg_subtle;
}

form {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $c_txt_grey-1;
  text-rendering: optimizelegibility;
  font-weight: normal;
  margin: 0;
}

h1 {
  margin-bottom: 0.337em;
  font-size: $fontSize-xl;
  line-height: 1.1;
  margin-top: 0;
  font-style: normal;
}

h2 {
  font-size: $fontSize-lg;
  margin-top: 0;
  line-height: 1;
}

h3 {
  font-size: 1.1rem;
  line-height: 1;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

h4 {
  font-size: 1.267em;
  line-height: 1;
  margin-top: 1.689em;
}

h5,
h6 {
  font-size: 1.063em;
  line-height: 1;
  margin-top: 1.689em;
  margin-bottom: 0.535em;
  text-transform: uppercase;
  font-style: normal;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: $c_txt_grey-1;
  text-decoration: none;
}

h1:hover a,
h2:hover a,
h3:hover a,
h4:hover a,
h5:hover a,
h6:hover a {
  color: inherit;
  text-decoration: underline;
}

p {
  font-size: $fontSize-md;
  text-indent: 0;
  margin: 0;
  line-height: 1.4;
}

table h1,
table h2,
table h3,
table h4,
table h5,
table h6 {
  margin: 0;
}

th {
  text-align: left;
  border-bottom: 1px solid $c_chrome_230;
}

hr {
  color: $c_subtle_grey_dark_10;
  margin: 0.75em 0;
  text-align: center;
  height: 1px;
  clear: both;
  display: block;
  border: 0;
  background: $c_subtle_grey_dark_10;
}

ul,
ol {
  margin: 0.8em 0;
  padding-left: 1.2em;
}

ul li {
  padding: 0;
  margin-bottom: 0.6em;
}

ol li {
  list-style-type: decimal;
  margin-left: 0.5em;
}

ul li ul,
ol li ol {
  margin: 1.25em 0;
}

dl {
  margin: 0.75em 0;
}
dl dt {
  margin-top: 0.75em;
  font-weight: bold;
}
dl dd {
  margin: 0;
}

blockquote {
  margin: 0.89285714285714em 0 1em;
  font-size: 1.6em;
  line-height: 1.6em;
  font-style: italic;
  border: dotted $c_chrome_120;
  border-width: 1px 0;
  padding: 1em 0;
}

blockquote span {
  margin-bottom: 0.3em;
  display: block;
}

pre {
  font-family: $fontFamily;
  font-size: $fontSize-md;
  line-height: 1.4;
  white-space: pre-line;
  word-break: keep-all;
}

abbr[title] {
  border: none;
}

input[type='button'],
input[type='reset'],
input[type='submit'] {
  width: auto;
  height: auto;
}

a {
  color: $c_a_color;
  text-decoration: none;
}

a:hover {
  color: $c_a_color_hover;
}

p a {
  text-decoration: underline;
}

address {
  font-style: normal;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
}

nav ul,
nav ol {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}

ol {
  li {
    margin-bottom: 0.6em;
  }
}

kbd {
  background-color: $c_chrome_240;
  border-radius: 3px;
  border: 1px solid $c_chrome_180;
  color: $c_chrome_40;
  display: inline-block;
  line-height: 1;
  padding: 0.2rem;
  white-space: nowrap;
  font-size: 0.85em;
  vertical-align: middle;
}

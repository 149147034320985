&__footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  align-items: center;
  background: $c_chrome_255;
  display: flex;
  flex-shrink: 0;
  height: 130px;
  justify-content: center;
  padding: 20px 0;
  &-content {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  &-title {
    color: $c_aqua_dark_10;
    font-size: 16px;
    text-transform: uppercase;
  }
  &-badge-list {
    align-items: center;
    display: flex;
    margin-top: 15px;
  }
  &-badge {
    padding: 0 10px;
    &-img {
      height: 45px;
    }
  }
  @media (max-width: 950px) {
    display: none;
  }
}

.organisation-board-role {
  border-bottom: 1px solid $c_chrome_210;
  display: flex;
  height: 35px;
  width: 100%;
  &--has-filter {
    background-color: $c_yellow_light_20;
  }
  @import 'expand';
  @import 'filter';
  @import 'filter-count';
  @import 'name';
  @import 'status';
}

.header {
  @include box_shadow(2);
  background-color: $c_white;
  padding: 1rem;
  width: 100%;
  border-bottom: 1px solid $c_subtle_grey_light_10;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  justify-content: space-between;
}

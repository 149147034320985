.multi-state-button {
  @extend %reset-button;
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &:disabled {
    opacity: 0.7;
    cursor: auto;
    pointer-events: none;
  }
  &--color {
    &-stroke {
      border: 2px solid $c_chrome_160;
    }
    &-green {
      background-color: $c_aqua_dark_20;
    }
    &-light-green {
      background-color: $c_mint2;
    }
    &-red {
      background-color: $c_coral_dark_10;
    }
  }
  &--rounded {
    border-radius: $bd_radius_sml;
  }
  &--required {
    &:after {
      content: '*';
      display: block;
      position: absolute;
      color: $c_coral_dark_20;
      top: -10px;
      right: -10px;
      font-size: 1.5rem;
      width: 1ch;
      height: 1ch;
    }
  }
  @import 'icon';
  @import 'inner';
}

&__text {
    background-color: #222;
    border-radius: 3px;
    bottom: 125%;
    color: $c_white;
    font-size: 13px;
    left: 50%;
    max-width: 400px;
    min-width: 80px;
    opacity: 0;
    padding: 8px 21px;
    pointer-events: none;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    transition: opacity 0.3s;
    visibility: hidden;
    z-index: 1;
    .tooltip--bottom & {
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 125%);
    }
    .tooltip--left & {
        bottom: 50%;
        left: -10px;
        transform: translate(-100%, 50%);
    }
    .tooltip--right & {
        bottom: 50%;
        left: calc(100% + 10px);
        transform: translate(0%, 50%);
    }
    &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        border-width: 5px;
        border-style: solid;
        border-color: #222 transparent transparent transparent;
        .tooltip--bottom & {
            left: 50%;
            border-color: transparent transparent #222 transparent;
            top: 0;
            transform: translate(-50%, -100%);
        }
        .tooltip--left & {
            top: 50%;
            right: 0;
            border-color: transparent transparent transparent #222;
            transform: translate(99%, -50%);
        }
        .tooltip--right & {
            top: 50%;
            left: 0%;
            border-color: transparent #222 transparent transparent;
            transform: translate(-95%, -50%);
        }
    }
    .tooltip:hover & {
        visibility: visible;
        opacity: .9;
    }
}
&__space-overlay {
  position: absolute;
  top: calc(#{$header-top-height} + 1px);
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 2;
  &-button {
    margin-top: 1rem;
  }
  &-inner {
    text-align: center;
    width: 100%;
    max-width: 500px;
  }
}

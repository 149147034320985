&__options {
    max-width: 500px;
    padding: 1.5em 24px;
    width: 100%;
    
    &-location-field {
        align-items: center;
        background-color: $c_subtle_grey;
        border-color: $c_subtle_grey;
        border-radius: 3px;
        color: $c_chrome_0;
        display: flex;
        font-size: 14px;
        height: 50px;
        padding: 0 12px;
    }
    &-value {
        margin: 1em 0 2em;
    }
}
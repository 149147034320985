&__section {
  /* border-bottom: 1px solid $c_subtle_grey_light_10;
  padding: 1.5em 24px 3em; */
  margin-bottom: 2rem;
  &--no-padding {
    padding: 1.5em 0;
  }
  &-add-text {
    align-items: center;
    color: $c_blue_dark_20;
    cursor: pointer;
    display: inline;
    font-size: 12px;
    margin: 1em 0;
  }
  &-add-icon {
    fill: $c_chrome_120;
    margin-right: 0.5em;
  }
  &-button {
    margin: 1rem;
  }
  &-connect-all {
    margin-right: 1.5rem;
  }
  &-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    @media (max-width: 1024px) {
      margin: 0;
    }
  }

  &-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $c_bd_grey_base;
    padding: 1rem;
  }

  &-title {
    color: $c_chrome_120;
    font-size: 12px;
    text-transform: uppercase;
  }

  &-title-icon {
    margin-left: 0.25rem;
  }
}

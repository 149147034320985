&__icon {
  fill: $c_aqua_dark_20 !important;
  height: 10px !important;
  margin-right: 0.5em;
  width: 10px !important;
  &-wrapper {
    display: block;
  }
  &--red {
    fill: $c_coral_dark_10 !important;
  }
}

&__label {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 1rem;

  &-icon {
    margin-right: 1rem;
    width: 3rem !important;
    height: 3rem !important;
    fill: $c_aqua_dark_10 !important;
  }

  &-text {
    color: $c_chrome_100;
  }
}
.status-label {
  align-items: center;
  display: inline-flex;
  background: $c_chrome_220;
  padding: 0.25rem 0.5rem;
  border-radius: $bd_radius_sml;
  font-size: $fontSize-sm;
  color: $c_chrome_100;
  margin: 0 0.3rem;

  .training-session-card & {
    align-self: flex-start;
  }

  &--no-margin {
    margin: 0;
  }
  &--green {
    background-color: $c_aqua_dark_10;
    color: $c_white;
  }
  &--light-green {
    background-color: $c_mint2;
    color: $c_aqua_dark_10;
  }
  &--blue {
    background-color: $c_blue_dark_10;
    color: $c_white;
  }
  &--red {
    background-color: $c_coral_dark_20;
    color: $c_white;
  }
  &--round {
    border-radius: 100px;
  }
  &--size {
    &-small {
      padding: 0.05rem 0.5rem;
    }
    &-large {
      padding: 0.5rem 1rem;
    }
  }
}

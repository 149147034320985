.error-message {
  align-items: center;
  background-color: $c_coral_dark_10;
  border-radius: 0 0 $bd_radius_sml $bd_radius_sml;
  display: flex;
  overflow: hidden;
  height: 0px;
  //min-height:1.5rem
  @import 'text';
}

.input-error {
  background-color: $c_coral_dark_10;
  display: flex;
  align-items: center;
  color: $c_white;
  overflow: hidden;
  max-height: 0px;
  font-size: $fontSize-sm;
  border-radius: 0 0 $bd_radius_sml $bd_radius_sml;
  transition: max-height 0.3s ease;
  &--visible {
    max-height: 8rem;
  }
  @import 'text';
}

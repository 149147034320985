.trainee-link-overlay {
  position: absolute;
  z-index: 1;
  left: $main-aside-width-wide;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: auto;
  @import 'content';
  @import 'overlay';
}

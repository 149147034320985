&__icon {
  flex-shrink: 0;
  margin-right: 1rem;
  fill: $c_blue_dark_20;
  .box-message--type-error & {
    fill: $c_white;
  }
  .box-message--type-warning & {
    fill: $c_yellow_dark_40;
  }
}

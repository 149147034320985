&__goal {
  align-items: center;
  color: $c_blue_dark_20;
  display: flex;
  font-size: 11px;
  justify-content: center;
  min-width: 50px;

  .organisation-board-unit--read-only & {
    display: none;
  }
}

&__header {
  @extend %card-border;
  align-items: center;
  background-color: $c_white;
  border-radius: $bd_radius_sml;
  display: flex;
  flex-shrink: 0;
  height: 3rem;
  padding-left: 0.5rem;
  position: sticky;
  top: 0px;
  z-index: 1;
  &-context-menu-icon {
    fill: $c_chrome_170;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2.5rem;
    .icon {
      margin: 0;
    }
  }
  &-delete-icon {
    fill: $c_coral_dark_20;
  }
  &-icon {
    fill: $c_aqua_dark_10;
    flex-shrink: 0;
    margin-right: 0.5em;
  }
  &-indicator {
    cursor: help;
  }
  &-indicator,
  &-icon-button {
    align-items: center;
    color: $c_chrome_130;
    display: flex;
    font-size: 0.707em;
    margin-left: 0.25rem;
    .icon {
      fill: $c_chrome_170;
    }
  }
  &-icon-button:hover {
    .icon {
      fill: $c_blue_dark_10;
    }
  }
  &-indicator-count,
  &-icon-button-count {
    color: $c_chrome_170;
    font-size: 12px;
    margin-bottom: 8px;
  }
  &-text {
    flex-grow: 1;
    min-width: 0px;
  }
  &-title {
    align-items: center;
    color: $c_prime_dark_20;
    display: flex;
    &-text {
      line-height: 1;
    }
    &-input {
      background: none;
      height: auto;
      padding: 0;
      &:focus {
        background: none;
        box-shadow: none;
      }
    }
    &-icon {
      cursor: pointer;
      fill: $c_chrome_120;
      flex-shrink: 0;
      height: 15px !important;
      margin-left: 0.5em;
      opacity: 0;
      transition: opacity 0.3s ease;
      width: 15px !important;
      .designboard-role__header-title:hover & {
        opacity: 1;
      }
    }
    &-text {
      @extend %truncate;
    }
  }
  &-unit {
    color: $c_chrome_130;
    font-size: 0.707em;
  }
}

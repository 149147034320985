&__main {
  display: none;
  width: 20rem;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-bottom: 10rem;
  @media (min-width: 950px) {
    display: flex;
  }
}

&__filter {
    display: flex;
    flex-shrink: 0;
    margin-bottom: 1em;
    &-btn {
        align-self: flex-end;
        border-radius: 0;
        flex-shrink: 0;
    }
    &-btn-icon {
        margin-right: 1em;
    }
    &-input {
        background: $c_chrome_240;
        height: 50px;
        padding: 0 24px;
        width: 100%;
        &:focus {
            background: $c_chrome_240;
        }
    }
}
.check-list {
  &--background {
    &-grey {
      background-color: $c_chrome_240;
    }
  }
  &--negative-horizontal-margin {
    margin: 0 -1rem 1rem;
    padding: 1rem;
  }
  @import 'option';
  @import 'option-nest';
}

&__left {
  display: flex;
  &-select {
    margin-right: 1rem;
    select {
      font-size: $fontSize-md;
    }
  }
  &-button {
    margin-right: 1rem;
  }
  &-switch {
    margin-left: 1rem;
    .switch__label {
      font-size: $fontSize-md;
    }
  }
}

.signature-box {
  @include box_shadow(-2);
  background-color: $c_chrome_220;
  margin-bottom: 1rem;
  min-height: 250px;
  position: relative;
  @import 'image';
  @import 'placeholder';
  @import 'text';
}

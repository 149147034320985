&__box {
  @include box_shadow(2);
  background-color: $c_white;
  border-radius: 3px;
  border: 1px solid $c_blue_dark_10;
  color: $c_txt_grey-3;
  height: auto;
  left: 40px;
  padding: 15px 21px;
  position: absolute;
  top: 14px;
  width: 250px;
  z-index: 3;
}
.progress-diagram {
  color: $c_white;
  display: flex;
  height: 1.5rem;
  position: relative;
  width: 100%;
  
  @import "bar";
  @import "completion";
}


&__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1.2rem !important;
  width: 1.2rem !important;
  .donut--grey & {
    fill: $c_chrome_120;
  }
  .donut--green & {
    fill: $c_aqua_dark_20;
  }
  .donut--light-grey & {
    fill: $c_chrome_200;
  }
}

&__add-box {
  align-items: center;
  border-left: 1px dashed $c_chrome_200;
  display: flex;
  padding-bottom: 0.5em;
  position: relative;
  margin-left: 18px;
  padding-top: 1em;
  .designboard-role__knowledge-areas:not(:empty) + & {
    padding-top: 0px;
    &:before {
      top: 0px;
      height: 17px;
    }
  }
  &:before {
    align-self: flex-start;
    border-bottom: 1px solid $c_chrome_200;
    border-left: 1px solid $c_chrome_200;
    content: '';
    width: 13px;
    top: -14px;
    height: 31px;
    left: -1px;
    position: relative;
  }
  &--additional {
    border-left: none;
    padding-top: 0px;
    &:before {
      align-self: flex-start;
      border-bottom: 1px dashed $c_chrome_200;
      border-left: 1px dashed $c_chrome_200;
      content: '';
      width: 13px;
      top: 0px;
      height: 17px;
      left: 0;
      position: relative;
    }
  }
  &-icon {
    fill: $c_aqua_dark_10;
    margin-right: 0.5em;
  }
  &-inner {
    @extend %card-border;
    align-items: center;
    border-radius: $bd_radius_sml;
    background-color: rgba(255, 255, 255, 0.4);
    color: $c_blue_dark_20;
    cursor: pointer;
    display: flex;
    padding: 0.5em;
    user-select: none;
    width: 100%;
  }
  &-text {
    font-size: 12px;
  }
}

&__icon {
  width: 50% !important;
  height: 50% !important;
  fill: currentColor;

  .badge--icon-size-sm & {
    width: 1.2rem !important;
    height: 1.2rem !important;
  }
  .badge--icon-color-grey & {
    fill: $c_chrome_180;
  }
}

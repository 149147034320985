&__knowledge-elements {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    overflow: hidden;
    padding: 0 24px;
    position: relative;
    
    &-header {
        align-items: center;
        display: flex;
        white-space: nowrap;
        &-title {
            text-align: left;
            text-transform: uppercase;
            font-size: 12px;
            color: $c_txt_grey-3;
            margin-left: 1em;
        }
    } 
}
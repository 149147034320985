.dashboard-knowledge-element {
  height: 2.5rem;
  border: 1px solid $c_chrome_220;
  display: flex;
  margin-top: 0.5rem;
  border-radius: $bd_radius_sml;
  position: relative;

  &--training-task {
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      height: 3px;
      bottom: -2px;
      z-index: 1;
      background-color: $c_blue_dark_20;
    }
  }

  @import 'drawer';
  @import 'left';
}

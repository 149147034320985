.card {
    @extend %card-border;
    @include box_shadow(1);
    align-items: center;
    background-color: $c_white;
    border-radius: $bd_radius_sml;
    display: flex;
    flex-basis: 100%;
    margin: 0 10px 2em;
    min-width: 0;
    padding: 1em;
    transition: all .3s ease;

    @media(min-width: 1024px) {
        flex-basis: calc(100% / 2 - 20px);
    }
    @media(min-width: 1200px) {
        flex-basis: calc(100% / 3 - 20px);
    }
    @import "icon";
    @import "text";
    @import "wrapper";
}
&__mobile {
  position: relative;
  text-align: center;
  color: $c_white;
  h1 {
    color: $c_white;
  }
  p {
    margin-bottom: 2rem;
  }

  @media (min-width: 950px) {
    display: none;
  }
  &-badge {
    margin: 0 0.5rem;
  }
  &-badges {
    display: flex;
    justify-content: center;
  }
}

.editor-element-responsibility-subtitle {
  &:hover {
    span {
      button & {
        color: $c_prime_dark_20 !important;
      }
    }
  }
  @import 'button';
}

&__main-content {
  padding: 0 0.5rem;
  .gantt-task--content-outside & {
    position: absolute;
    left: calc(100% + 1rem);
  }
  .gantt-task--content-outside.gantt-task--no-end-date & {
    position: absolute;
    left: calc(100% + 3rem);
  }
}

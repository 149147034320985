.organisation-board-header {
  background-color: $c_white;
  border-bottom: 1px solid $c_chrome_210;
  display: flex;
  height: 35px;
  overflow: hidden;
  top: 124px;
  width: 100%;
  z-index: 1;
  position: sticky;
  top: 4rem;
  @import 'goal';
  @import 'persons';
  @import 'placeholder';
}

&__right {
  &-add-persons {
    height: 35px;
    margin: 0 1rem;
    /* position: sticky;
    top: 4rem; */
    .organisation-board-unit--read-only & {
      display: none;
    }
  }
}

&__item-wrapper {
  @extend %reset-button;
  align-items: center;
  cursor: pointer;
  display: flex;
  min-height: 2.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  position: relative;
  .organisation-sidebar-tree__item--child &,
  &--has-children {
    padding-left: 0;
  }
  &--has-subtitle {
    margin: .5rem 0;
  }
  &--loading {
    cursor: auto;
    pointer-events: none;
  }
}

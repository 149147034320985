.side-nav-title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $c_subtle_grey_light_10;
  border-bottom: 1px solid $c_subtle_grey_light_10;
  padding: 1.5em 1.5rem !important;
  &--subtitle {
    border: none;
    padding: 1rem 1.5rem .5rem !important;
  }
  &--size {
    &-small {
      padding: 0.75rem 1.5rem !important;
    }
  }
  @import 'text';
}

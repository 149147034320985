&__main {
  background-color: $c_white;
  overflow: auto;
  justify-items: stretch;
  display: block;

  @include for-tablet-landscape-up {
    overflow: hidden;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  &-left,
  &-right {
    overflow: auto;
  }
  &-left {
    border-right: 1px solid $c_bd_grey_base;
    overflow-x: auto;
  }
  &-list-title {
    min-height: 3.5rem;
  }
  &-settings {
    overflow: auto;
    &-buttons {
      margin: 2rem 0;
      display: flex;
      column-gap: 1rem;
    }
  }
  &-form {
    max-width: 700px;
    padding: 1rem;
  }
}

&__header {
  align-items: center;
  display: flex;
  padding: 1.5em 1rem;

  &-button {
    margin-left: 1rem;
    &:first-of-type {
      margin-left: auto;
    }
  }
  &-button-icon {
    margin-right: 0.5em;
  }
  &-text {
    margin: 0 1rem;
    flex-shrink: 0;
    align-self: center;
  }
  &-title {
    align-items: center;
    display: flex;
    flex-grow: 1;
    margin: 0;
  }
  &-title-input {
    flex-grow: 1;
    .inline-field-editor__form-input {
      background-color: transparent !important;
      font-size: $fontSize-xl;
      box-shadow: none;
      border: none;
      padding: 0;
      height: auto;
      line-height: 1.1;
    }
  }
  &-icon {
    fill: $c_chrome_120;
  }
  &-icon-wrapper {
    cursor: pointer;
    margin-left: 0.5em;
  }
}

.button-icon {
  @extend %reset-button;
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 0;
  width: $uiMediumHeight;
  height: $uiMediumHeight;
  flex-shrink: 0;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:disabled {
    cursor: auto;
  }

  .training-session__element &,
  .knowledge-element &,
  .section-list-item & {
    border-left: 1px solid $c_bd_grey_base;
  }

  .section-list-item & {
    height: 100%;
  }

  .designboard-knowledge-element &:hover:not([disabled]) {
    .button-icon__icon {
      fill: $c_prime_dark_20;
    }
  }

  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--color {
    &-green {
      background-color: $c_aqua_dark_20;
    }
    &-red {
      background-color: $c_coral_dark_20;
    }
  }
  &--cursor-help {
    cursor: help !important;
  }
  &--inline {
    width: auto;
    height: auto;
    margin: 0 0.3rem;
  }
  &--no-margin {
    margin: 0;
  }
  &--round {
    border-radius: calc($uiMediumHeight / 2);
  }
  &--rounded {
    border-radius: $bd_radius_sml;
  }
  &--size {
    &-xs {
      height: 0.75rem;
      width: 0.75rem;
    }
    &-small,
    &-sm {
      height: 1rem;
      width: 1rem;
    }
    &-md {
      height: 2rem;
      width: 2rem;
    }
    &-large {
      width: 3.5rem;
      height: 3.5rem;
    }
  }

  @import 'icon';
}

&__icon {
    cursor: pointer;
    display: flex;
    fill: $c_chrome_120;
    flex-shrink: 0;
    margin-left: auto;

    &-minus {
        fill: $c_coral_dark_10;
        margin-left: .5em;
    }
}
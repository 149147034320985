&__left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @include for-tablet-portrait-up {
    flex-direction: column;
    align-items: flex-start;
  }

  &-title {
    @extend %truncate;
    margin: 0;
  }
}

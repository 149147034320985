.editor-element {
  display: flex;
  flex-wrap: wrap;
  min-height: 55px;
  background-color: $c_white;
  border-bottom: 1px solid $c_bd_grey_base;
  position: relative;
  &--hover-under {
    padding-bottom: 55px;
  }
  &--hover-over {
    padding-top: 55px;
  }
  &--hidden {
    opacity: 0 !important;
  }
  &--critical {
    &:before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      background-color: $c_coral_dark_10;
    }
  }
  &-wrapper {
    align-items: center;
    cursor: auto;
    display: flex;
    flex-grow: 1;
    min-height: 55px;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease;
    width: 100%;

    .editor-element--add-element & {
      padding: 0 1em;
    }
  }
  @import 'indicators';
  @import 'info';
  @import 'handle';
  @import 'options';
  @import 'settings';
}

.simple-table {
  width: 100%;

  &--font-size {
    &-sm {
      font-size: $fontSize-sm;
    }
  }
  &--layout-fixed {
    table-layout: fixed;
  }
  &--spacing-bottom {
    &-md {
      margin-bottom: 2rem;
    }
  }
  @import 'cell';
  @import 'row';
}

.input {
  background-color: $c_subtle_grey;
  border-radius: $bd_radius_sml;
  border: none;
  color: $c_txt_grey-0;
  font-size: $fontSize-md;
  height: 3rem;
  outline: none;
  padding: 0 0.5rem;
  transition: all 0.3s ease;

  &-wrapper {
    position: relative;
  }

  &[type='password'] {
    padding-right: 3rem;
  }

  &:disabled {
    opacity: 0.3;
  }

  .ui-field & {
    margin-bottom: 24px;
  }

  &:focus {
    box-shadow: inset 0 0px 5px -1px rgba($c_black, 0.7);
    background-color: $c_subtle_grey_light_10;
    color: $c_txt_grey-0;
  }

  &--full-width {
    width: 100%;
  }

  &--size {
    &-small {
      height: 2rem;
    }
  }

  &--color {
    &-dark {
      background-color: #595959;
      border-color: #595959;
      color: #efefef;

      &:focus {
        background-color: #303030;
        border-color: #303030;
        color: #ffffff;
      }

      &.ui-val-success {
        background-color: #595959;
        border-color: $c_aqua;
        color: $c_aqua;
      }

      &.ui-val-error {
        background-color: #595959;
        border-color: $c_coral_dark_10;
        color: $c_coral_dark_10;
      }
    }

    &-outline {
      background-color: $c_white;
      border-color: $c_subtle_grey;
      color: $c_txt_grey-0;

      &:focus {
        background-color: $c_white;
        border-color: $c_subtle_grey_dark_10;
        color: $c_txt_grey-0;
      }
    }

    &-invisible {
      background: none;
      border: none;
      height: auto;
      padding: 0;

      &:focus {
        background: none;
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
  }
  @import 'icon-button';
}

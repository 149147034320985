.ReactModal__Overlay {
  background: rgba($c_black, 0.3);

  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
  z-index: $z-index-systemlayer-modal;

  &--after-open {
    opacity: 1;
    transition: opacity 200ms ease-out;
  }

  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Content {
  -webkit-overflow-scrolling: touch;
  background: $c_white;
  border-radius: 4px;
  border: 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  max-height: 90%;
  left: 50%;
  max-width: 1000px;
  outline: none;
  overflow: auto;
  position: absolute;
  top: 50%;
  width: 90%;
  transform: translate(-50%, -45%);
  transform-origin: left top;
  opacity: 0;

  &--after-open {
    transform: translate(-50%, -50%);
    transition: transform 200ms ease, opacity 200ms ease;
    opacity: 1;
  }

  &--before-close {
    transform: translate(-50%, -45%);
    transition: transform 100ms ease, opacity 100ms ease;

    opacity: 0;
  }
}

.system-modal {
  &--fixed-height {
    height: 100%;
  }
}

&__input {
  $size: 1rem;
  @mixin rangeThumb {
    width: $size;
    height: $size;
    margin-top: - calc($size / 2);
    border-radius: 50%;
    background: $c_white;
    @include box-shadow(2);
    cursor: pointer;
    border: 1px solid $c_chrome_200;
  }

  @mixin rangeTrack {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: $c_aqua_dark_10;
    border-radius: 2px;
  }

  -webkit-appearance: none;
  width: 100%;
  height: $size;
  outline: none;

  // Thumb
  &::-webkit-slider-thumb {
    -webkit-appearance: none; // needed again for Chrome & Safari
    @include rangeThumb;
  }

  &::-moz-range-thumb {
    @include rangeThumb;
  }

  &::-ms-thumb {
    @include rangeThumb;
  }

  // Track
  &::-webkit-slider-runnable-track {
    @include rangeTrack;
  }

  &::-moz-range-track {
    @include rangeTrack;
  }

  &::-ms-track {
    @include rangeTrack;
  }
}

&__action {
  @extend %reset-button;
  background-color: $c_white;
  border-color: $c_chrome_220 !important;
  border-left: 1px solid;
  border-bottom: 1px solid;
  border-top: 1px solid;
  cursor: pointer;
  &-icon {
    fill: $c_chrome_100;
    .slide-button__action--color-red & {
      fill: $c_coral_dark_20 !important;
    }
  }
  &-title {
    font-size: 0.75rem;
  }
}

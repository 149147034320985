&__input {
  background: none;
  border: none;
  flex-grow: 1;
  font-family: inherit;
  font-size: 12px;
  height: auto;
  min-height: 2rem;
  padding: 0.5rem 0;
  resize: none;
  width: 100%;
  &:focus {
    background: none;
    box-shadow: none;
  }

  .editor & {
    font-size: $fontSize-md;
  }
}

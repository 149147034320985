.signup {
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2em;
    width: 100%;

    @import 'button';
    @import 'form';
    @import 'form-error';
}
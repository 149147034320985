&__organisations {
  @extend %reset-list;
  padding-bottom: 0.5rem;
  max-height: 300px;
  overflow: auto;
  &-item {
    margin: 0;
  }
  &-link,
  &-button {
    display: block;
    padding: 0.75rem 1rem;
    font-size: $fontSize-md;
    width: 100%;
    text-align: left;
    &:hover {
      background-color: $c_chrome_240;
      &[disabled] {
        background-color: transparent;
      }
    }
  }
  &-link {
    color: $c_black;
    &:hover {
      color: $c_black;
    }
  }
  &-button {
    @extend %reset-button;
    cursor: pointer;
    &[disabled] {
      color: $c_black;
      cursor: auto;
    }
  }
  &-name-container {
    display: flex;
    align-items: center;
  }
  &-name {
    margin-right: .5em;
  }
  &-selection-text {
    font-size: $fontSize-sm;
    color: $c_chrome_140;
    line-height: 1;
  }
}

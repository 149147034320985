&__button {
  @extend %reset-button;
  background-color: $c_aqua_dark_20;
  color: $c_white;
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  &[disabled] {
    opacity: 0.7;
  }
}

&__action {
  padding: 0 2rem;
  height: 2.5rem;
  width: 220px;
  font-size: $fontSize-sm;

  .dashboard & {
    width: 12rem;
    padding: 0 0.5rem;
  }

  .dashboard--state-compact & {
    width: calc(80px - 0.5rem);
    justify-content: center !important;
    span {
      display: none;
    }
  }
  .status-button--menu-direction-down &,
  .status-button--menu-direction-up & {
    justify-content: flex-start;
  }

  .status-button--size-large & {
    height: 3.5rem;
  }
  .section-list-item & {
    height: auto;
  }
}

.legend {
  display: flex;
  padding: 1rem 0;
  flex-wrap: wrap;
  &--horizontal-padding {
    padding: 1rem;
  }
  &--no-padding {
    padding: 0;
  }
  @import 'item';
}

.file-upload-box {
  border: 2px dashed $c_subtle_grey;
  border-radius: $bd_radius_sml;
  margin-bottom: 1rem;
  transition: all 0.3 ease;
  &--drag-over,
  &--has-file {
    border: 2px solid $c_chrome_240;
    background-color: $c_chrome_250;
  }

  @import 'file';
  @import 'info';
  @import 'input';
  @import 'label';
}

&__drag-handle {
  height: 2rem;
  width: 1rem;
  position: absolute;
  cursor: col-resize;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  .gantt-task__bar:hover & {
    opacity: 1;
  }
  &:before {
    content: '';
    display: block;
    border-left: 1px solid $c_chrome_200;
    border-right: 1px solid $c_chrome_200;
    height: 1rem;
    width: 0.25rem;
    opacity: 0;
    .gantt-task__bar:hover & {
      opacity: 1;
    }
  }
  &--left {
    border-radius: $bd_radius_lg 0 0 $bd_radius_lg;
    left: -1rem;
  }
  &--right {
    right: -1rem;
    border-radius: 0 $bd_radius_lg $bd_radius_lg 0;
  }
}

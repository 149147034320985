&__grid {
  flex-grow: 1;
  //overflow: hidden;
  position: relative;
  overflow-x: auto;
  //width: calc(100% - 352px); // Role column is 300px and right column is 52px
  display: flex;
  padding-bottom: 0.75rem;

  &::-webkit-scrollbar {
    height: 13px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $c_chrome_200;
    background: linear-gradient(to right, $c_aqua_dark-10, $c_aqua_dark_20);
    outline: none;
    border-radius: 0.4rem;
  }
  &-column {
    flex-grow: 1;
  }
  &-empty-state {
    color: $c_chrome_100;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    p {
      font-size: $fontSize-sm;
    }
  }
  &-persons,
  &-status-row {
    display: flex;
  }
  &-persons {
    background-color: $c_white;
    overflow: hidden;
    position: sticky;
    top: 4rem;
    z-index: 1;
  }
  &-statuses {
    padding-bottom: 11px;
    margin-bottom: 11px;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 13px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 0.4rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $c_chrome_200;
      background: linear-gradient(to right, $c_aqua_dark-10, $c_aqua_dark_20);
      outline: none;
      border-radius: 0.4rem;
    }
  }
  &-wrapper {
    flex-grow: 1;
    min-width: 0;
    align-self: stretch;
  }
}

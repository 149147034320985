&__name-box {
  height: 2.5rem;
  background-color: $c_white;
  border: 1px solid $c_chrome_220;
  padding: 0.25rem 1rem;
  text-align: center;
  border-radius: $bd_radius_sml;
  &--center {
    display: flex;
    align-items: center;
  }
  .text {
    line-height: 1;
  }
}

&__context-menu {
  &-trigger {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      margin: 0 0.5rem;
    }
  }
}

&__check {
  border-radius: 50%;
  display: block;
  height: 10px;
  left: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  width: 10px;
  .radio-button--small & {
    width: 6px;
    height: 6px;
  }
  .radio-button--medium & {
    width: 0.75rem;
    height: 0.75rem;
  }
  .radio-button__input:checked ~ & {
    background: $c_aqua_dark_20;
  }
  .radio-button__input:disabled ~ & {
    opacity: 0.5;
  }
}

&__error {
  background-color: $c_coral_dark_10;
  color: $c_white;
  padding: 1rem;
  border-radius: $bd_radius_sml;
  &--h-spacing {
    &-md {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}

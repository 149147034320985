.section-list-item {
  display: flex;
  border-bottom: 1px solid $c_bd_grey_base;
  &--highlight {
    &-red {
      border-left: 7px solid $c_coral_dark_10;
    }
  }
  @import 'left';
  @import 'right';
}

&__breadcrumbs {
  &-crumb {
    &:after {
      content: '/';
      margin: 0 0.5em;
    }
    &:last-child:after {
      display: none;
    }
  }
}

.content-administration {
  $header-top-height: 4rem;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  @import 'focus-header';
  @import 'header';
  @import 'header-cell';
  @import 'legend';
  @import 'sidebar';
  @import 'space-overlay';
  @import 'table';
}

&__header-cell {
  display: flex;
  align-items: flex-end !important;
  &--hidden {
    visibility: hidden;
  }
  &--rotated {
    white-space: nowrap;
    position: relative;
    display: block !important;
    border-width: 0px 0px 1px 0px !important;
    &.table-header-cell--state-sort-active {
      font-weight: bold;
    }
    div {
      transform: translate(-8px, 27px) rotate(315deg);
      width: 250px;
      height: 30px;
      border-bottom: 1px solid $c_bd_grey_base;
      display: flex;
      align-items: center;
      z-index: 1;
      position: relative;
    }
  }
  &-title {
    @extend %truncate;
    display: flex;
    font-size: $fontSize-sm;
    padding: 0 0.25rem;
    flex-grow: 1;
    max-width: 150px;
    &-text {
      @extend %truncate;
    }
  }
  &-tooltip-icon {
    transform: rotate(45deg);
    height: 0.75rem !important;
    width: 0.75rem !important;
    margin: 0 0.25rem;
  }
}

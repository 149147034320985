&__header {
  align-items: center;
  display: flex;
  margin-bottom: 2rem;
  &-btn {
    margin-left: 1rem;
  }
  &-title {
    flex-grow: 1;
    margin-bottom: 0px;
  }
}

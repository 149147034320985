&__info {
  flex-grow: 1;

  &-mentor {
    align-items: center;
    display: flex;
  }

  &-mentor-icon {
    margin: 0 0.5rem;
  }

  &-subtitle {
    align-items: center;
    display: flex;
    color: $c_chrome_120;
    line-height: 1.2;
    .role-card--state-disabled & {
      color: $c_chrome_200;
    }
    .icon {
      margin: 0 0.5rem 0 1rem;
      height: 1rem;
      width: 1rem;
    }
    .status-label {
      margin: 0;
    }
  }

  &-title {
    color: $c_txt_blue-0;
    font-size: $fontSize-lg;
    line-height: 1.2;
    .role-card--state-disabled & {
      color: $c_chrome_200;
    }
  }
}

&__knowledge-elements {
  border: 1px solid $c_chrome_220;
  border-top: none;

  &-buttons {
    cursor: auto;
    display: flex;
    border-bottom: 1px solid $c_chrome_220;
    padding: 0.5rem 38px;
    justify-content: flex-end;
    background-color: $c_white;
  }
  &-button {
    font-size: $fontSize-xs;
    margin-left: 0.5rem;
  }

  &-wrapper {
    .designboard-role__knowledge-areas .designboard-knowledge-area:last-child & {
      padding-left: 13px;
    }
    padding-left: 12px;
    width: 100%;
  }
}

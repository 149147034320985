.designboard-role {
  flex-shrink: 0;
  padding: 0 1rem;
  width: 500px;
  &:first-of-type {
    padding-left: 0;
  }
  @import 'add-box';
  @import 'header';
  @import 'knowledge-areas';
}

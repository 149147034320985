&__active-background {
  background-color: $c_aqua_dark_20;
  height: 2rem;
  position: absolute;
  transition: transform 0.3s ease-out;
  left: 0;
  pointer-events: none;
  z-index: 1;
  .tabs--active-tab-color-grey & {
    background-color: $c_chrome_240;
  }
  .tabs--rounded & {
    border-radius: $bd_radius_sml;
  }
  .tabs--round & {
    border-radius: 50px;
  }
}

&__drawer {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 2;
  &-action {
    justify-content: flex-start;
    padding: 0 0.5rem;
    border-bottom: 1px solid $c_chrome_200;
  }
}

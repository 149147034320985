&__indicators {
  align-items: center;
  border-right: 1px solid $c_chrome_200;
  display: flex;
  margin: 0 0.7em;
  padding: 0 0.7em;
  &-icon {
    fill: $c_chrome_120;
    margin-left: 0.3rem;
    flex-shrink: 0;
  }
}

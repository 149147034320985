.person-editor-role {
    @extend %card-border;
    @include box_shadow(1);
    background: $c_white;
    display: flex;
    margin-bottom: 2em;
    min-height: 86px;
    padding: $gutter-md 16px $gutter-md 16px;
    position: relative;
    transition: all 0.2s ease;
    @import "info";
    @import "mentor";
}
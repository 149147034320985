.field {
  display: flex;
  flex-direction: column;
  &--margin-none {
    margin-bottom: 0;
  }
  &--margin-md {
    margin-bottom: 1rem;
  }
  &--margin-lg {
    margin-bottom: 1.5rem;
  }
  .input-label {
    align-self: flex-start;
  }
}

&__header {
  display: flex;
  padding: 1.5rem 1rem;
  &-left {
    flex-grow: 1;
  }
  &-owner-text {
    color: $c_chrome_180;
  }
}

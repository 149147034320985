&__actions {
  display: flex;
  width: 0px;
  transition: width 0.3s ease;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  .slide-button--state-open & {
    width: 100%;
  }
}

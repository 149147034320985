.list-card {
  @extend %card-border;
  @include box_shadow(1);
  align-items: center;
  background-color: $c_white;
  display: flex;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  min-height: 4rem;
  text-align: left;
  width: 100%;
  &--clickable {
    cursor: pointer;
  }
  &--disabled {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: auto;
  }
  &--no-card-styling {
    border: none;
    box-shadow: none;
  }
  &--no-right-padding {
    padding-right: 0;
  }

  .check-list__option-nest & {
    min-height: auto;
    margin-bottom: 0;
  }

  @import 'center';
  @import 'left';
  @import 'right';
}

.context-menu-fixed {
  @include box_shadow(2);
  background-color: $c_white;
  border: 1px solid $c_blue_dark_10;
  margin: 0;
  max-width: 250px;
  min-width: 200px;
  top: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: fixed;
  transition: opacity 0.15s ease;
  visibility: hidden;
  white-space: nowrap;
  z-index: 99;
  border-radius: $bd_radius_sml;
  &--open {
    opacity: 1;
    visibility: visible;
    .menu-open & {
      transform: translateX(-270px);
    }
  }
  &--bottom-left {
    transform: translate(-100%, 0%);
  }
  &--bottom-right {
    transform: translate(0, 0);
  }
  &--top-left {
    transform: translate(-100%, -100%);
  }
  &--top-right {
    transform: translate(0%, -100%);
  }
  li {
    @extend %truncate;
    border-bottom: 1px solid $c_chrome_220;
    margin: 0;
    padding: 0.5em;
    &:last-child {
      border: none;
    }
  }
}

.aside {
  @include custom-scrollbar();
  background-color: $c_white;
  border-right: 1px solid $c_bd_grey_base;
  flex-shrink: 0;
  height: 100%;
  overflow-y: auto;
  width: $main-aside-width;
  position: relative;

  .page--fullscreen & {
    position: fixed;
    z-index: $z-index-full-screen-aside;
    top: 0;
    transition: transform 0.3s ease;
  }
  &--footer {
    display: flex;
    flex-direction: column;
    //overflow: hidden;
  }
  &--no-scroll {
    overflow: hidden;
  }
  &--sublevel {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  &--wide {
    width: $main-aside-width-wide;
  }
  &--hidden {
    transform: translate(-$main-aside-width);
    z-index: 1;
  }

  &--visible {
    left: 0;
    z-index: 1;
  }
  .ReactVirtualized__Grid,
  .ReactVirtualized__List {
    @include custom-scrollbar(0.5rem);
  }
  @import 'content';
  @import 'footer';
}

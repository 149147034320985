.side-nav-item {
  margin: 0;
  width: 100%;
  transition: background-color 0.3s ease;
  &--disabled {
    opacity: 0.5;
  }
  &--opaque {
    opacity: 0.7;
  }
  &--hidden {
    visibility: hidden;
  }
  &--state-active {
    background-color: $c_chrome_240;
  }
  @import 'button';
  @import 'left';
  @import 'right';
  @import 'subtitle';
  @import 'text';
  @import 'title';
}

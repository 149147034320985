%card-border {
  border: 1px solid $c_chrome_220;
}

%reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

%truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%reset-button {
  background: none;
  border: none;
  padding: 0;
  &:focus {
    outline: none;
  }
}

%rich-text {
  a {
    word-break: break-all;
  }
  p {
    margin: 1em 0;
  }
  li {
    margin-left: 1.5em;
  }
  ul,
  ol {
    margin: 16px 0;
    padding: 0;
  }
}

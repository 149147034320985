&__error {
  background-color: $c_coral_dark_20;
  border-radius: $bd_radius_sml;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.3s ease;

  .login--error & {
    max-height: 5rem;
  }
  span {
    display: block;
    padding: 0.5rem 1rem;
  }
}

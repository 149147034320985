&__drawer {
  @include box-shadow(-1);
  background-color: $c_chrome_240;
  &-inner {
    @include custom-scrollbar();
    display: flex;
    overflow: auto;
    padding: 1rem 1.5rem;
  }
}

.text {
  white-space: break-spaces;
  &--bold {
    font-weight: bold;
  }
  &--italic {
    font-style: italic;
  }
  &--color {
    &-green {
      color: $c_aqua_dark_20;
    }
    &-grey {
      color: $c_txt_grey-4;
    }
    &-dark-grey {
      color: $c_txt_grey-3;
    }
    &-light-red {
      color: $c_coral_dark_10;
    }
    &-red {
      color: $c_coral_dark_20;
    }
    &-blue {
      color: $c_blue_dark_20;
    }
    &-white {
      color: $c_white;
    }
  }
  &--size {
    &-xxs {
      font-size: $fontSize-xxs;
    }
    &-xs {
      font-size: $fontSize-xs;
    }
    &-sm {
      font-size: $fontSize-sm;
    }
    &-lg {
      font-size: $fontSize-lg;
    }
  }
  &--truncate {
    @extend %truncate;
    white-space: nowrap;
    max-width: 100%;
  }
  &--upper-case {
    text-transform: uppercase;
  }
}

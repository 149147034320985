&__body {
    display: flex;
    flex-direction: column;
    &-form,
    &-form-field,
    &-form-unit-field {
        display: flex;
        flex-direction: column;
    }
    &-form-field-group{
        display: flex;
        justify-content: space-between;
    }
    &-form-field {
        flex-shrink: 0;
        &--inline{
            flex-basis: calc(100% / 2 - 10px);
        }
    }
    &-form-unit-field {
        flex-shrink: 1;
    }
}
&__section-title {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0.5rem 0;
  height: 2rem;
  &--visible {
    visibility: visible;
  }
  &--direction-right {
    justify-content: flex-end;
  }
  &-content {
    position: relative;
  }
  &-text {
    font-size: $fontSize-md;
    color: $c_aqua_dark_20;
    font-weight: bold;
  }
}

&__lock-button {
  background: none;
  cursor: pointer;
  height: 25px;
  width: 25px;
  padding: 0;
  border: none;
  &:focus {
    outline: none;
  }
  &-icon {
    &-circle {
      fill: none;
      stroke: $c_chrome_120;
      transition: all .3s ease;
      .knowledge-element--complete & {
        fill: $c_aqua_dark_10;
        stroke: $c_aqua_dark_10;
      }
    }
    &-path {
      fill: $c_chrome_120;
      stroke: $c_chrome_120;
      stroke-width: .3px;
      transition: all .3s ease;
      .knowledge-element--complete & {
        fill: $c_white;
        stroke: $c_white;
      }
    }
  }
}
&__list {
  @extend %reset-list;
  &-icon-wrapper {
    border-radius: 17px;
    flex-shrink: 0;
    height: 34px;
    margin-right: 1.2em;
    position: relative;
    transition: background-color 0.3s ease;
    width: 34px;
    .role-nav__list-item--completed & {
      background-color: $c_aqua_dark_10;
    }
    .role-nav__list-item--expert & {
      background-color: $c_aqua_dark_10;
    }
    .role-nav__list-item--ongoing & {
      background-color: $c_mint2;
    }
    .role-nav__list-item--unlinked & {
      background-color: $c_chrome_220;
    }
  }
  &-icon {
    height: 17px !important;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 17px !important;
    // Because star icon's visual center is different, we move it a bit up
    &--expert {
      top: 47%;
    }
    .role-nav__list-item--completed & {
      fill: $c_white;
    }
    .role-nav__list-item--expert & {
      fill: $c_white;
    }
    .role-nav__list-item--ongoing & {
      fill: $c_aqua_dark_10;
    }
    .role-nav__list-item--unlinked & {
      fill: $c_chrome_140;
    }
  }
  &-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 60px;
    margin: 0;
    position: relative;
    transition: background-color 0.3s ease;
    user-select: none;
    &.selected {
      background-color: $c_subtle_grey_light_10;
    }
  }
  &-notifications {
    float: none;
    margin-left: 5px;
  }
  &-role-desc-icon {
    fill: $c_aqua_dark_10;
  }
  &-text {
    flex-grow: 1;
    overflow: hidden;
  }
  &-text-category {
    color: $c_chrome_180;
    display: block;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-text-name {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-title {
    align-items: center;
    display: flex;
    border-top: 1px solid $c_subtle_grey_light_10;
    border-bottom: 1px solid $c_subtle_grey_light_10;
    padding: 1.5em 24px !important;
  }
  &-item {
    padding: 0 24px !important;
    &--role-desc {
      border-top: 1px solid $c_subtle_grey_light_10;
    }
  }
}

&__element {
  align-items: center;
  display: flex;
  min-height: calc(3.5rem + 1px);
  padding-left: 1rem;
  border-bottom: 1px solid $c_bd_grey_base;
  margin-bottom: 0;
  background-color: $c_white;
  &-body {
    display: flex;
    flex-grow: 1;
  }
  &-handle {
    margin-right: 1rem;
  }
}

&__title {
  align-items: center;
  display: flex;
  border-top: 1px solid $c_subtle_grey_light_10;
  border-bottom: 1px solid $c_subtle_grey_light_10;
  padding: 1.5em 1.5rem !important;

  &-text {
    text-transform: uppercase;
    font-size: $fontSize-xs;
    color: $c_txt_grey-3;
  }
}

&__unit {
  display: flex;
  margin-bottom: 3rem;
  &-left {
    width: 10rem;
    margin-right: 2rem;
  }
  &-right {
    flex-grow: 1;
  }
  &-add-button {
    @extend %reset-button;
    align-items: center;
    color: $c_blue_dark_20;
    cursor: pointer;
    display: inline;
    font-size: 12px;
  }
  &-empty-text {
    margin: 0 0 1rem 0;
  }
  &-header {
    align-items: center;
    margin-bottom: 1rem;
    display: flex;
  }
  &-title {
    color: $c_chrome_80;
    font-size: 14px;
  }
}

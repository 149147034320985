.table-cell {
  border: 1px solid $c_bd_grey_base;
  display: flex;

  .content-administration & {
    border-top-width: 0px !important;
    border-left-width: 0px !important;
  }

  &:has(button[disabled]),
  &:has(input[type='radio'][disabled]),
  &:has(input[type='checkbox'][disabled]) {
    background-color: $c_chrome_240;
  }
  &--background-color {
    &-grey {
      background-color: $c_chrome_240;
    }
  }
  &--center-content {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  &--disabled {
    background-color: $c_chrome_240;
  }

  &--hidden {
    visibility: hidden;
  }
  &--highlight {
    &-grey {
      border-left: 5px solid $c_chrome_200;
    }
    &-red {
      border-left: 5px solid $c_coral_dark_10;
    }
  }
  @import 'content';
  @import 'context-menu';
  @import 'icon';
  @import 'subtitle';
  @import 'title';
}

&__item {
    align-items: center;
    color: $c_txt_grey-3;
    display: flex;
    font-size: 13px;
    margin-left: 1.5rem;
    margin-bottom: 0px;
    &:first-child {
        margin-left: 0;
    }
}
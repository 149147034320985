&__ie-banner {
    height: 3rem;
    background-color: $c_coral_dark-10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}
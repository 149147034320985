&__body {
    background-color: $c_chrome_240;
    flex-basis: 0;
    flex-grow: 1;
    overflow-y: auto;
    padding: 1.5em 24px 3em;
    &--create,
    &-form,
    &-unit-field {
        display: flex;
        flex-direction: column;
    }
    &-info-message {
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }
    &-item-checkbox {
        margin-left: auto;
    }
    &-item-subtitle {
        color: $c_chrome_120;
        font-size: 12px;
        line-height: 1;
    }
    &-list {
        @extend %reset-list;
    }
    &-list-item {
        align-items: center;
        background-color: $c_white;
        border: 1px solid $c_subtle_grey_light_10;
        box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08);
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1em;
        min-height: 55px;
        padding: 1em;
        width: 100%;
    }
}
&__right {
  display: flex;
  &-full-screen-button {
    margin-left: 1rem;
  }
  &-button {
    margin-right: 0.5rem;
  }
  &-select {
    margin-right: 1rem;
  }
}

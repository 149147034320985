&__point {
  background-color: $c_aqua_dark_10;
  border-radius: 50%;
  height: 7px;
  margin-right: 0.5em;
  width: 7px;

  &--light {
    background-color: $c_aqua_light_10;
  }
  &--red {
    background-color: $c_coral_dark_10;
  }
}

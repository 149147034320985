%card-styling {
  @include box-shadow(1);
  background-color: $c_white;
  padding: 1rem;
  border-radius: $bd_radius_sml;
}

%reset-appearance {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

%reset-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }
}
%reset-fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0.01em 0 0 0;
}

%reset-legend {
  padding: 0;
}

%reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

%truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

&__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 40px;
  justify-content: center;
  min-width: 0;
  overflow: hidden;
  padding: 0 0.5rem;
}

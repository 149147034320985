.radio-button-group {
  &--direction-horizontal {
    display: flex;
    align-items: center;
    .input-label {
      margin-right: .5rem;
    }
  }
  
  @import 'option';
  @import 'options';
}

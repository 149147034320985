&__option {
  &--is-selected,
  &--is-focused {
    color: $c_black !important;
    background-color: $c_chrome_240 !important;
  }
  &:hover {
    background-color: $c_chrome_240 !important;
  }
}

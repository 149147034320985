.dashboard-area-box {
  height: 2.5rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $bd_radius_sml;

  &--transparent {
    opacity: 0.2;
  }
  &--color {
    &-green {
      background-color: $c_aqua_dark_10;
      color: $c_white;
    }
    &-light-green {
      background-color: $c_mint2;
      color: $c_aqua_dark_10;
    }
    &-grey {
      background-color: $c_chrome_220;
      color: $c_chrome_140;
    }
  }
  @import 'button';
  @import 'icon';
}

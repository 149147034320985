&__section {
  border: 1px solid $c_chrome_220;
  border-radius: $bd_radius_sml;
  margin-bottom: 1rem;

  &-button {
    margin-top: 1rem;
  }
  &-header {
    padding: 1rem;
    display: flex;
    align-items: center;
  }
  &-header-left {
    flex-shrink: 0;
  }
  &-header-center {
    flex-grow: 1;
    padding: 0 1rem;
  }
  &-header-right {
    flex-shrink: 0;
  }
  &-main {
    padding: 1rem;
    border-top: 1px solid $c_chrome_220;
    &--background-grey {
      background-color: $c_chrome_240;
    }
  }
}

&__dots {
  display: flex;
  justify-content: center;
  $timing: 1000ms infinite ease-in-out;
  $delay: 100ms;
  @mixin fadeInAndOut($num) {
    animation: fadeInAndOut $timing;
    animation-delay: $delay * $num;
  }
  span {
    background-color: $c_aqua_dark_10;
    border-radius: 1rem;
    display: block;
    height: 1rem;
    margin: 0 0.2rem;
    opacity: 0;
    width: 1rem;
    .loader--color-white & {
      background-color: $c_white;
    }
    .loader--size-small & {
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 0.25rem;
      margin: 0 0.1rem;
    }
  }
  span:nth-of-type(1) {
    @include fadeInAndOut(0);
  }
  span:nth-of-type(2) {
    @include fadeInAndOut(1);
  }
  span:nth-of-type(3) {
    @include fadeInAndOut(2);
  }
  @keyframes fadeInAndOut {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
}

.context-menu-item {
  align-items: center;
  display: flex;
  cursor: pointer;
  color: $c_black;
  user-select: none;
  &:hover {
    background-color: $c_chrome_240;
  }
  &--form {
    cursor: auto;
    &:hover {
      background-color: transparent;
    }
  }
  &--disabled {
    cursor: not-allowed !important;
    &:hover {
      background-color: transparent;
    }
  }
  &--no-click {
    cursor: auto;
    &:hover {
      background-color: transparent;
    }
  }
  &--content {
    display: block;
  }
  @import 'content';
  @import 'form';
  @import 'header';
  @import 'icon';
  @import 'switch';
  @import 'text';
}

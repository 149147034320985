.side-nav-filter {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  border-top: 1px solid $c_subtle_grey_light_10;
  border-bottom: 1px solid $c_subtle_grey_light_10;
  .side-nav-title + & {
    border-top: none;
  }
  @import 'button';
  @import 'buttons';
  @import 'inner';
}

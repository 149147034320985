&__settings {
  background-color: $c_chrome_240;
  box-shadow: inset 0px 5px 5px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  width: 100%;
  &-btn {
    &:first-child {
      margin-right: 0.5em;
    }
  }
  &-checkbox {
    margin: 2em 0;
  }
  &-label {
    display: inline;
    margin-left: 0.5em;
  }
  &-help-text {
    color: $c_chrome_120;
    font-size: 12px;
    font-style: italic;
    margin: 1em 0;
  }
  &-link-visiblity {
    display: flex;
    margin: 1.5em 0 0.5em;
    label {
      margin-left: 0.5em;
    }
  }
  &-lock-mediator {
    display: flex;
    margin: 1.5em 0 0.5em;
    label {
      margin-left: 0.5em;
    }
  }
  &-radio-button {
    display: inline-block;
    margin-left: 0.5em;
  }
  &-radio-field {
    align-items: center;
    display: flex;
    margin-bottom: 1em;
  }
  &-rte-buttons {
    margin: 1em 0;
  }
  &-section {
    border-bottom: 1px solid $c_subtle_grey_dark_10;
    padding: 1.5em 24px 3em;
    &:first-child {
      margin-top: 1em;
    }
  }
  &-select {
    display: block;
    max-width: 500px;
    width: 100% !important;
  }

  &-title {
    color: $c_chrome_120;
    font-size: 12px;
    margin-bottom: 2em;
    text-transform: uppercase;
  }
}

&__task-indicator {
  height: 3rem;
  visibility: hidden;
  display: flex;
  align-items: center;
  &--visible {
    visibility: visible;
  }
  &--direction-right {
    justify-content: flex-end;
  }
  &-button {
    @extend %reset-button;
    color: $c_aqua_dark_20;
    display: flex;
    pointer-events: auto;
    text-align: left;
    height: 2rem;
    align-items: center;
    .gantt-chart__task-indicator-section--direction-right &,
    .gantt-chart__task-indicator--direction-right & {
      flex-direction: row-reverse;
      text-align: right;
    }
  }
  &-text {
    margin: 0 0.25rem;
  }
  &-title {
    font-weight: bold;
    line-height: 1;
    font-size: $fontSize-xxs;
  }
  &-section {
    display: flex;
    align-items: center;
    position: relative;
    height: 2rem;
    margin: 0.5rem 0;
    //background-color: $c_aqua_dark_20;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0 0.5rem;
    //margin: 0.5rem 0;
    /* visibility: hidden;
    &--visible {
      visibility: visible;
    } */
    /* &--direction-right {
      justify-content: flex-end;
    } */
    &-title {
      //margin: 0 0.25rem;
      font-size: $fontSize-md;
      color: $c_white;
      color: $c_aqua_dark_20;
      font-weight: bold;
    }
  }
  &-subtitle {
    font-size: $fontSize-xxs;
    line-height: 1;
  }
  &-arrow {
    margin: 0 0.25rem;
    fill: $c_aqua_dark_20;
    height: 2rem;
    .gantt-chart__task-indicator-section--direction-right &,
    .gantt-chart__task-indicator--direction-right & {
      transform: rotate(180deg);
    }
  }
}

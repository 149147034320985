&__knowledge-areas {
  border-left: 1px solid $c_chrome_200;
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  padding-top: 1em;
  position: relative;
  &-title {
    color: $c_chrome_120;
    font-size: 14px;
    letter-spacing: 0.2em;
    margin-bottom: 1px;
    padding: 1em 0 1em 13px;
    text-align: left;
    text-transform: uppercase;
  }
  &--additional{
    border-left: 1px dashed $c_chrome_200;
  }
}

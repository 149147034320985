&__name {
  display: flex;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  &-context-menu {
    &-icon {
      fill: $c_chrome_170;

      .organisation-board-unit--read-only & {
        display: none;
      }
    }

    &-remove-knowledge-icon {
      fill: $c_coral_dark_10;
    }
  }

  &-edit-icon {
    fill: $c_chrome_120;
    flex-shrink: 0;
    height: 1rem !important;
    margin: 0 0.5rem;
    opacity: 0;
    width: 1rem !important;
    transition: opacity 0.3s ease;

    .organisation-board-knowledge__name:hover & {
      opacity: 1;
    }

    .organisation-board-unit--read-only & {
      display: none;
    }
  }

  &-input {
    background: none;
    font-size: 12px;
    height: auto;
    padding: 0;

    &:focus {
      background: none;
      box-shadow: none;
    }
  }

  &-text {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-wrapper {
    align-items: center;
    display: flex;
    height: 35px;
    justify-content: space-between;
    padding: 0 0.5rem 0 2.25rem;
    min-width: 245px;
    width: 245px;
  }
}

.signature-pad {
  @include box_shadow(-2);
  background-color: $c_chrome_220;
  position: relative;
  &--padding {
    &-md {
      padding: 1rem;
    }
  }
  &--margin {
    &-md {
      margin: 1rem;
    }
  }
  @import 'canvas';
  @import 'reset-button';
  @import 'text';
}

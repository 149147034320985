.main {
  margin-left: $mainBarWidth;
  position: relative;
  left: 0;
  height: 100%;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  &--flex-cl {
    display: flex;
    flex-direction: column;
  }
  .menu-open & {
    transform: translateX($mainBarOpenWidth - $mainBarWidth);
  }
}

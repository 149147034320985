.main-area {
  @include custom-scrollbar();
  background-color: $c_white;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 10em;
  position: relative;
  &--no-bottom-pd {
    padding-bottom: 0;
  }
  &--background-grey {
    background-color: $c_chrome_240;
  }
  &--default-pd {
    padding: 24px 24px 10rem;
  }
  &--force-vertical-scroll-bar {
    overflow-y: scroll;
  }
  &--horizontal-scroll {
    overflow: auto;
  }
  &--full-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index-full-screen-main-area;
  }
}

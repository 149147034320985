&__filter {
  display: flex;
  flex-shrink: 0;
  padding: 1rem;
  &-button {
    border-radius: 0 $bd_radius_sml $bd_radius_sml 0;
  }
  &-input {
    border-radius: $bd_radius_sml 0 0 $bd_radius_sml;
    width: 100%;
  }
  .input-wrapper {
    flex-grow: 1;
  }
}

.main-header {
  background: $c_app_bg_light;
  border-bottom: 1px solid $c_bd_grey_base;
  display: flex;
  //flex-wrap: wrap;
  justify-content: space-between;
  height: $main-header-height;
  flex-shrink: 0;
  padding: 0 1.5rem;
  position: relative;
  z-index: $z-index-header;
  @import 'back';
  @import 'breadcrumbs';
  @import 'content';
  @import 'left';
  @import 'navigation';
  @import 'organisations';
  @import 'right';
  @import 'user-badge';
  @import 'user-info';
}

&__header {
    align-items: center;
    border-bottom: 1px solid $c_subtle_grey_light_10;
    display: flex;
    padding: 1.5em 24px;
    &-delete-button {
        margin-left: auto;
    }
    &-delete-button-icon {
        margin-right: .5em;
    }
}
.organisation-board-unit {
    @include box_shadow(1);
    background-color: $c_white;
    margin-bottom: 1.5rem;

    @import "add-role";
    @import "header";
    @import "goal";
    @import "grid";
    @import "main";
    @import "right";
    @import "sidebar";
    }
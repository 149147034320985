&__body {
  flex-grow: 1;
  overflow: auto;
  position: relative;
  padding: 0 1rem;
  margin: 1rem 0 2rem;
  &--center {
    text-align: center;
  }

  &--deep {
    background-color: $c_chrome_240;
    padding: 1rem;
    margin: 0;
  }

  &--no-spacing {
    padding: 0;
    margin-bottom: 0;
  }

  &--no-bottom-spacing {
    margin-bottom: 0;
  }

  &--flex-cl {
    display: flex;
    flex-direction: column;
  }

  &-info-message {
    text-align: center;
  }

  &-title {
    margin-bottom: 1em;
  }
}

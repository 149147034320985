&__list {
  @extend %reset-list;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  @include for-tablet-landscape-up {
    grid-template-columns: 1fr 1fr;
  }
  @include for-desktop-up {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include for-big-desktop-up {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

&__icon {
  width: 1rem !important;
  height: 1rem !important;
  //stroke: 1px;
  
  .multi-state-button--color-stroke & {
    fill: $c_chrome_120;
  }
  .multi-state-button--color-green & {
    fill: $c_white;
  }
  .multi-state-button--color-light-green & {
    fill: $c_aqua_dark_20;
  }
  .multi-state-button--color-red & {
    fill: $c_white;
  }
}

&__body {
  margin: 0em 0 2em;
  &-text {
    padding-bottom: 1em;
  }
  &-text-section {
    margin-top: 1em;
  }
  &-text-small {
    color: $c_chrome_120;
    font-size: 12px;
  }
}

.switch {
  $circle-bg: white;
  $off-bg: $c_chrome_200;
  $on-bg: $c_aqua_dark_10;

  display: block;
  height: 20px;
  position: relative;
  width: 40px;
  cursor: pointer;
  flex-shrink: 0;

  &[disabled] {
    cursor: auto;
  }
  &--hitslop {
    &-md {
      padding: 1rem;
      margin: -1rem;
      box-sizing: content-box;
    }
  }

  &--small {
    height: 10px;
    width: 20px;
  }
  &-wrapper {
    align-items: center;
    cursor: pointer;
    display: flex;
  }

  .designboard-knowledge-element__options & {
    align-items: center;
    display: flex;
    height: 2.5rem;
    justify-content: center;
    width: 2rem;
  }

  @import 'checkbox';
  @import 'icon';
  @import 'slider';
  @import 'label';
}

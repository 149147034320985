.area-connect-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;
    &-wrapper {
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        flex-grow: 1;
    }
    @import "body";
    @import "closer";
    @import "footer";
    @import "header";
    @import "options";
}
&__label {
  border: 2px solid $c_chrome_160;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 20px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 20px;
  transform: translate(-50%, -50%);
  .radio-button--small & {
    width: 13px;
    height: 13px;
    border: 1px solid $c_chrome_160;
  }
  .radio-button--medium & {
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid $c_chrome_160;
  }
  .radio-button__input:checked ~ & {
    border-color: $c_aqua_dark_20;
  }
  .radio-button__input:disabled ~ & {
    border-color: scale-color($c_chrome_160, $alpha: -50%);
    cursor: auto;
  }

  .radio-button__input:checked.radio-button__input:disabled ~ & {
    border-color: scale-color($c_aqua_dark_20, $alpha: -50%);
  }
}

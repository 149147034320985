&__button {
  @extend %reset-button;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 40px;
  min-width: 0;
  overflow: hidden;
  padding: 0 0.5rem;
  .table-cell-editable--disabled & {
    cursor: auto;
    color: $c_chrome_120;
  }
}

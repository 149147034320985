&__navigation {
  @extend %reset-list;
  min-width: 18rem;
  &-wrapper {
    border-radius: $bd_radius_sml;
    border: 1px solid $c_bd_grey_base;
    background-color: $c_white;
    position: fixed;
    top: $main-header-height;
    right: 1.5rem;
    overflow: hidden;
    transition: transform 0.3s ease;
    z-index: 10002;
  }
  &-item {
    margin: 0;
  }
  &-link,
  &-button {
    display: block;
    padding: 1rem 1rem;
    font-size: $fontSize-md;
    border-top: 1px solid $c_bd_grey_base;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    .main-header__navigation-item:first-of-type & {
      border: none;
    }

    &:hover {
      background-color: $c_chrome_240;
    }
  }
  &-link {
    color: $c_black;
    &:hover {
      color: $c_black;
    }
  }
  &-button {
    @extend %reset-button;
    cursor: pointer;
  }
  &-icon {
    margin-right: 0.5rem;
  }
  &-title {
    padding: 1rem 1rem 0.5rem;
    letter-spacing: 0.1em;
  }
}

&__settings {
  &-button {
    @extend %reset-button;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 0 1rem 0;
    width: 100%;
    justify-content: space-between;
    &-icon {
      transition: transform 0.3s ease;
      .training-form__settings-button--state-open & {
        transform: rotate(45deg);
      }
    }
    &-icon-container {
      @include box_shadow(2);
      border: 1px solid $c_bd_grey_base;
      background-color: $c_white;
      margin-right: 0.5rem;
      height: 2rem;
      width: 2rem;
      border-radius: 1rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before {
        display: block;
        content: '';
      }
    }
    &-line {
      width: 100%;
      height: 1px;
      background-color: $c_bd_grey_base;
      margin: 0 1rem;
    }

    &-text {
      flex-shrink: 0;
      color: $c_txt_grey-2;
    }
  }
  &-radio-input {
    width: 5ch;
    border: 1px solid $c_bd_grey_dark;
    text-align: center;
    border-radius: $bd_radius_sml;
    margin: 0 1ch;
    // Hide arrows on number input

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

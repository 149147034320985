&__form {
  &-field {
    align-items: center;
    display: flex;
    margin: .3em 0;
  }
  &-label {
    font-size: 9px;
    color: $c_txt_grey-3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: .3em;
  }
}

.inline-field-editor {
  align-items: center;
  display: flex;
  flex-grow: 1;

  @import 'form';
  @import 'icon';
  @import 'input-wrapper';
  @import 'loader';
  @import 'validation-icon';
}

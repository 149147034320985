&__field {
  &-label {
    margin-bottom: 0.6rem;
  }
  &-button {
    @extend %reset-button;
    cursor: pointer;
    background-color: $c_subtle_grey;
    display: block;
    border-radius: $bd_radius_sml;
    padding: 0 12px;
    height: 3rem;
    width: 100%;
    text-align: left;
    margin-bottom: 1.5rem;
    &[disabled] {
      cursor: default;
      opacity: 0.45;
      color: $c_black;
    }
  }
}

&__text {
  .checkbox__input[disabled] ~ & {
    opacity: 0.6;
  }
  &--mg-right {
    margin-right: 0.5em;
  }
  &--mg-left {
    margin-left: 0.5em;
  }
}

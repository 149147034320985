&__name-box {
  height: 2.5rem;
  align-items: center;
  background-color: $c_white;
  border: 1px solid $c_chrome_220;
  padding: 0.25rem;
  text-align: center;
  border-radius: $bd_radius_sml;
  width: calc(200px - 0.5rem);
  flex-shrink: 0;
  margin-left: 0.5rem;
  &--center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dashboard--state-compact & {
    width: calc(80px - 0.5rem);
  }
  .text {
    line-height: 1;
  }
}

&__requirement {
  display: flex;
  align-items: center;
  .icon {
    margin-right: 0.5rem;
  }
  &-tooltip-icon {
    margin-left: 0.5rem;
    fill: $c_prime_dark_20;
  }
}

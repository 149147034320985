&__icon {
  width: $iconMedium;
  height: $iconMedium;
  fill: $c_chrome_120;

  .button-icon--color-green & {
    fill: $c_white;
  }
  .button-icon--icon-color-green & {
    fill: $c_aqua_dark_20;
  }
  .button-icon--icon-color-red & {
    fill: $c_coral_dark_20;
  }
  .button-icon--icon-color-light-grey & {
    fill: $c_chrome_180;
  }

  .button-icon--size-xs & {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
  .button-icon--size-sm & {
    height: 1rem !important;
    width: 1rem !important;
  }
  .button-icon--size-large & {
    width: 1.2rem !important;
    height: 1.2rem !important;
  }
}

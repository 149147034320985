&__item {
  margin: 0;
  &:first-of-type {
    button {
      border-top: none;
    }
  }
  &-button {
    @extend %reset-button;
    cursor: pointer;
    display: flex;
    padding: 0.5rem;
    align-items: center;
    border-top: 1px solid $c_chrome_220;
    width: 100%;
  }
}

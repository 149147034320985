&__delete {
  @extend %reset-button;
  @include box_shadow(2);
  align-items: center;
  background-color: $c_coral_dark-10;
  border-radius: 2rem;
  cursor: pointer;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;

  &-confirmation {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 1;
  }

  .icon {
    fill: $c_white;
  }
}

&__box {
  align-items: center;
  background-color: $c_white;
  border: 1px solid $c_chrome_220;
  border-radius: $bd_radius_sml;
  display: flex;
  height: 2.5rem;
  padding: 0 1rem;
  position: relative;
  text-align: left;
  width: 100%;
  .dashboard-sidebar-item--sticky > & {
    z-index: 1;
    position: sticky;
    top: 5rem; // Height of header
  }
  .dashboard-sidebar-item--highlight & {
    &:after {
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      position: absolute;
      border: 3px solid $c_coral_dark_10;
    }
  }
  &--clickable {
    cursor: pointer;
  }
  .dashboard-sidebar-list--nested & {
    &:before {
      content: '';
      display: block;
      height: 1px;
      width: 1rem;
      border-top: 1px solid $c_chrome_200;
      position: absolute;
      top: 50%;
      // 1px to connect lines fully with parent
      left: calc(-1rem - 1px);
    }
  }
  .dashboard-sidebar-list--dashed-borders & {
    &:before {
      border-top: 1px dashed $c_chrome_200;
    }
  }
}

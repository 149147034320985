&__placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &-icon {
    height: 5rem !important;
    width: 5rem !important;
  }
}

.organisation-board-person {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  overflow: hidden;
  height: 35px;
  padding: 0 0.5rem;
  border-bottom: 1px solid $c_chrome_210;
  height: 35px;
  cursor: pointer;
  .organisation-board-unit__grid-column & {
    visibility: hidden;
    height: 0px;
    border: none;
  }
  @import 'name';
}

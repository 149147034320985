&__button {
  @extend %reset-button;
  @include box_shadow(2);
  align-items: center;
  background-color: $c_chrome_230;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin: 0 0.25em;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  width: 2rem;


  .confirm-button--active & {
    opacity: 1;
  }

  &--reject {
    .confirm-button--active & {
      transform: translate(-3rem, -50%);
    }
    .icon {
      fill: $c_coral_dark_10 !important;
      stroke: $c_coral_dark_10 !important;
    }
  }
  &--confirm {
    .confirm-button--active & {
      transform: translate(-5.5rem, -50%);
    }
    .icon {
      fill: $c_aqua_dark_20 !important;
      stroke: $c_aqua_dark_20 !important;
    }
  }
  .icon {
    stroke-width: 1px;
    height: 1rem !important;
    width: 1rem !important;
  }
}

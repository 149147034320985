.donut {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  flex-direction: column;
  .boxlink &,
  .person-editor-role &,
  .role-card & {
    margin-right: 1rem;
    width: 6rem;
  }

  .role-card--state-disabled & {
    opacity: 0.5;
  }

  @import 'background';
  @import 'icon';
  @import 'inner';
  @import 'label';
  @import 'text';
  @import 'value';
}

&__item {
  @extend %card-styling;
  align-items: center;
  display: flex;
  margin-bottom: 1rem;

  &-info {
    flex-grow: 1;
  }

  &-subtitle {
    color: $c_chrome_120;
    font-size: 12px;
    line-height: 1;
  }

  &-title {
  }

  &-title-icon {
    cursor: pointer;
    fill: $c_chrome_120;
    height: 15px !important;
    margin-left: 0.5em;
    opacity: 0;
    transition: opacity 0.3s ease;
    width: 15px !important;

    .box-list__item-title:hover & {
      opacity: 1;
    }
  }
}


// Text grey
$c_txt_grey-0: $c_chrome_0;
$c_txt_grey-1: $c_chrome_30;
$c_txt_grey-2: $c_chrome_80;
$c_txt_grey-3: $c_chrome_120;
$c_txt_grey-4: $c_chrome_180;

// Text blue
$c_txt_blue-0: $c_prime_dark_20;

// Borders
$c_bd_grey_dark: $c_chrome_180;
$c_bd_grey_base: $c_chrome_220;
$c_bd_grey_light: $c_chrome_230;



// Anchor
$c_a_color: $c_prime_dark_20;
$c_a_color_hover: darken($c_prime_dark_20, 5%);


// App / UI
$c_app_bg_light: $c_chrome_255;
$c_app_bg_subtle: $c_chrome_240;
$c_app_bg_medium: $c_chrome_240;
$ui-dark-bg: $c_aqua_dark_10;
$ui-dark--bg: $c_aqua_dark_20;




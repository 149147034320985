&__item {
  margin: 0;
  position: relative;
  &--child {
    padding-left: 3rem;
    .organisation-sidebar-tree__item--child & {
      padding-left: 1rem;
    }
  }
  &-expand-icon {
    margin-left: 1rem;
    transition: transform 0.3s ease;
    .organisation-sidebar-tree__item--child & {
      position: absolute;
      left: -2rem;
    }
    .organisation-sidebar-tree__item-wrapper--state-expanded & {
      transform: rotate(-180deg);
    }
    .icon {
      height: 0.75rem !important;
      width: 0.75rem !important;
      fill: $c_chrome_40;
    }
  }
  &-left {
    align-items: center;
    display: flex;
    margin-left: auto;
  }
  &-notifications {
    margin-left: 0.5rem;
    position: relative;
    top: 1px;
  }
  &-select-button {
    width: 2rem;
    height: 2rem;
  }
  &-subtitle {
    @extend %truncate;
    font-size: $fontSize-xs;
    padding-left: .5rem;
    color: $c_txt_grey-3;
    .organisation-sidebar-tree__item-wrapper--has-children & {
      padding-left: 0.5rem;
    }
  }
  &-text {
    overflow: hidden;
  }
  &-title {
    @extend %truncate;
    font-size: $fontSize-sm;
    padding-left: .5rem;
    .organisation-sidebar-tree__item-wrapper--has-children & {
      padding-left: 0.5rem;
    }
  }
}

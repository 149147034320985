&__user-info {
  text-align: right;
  display: none;
  @include for-tablet-portrait-up {
    display: block;
  }

  &-organisation,
  &-name {
    line-height: 1;
    .main-header__right:hover & {
      opacity: 0.7;
    }
  }
  &-organisation {
    color: $c_chrome_140;
    font-size: $fontSize-sm;
  }
}

.radio-button {
  align-self: center;
  display: block;
  height: 21px;
  position: relative;
  width: 21px;
  &--small {
    height: 14px;
    width: 14px;
  }
  @import 'check';
  @import 'input';
  @import 'label';
}

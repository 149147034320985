&__bar {
  height: 100%;
  width: 0;

  .organisation-statusbox-progress--color-green & {
    background-color: $c_aqua_dark_10;
  }
  .organisation-statusbox-progress--color-red & {
    background-color: $c_coral_dark_10;
  }
  .organisation-statusbox-progress--color-grey & {
    background-color: $c_chrome_220;
  }
}

&__menu {
  @include box_shadow(2);
  background-color: $c_white;
  border: 1px solid $c_blue_dark_10;
  border-radius: $bd_radius_sml;
  margin: 0;
  max-width: 260px;
  min-width: 200px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  transition: opacity 0.15s ease;
  visibility: hidden;
  white-space: nowrap;
  z-index: 3;
  &--fixed {
    position: fixed;
    z-index: 9999999999;
    width: 100%;
    top: 50%;
    left: 50%;
  }
  &--visible {
    opacity: 1;
    visibility: visible;
  }
  &--bottom-left {
    bottom: 0;
    left: 0;
    transform: translate(-100%, 100%);
  }
  &--bottom-right {
    bottom: 0;
    right: 0;
    transform: translate(100%, 100%);
  }
  &--top-left {
    top: 0;
    left: 0;
    transform: translate(-100%, -100%);
  }
  &--top-right {
    top: 0;
    right: 0;
    transform: translate(100%, -100%);
  }
  li {
    @extend %truncate;
    border-bottom: 1px solid $c_chrome_220;
    margin: 0;
    padding: 0.5em;
    &:last-child {
      border: none;
    }
  }
}

&__indicator {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  &-dash {
    color: $c_chrome_220;
  }
  &-inner {
    text-align: center;
  }
  &-percentage {
    font-size: 11px;
    margin-bottom: .2rem;
  }
  &--green {
    color: $c_aqua_dark_10;
    .organisation-statusbox-progress {
      &__bar {
        background-color: $c_aqua_dark_10 !important;
      }
    }
  }
  &--red {
    color: $c_coral_dark_10;
    .organisation-statusbox-progress {
      background-color: lighten($c_coral_dark_10, 30%);
      &__bar {
        background-color: $c_coral_dark_10 !important;
      }
    }
  }
  &--yellow {
    color: $c_amber_dark_20;
    .organisation-statusbox-progress {
      background-color: lighten($c_amber, 30%);
      &__bar {
        background-color: $c_amber !important;
      }
    }
  }
}
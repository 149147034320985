.permission-matrix {
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  position: relative;

  &--state-updating {
    pointer-events: none;
  }

  @import 'header-cell';
  @import 'sidebar';
  @import 'table';
}

.designboard-knowledge-element {
  align-items: center;
  background-color: $c_white;
  border-bottom: 1px solid $c_chrome_230;
  cursor: pointer;
  display: flex;
  height: 3rem;
  padding-left: 0.5rem;
  position: relative;
  &--add-element {
    padding: 0 0.5rem;
  }
  &--hidden {
    opacity: 0 !important;
  }
  &--critical {
    &:before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      background-color: $c_coral_dark_10;
    }
  }

  @import 'handle';
  @import 'options';
  @import 'subtitle';
  @import 'title';
}

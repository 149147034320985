&__section {
    align-items: center;
    background-color: $c_white;
    display: flex;
    padding: 0.5em 1em;
    &-add-text {
        align-items: center;
        color: $c_blue_dark_20;
        cursor: pointer;
        flex-grow: 1;
        font-size: 12px;
    }
    &-delete-area {
        flex-shrink: 0;
        cursor: pointer;
        fill: $c_coral_dark_20;
    }
}
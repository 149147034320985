&__actions {
  display: flex;
  position: absolute;
  right: 100%;
  z-index: 2;

  .section-list-item & {
    height: 100%;
  }
  .status-button--menu-direction-up &,
  .status-button--menu-direction-down & {
    flex-direction: column;
    right: 0;
  }
  .status-button--menu-direction-down & {
    top: 100%;
  }
  .status-button--menu-direction-up & {
    top: 0;
    transform: translateY(-100%);
  }
}

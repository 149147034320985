&__footer {
  display: grid;
  padding: 0.5rem 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  &-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-left {
    display: flex;
    justify-content: flex-start;
  }
}

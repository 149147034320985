&__header {
  flex-wrap: wrap-reverse;
  row-gap: 1rem;
  &-left {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.5rem;
  }
  &-detail {
    display: flex;
    margin-right: 1rem;
    &-text {
      margin-left: 0.5rem;
    }
  }
  &-progress-bar {
    width: 100px;
  }
  &-progress-text {
    margin: 0 0.5rem;
  }
}

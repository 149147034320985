&__star {
  display: flex;
  &-icon {
    fill: $c_aqua_dark_10;
    height: 10px !important;
    width: 10px !important;

    .organisation-statusbox-knowledge--theme-grey & {
      fill: $c_chrome_210;
    }

    .organisation-statusbox-knowledge--theme-red & {
      fill: $c_coral_dark_10;
    }
  }
}

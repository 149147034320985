&__form {
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 750px;
  width: 100%;

  &-left,
  &-right {
    flex-grow: 1;
    padding: 1em;
  }

  .Select {
    &.is-focused:not(.is-open) > .Select-control {
      box-shadow: 0 3px 5px -1px rgba($c_black, 0.2);
      border-color: $c_subtle_grey_light_10;
    }
    &.is-open > .Select-control .Select-arrow {
      top: 0;
      border-color: $c_black transparent transparent;
      border-width: 6px 3px 2.5px;
    }
  }

  &-select {
    margin: 1em 0 3.7em;
    &-language-option {
      align-items: center;
      display: flex;
    }
    .Select-control {
      @extend .ui-select;
      height: 100%;
      border-radius: 0px;
      display: flex;
      .Select-multi-value-wrapper {
        display: flex;
        align-items: center;
        .Select-value {
          padding: 0 5px;
          position: relative;
        }
      }
      .Select-arrow-zone {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: -24px;
        .Select-arrow {
          border-color: black transparent transparent;
          border-width: 6px 3px 2.5px;
        }
      }
    }
    .Select-option {
      background-color: $c_subtle_grey;
      padding: 4px 10px;
      &.is-focused {
        background-color: $c_blue_dark_10;
        color: $c_white;
      }
      
      &.is-selected.is-focused {
        background-color: $c_blue_dark_10;
        color: $c_white;
      }
    }
  }

  &-input {
    margin: 1em 0 2em;
  }

  &-column-title {
    margin: 0 0 1em 0;
  }
}

&__login-options {
  &-button {
    margin-right: 1rem;
  }
  &-email {
    margin-bottom: 1rem;
  }
  &-label {
    background-color: $c_blue_dark_10;
    color: $c_white;
    padding: 0.2rem 0.5rem;
    margin-left: 0.5rem;
    border-radius: 2rem;
    font-size: $fontSize-xs;
  }
  &-sync-info {
    margin-bottom: 1rem;
  }
}

.table-cell-editable {
  border: 1px solid $c_bd_grey_base;
  display: flex;
  &--center-content {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  &--disabled {
    background-color: $c_chrome_240;
  }
  &--highlight {
    &-grey {
      border-left: 5px solid $c_chrome_200;
    }
    &-red {
      border-left: 5px solid $c_coral_dark_10;
    }
  }
  @import 'button';
  @import 'icon';
  @import 'input';
  @import 'left';
  @import 'right';
  @import 'select';
  @import 'subtitle';
  @import 'title';
}

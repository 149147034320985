&__tree {
  list-style: none;
  margin: 0;
  padding: 0;
  &-item-wrapper {
    @extend %reset-button;
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 2rem;
    padding-left: 100%;
    padding-right: 24px;
    margin-left: -100%;
    &--loading {
      cursor: auto;
      pointer-events: none;
    }
  }
  &-item {
    margin: 0;
    position: relative;
    &--child {
      padding-left: 2rem;
    }
    &-expand-icon {
      margin-left: 1rem;
      transition: transform 0.3s ease;
      .organisation-sidebar__tree-item--child & {
        position: absolute;
        left: 1rem;
      }
      .organisation-sidebar__tree-item-wrapper--state-expanded & {
        transform: rotate(-180deg);
      }
      .icon {
        height: 0.75rem !important;
        width: 0.75rem !important;
        fill: $c_chrome_40;
      }
    }
    &-left {
      align-items: center;
      display: flex;
      margin-left: auto;
    }
    &-notifications {
      margin-left: 0.5rem;
      position: relative;
      top: 1px;
    }
    &-selection-icon-wrapper {
      align-items: center;
      display: flex;
      margin-left: 0.5rem;
    }
    &-selection-icon,
    &-spinner-icon {
      flex-shrink: 0;
      height: 20px; //margin-left: 0.5em;
      width: 20px;
    }
    &-selection-icon {
      fill: #aaaaaa;
      &--selected {
        fill: #009d8c;
      }
    }
    &-spinner-icon {
      fill: #009d8c;
      animation: spin 1s infinite ease-in;
      @keyframes spin {
        from {
          opacity: 1;
          transform: scale(0);
        }
        to {
          opacity: 0;
          transform: scale(1);
        }
      }
    }
    &-text {
      @extend %truncate;
      padding-left: 1.5rem;
      .organisation-sidebar__tree-item-wrapper--has-children & {
        padding-left: 0.5rem;
      }
      .organisation-sidebar__tree-item--child
        .organisation-sidebar__tree-item-wrapper--has-children
        & {
        padding-left: 1.5rem;
      }
    }
  }
  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

&__tab {
  @extend %reset-button;
  height: 2rem;
  position: relative;
  flex-shrink: 0;
  z-index: 1;
  cursor: pointer;
  color: $c_chrome_100;
  transition: background-color 0.3s ease;
  padding: 0 1rem;
  margin-right: 1rem;
  .scrollable-tabs--rounded & {
    border-radius: $bd_radius_sml;
  }
  &:hover {
    background-color: $c_chrome_240;
    color: $c_chrome_100;
  }
  &--state-active {
    color: $c_white;
    transition: color 0.3s ease-in;
    &:hover {
      color: $c_white;
    }
  }
  &-text {
    position: relative;
    z-index: 1;
  }
}


.e-spaced-caps{
	text-transform: uppercase;
	text-decoration: none;
	letter-spacing: 0.2em;
	font-size: $fontSize-md;
	&--small{
		@extend .e-spaced-caps;
		font-size: $fontSize-sm;
	}
	&--medium{
		@extend .e-spaced-caps;
	}
	&--large{
		@extend .e-spaced-caps;
		font-size: $fontSize-lg;
	}
}


.e-label-block{
	p, &{
		display: block;
		font-weight: bold;
		margin-bottom: 0.3em;
		color:$c_txt_grey-3;
	}
}

.e-label-normal{
	p, &{
		font-weight: normal;
		color:$c_txt_grey-3;
	}
}

.e-label-upper{
	p, &{
		text-transform: uppercase;
		font-size: $fontSize-xs;
		color:$c_txt_grey-3;
	}

}

p.e-label-upper{
	margin-bottom: 0.3em;
}




.e-marker{
	display: inline-block;
	background: $c_chrome_220;
	padding: 2px 0.7em;
	border-radius: $bd_radius_sml;

	font-size: 12px;
	&--blue{
		@extend .e-marker;
		background: $c_aqua_light_10;
	}

	&--shy{
		@extend .e-marker;
		background: transparent;
		font-weight: bold;
		color:$c_txt_blue-0;
	}
}



.e-notification-counter{
	background: $c_coral_dark_10;
	display: inline-block;
	padding: 4px 0.6em;
	font-weight: bold;
	font-size: 12px;
	color:$c_white;
	height: 22px;
	border-radius: 11px;
	text-align: center;
	min-width: 30px;
	float:right;
}
.e-notification-counter-nav{
	background: $c_coral_dark_10;
	display: inline-block;
	padding: 4px 0.6em;
	font-weight: bold;
	font-size: 12px;
	color:$c_white;
	height: 22px;
	border-radius: 11px;
	text-align: center;
	min-width: 30px;
}



.marker-segment  {
	.e-label-normal, .e-label-upper, .e-marker{
		margin-right: 0.4em;
	}
	& + .marker-segment{
		margin-left: 1em;
	}
}


.e-hd-md-upper{
	text-transform: uppercase;
	text-decoration: none;
	letter-spacing: 0.2em;
	font-size: $fontSize-md;
	margin-bottom:1.6em;
}



.e-initials{
	text-transform: uppercase;
}
.organisation-board-legend {
    display: flex;
    padding: 1rem 0 0;
    @import "color";
    @import "divider";
    @import "icon";
    @import "item";
    @import "list";
    @import "mentorship";
    @import "point";
}
$ui_border_size: 0;
$iconXSmall: 12px;
$iconSmall: 16px;
$iconMedium: 20px;
$iconLarge: 24px;
$iconXLarge: 28px;

%uiBtnCore {
  align-items: center;
  border-radius: $bd_radius_sml;
  border: $ui_border_size solid;
  cursor: pointer;
  display: inline-flex;
  font-family: $fontFamily;
  font-size: $uiFontSizeMedium;
  height: 3rem;
  line-height: 22px;
  margin: 0;
  outline: 0;
  overflow: visible;
  padding: 3px 30px 3px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }

  &.disabled,
  &[disabled] {
    cursor: default;
    opacity: 0.45;
  }

  &.ui-rounded {
    border-radius: calc($uiMediumHeight / 2);
  }

  // Size
  &.ui-small {
    font-size: $uiFontSizeSmall;
    padding: 0.3em 12px;
    height: auto;

    &.ui-rounded {
      border-radius: calc($uiSmallHeight / 2);
    }
  }

  // Icons
  .icon {
    fill: currentColor;
    vertical-align: text-bottom;
  }
}

.ui-btn {
  @extend %uiBtnCore;
  background: $c_prime_light_20;
  border-color: $c_prime_light_10;
  color: $c_prime_dark_20;
  &--full-width {
    justify-content: center;
    width: 100%;
  }
  &:hover {
    background-color: $c_prime_light_10;
    border-color: $c_prime_light_10;
    color: $c_prime_dark_20;
  }

  &__icon {
    &--left {
      margin-right: 0.5em;
    }

    &--right {
      margin-left: 0.5em;
    }
  }

  &-progress {
    background-color: $c_mint2;
    border-color: darken($c_mint2, 5%);
    color: #ffffff;

    &:hover {
      background-color: darken($c_mint2, 3%);
      border-color: darken($c_mint2, 5%);
      color: #ffffff;
    }
  }

  &-inactive {
    cursor: auto !important;
    background-color: $c_chrome_230;
    border-color: $c_chrome_230;
    color: $c_chrome_210;

    &:hover {
      background-color: $c_chrome_230;
      border-color: $c_chrome_230;
      color: $c_chrome_210;
    }
  }

  &-action {
    background-color: $c_prime_dark_10;
    border-color: $c_prime_dark_10;
    color: #ffffff;

    &:hover {
      background-color: $c_prime_dark_20;
      border-color: $c_prime_dark_20;
      color: #ffffff;
    }
  }

  &-darkui {
    background-color: $ui-dark--bg;
    border-color: darken($ui-dark--bg, 3%);
    color: #ffffff;

    &:hover {
      background-color: darken($ui-dark--bg, 1%);
      border-color: darken($ui-dark--bg, 3%);
      color: #ffffff;
    }
  }

  &-deep {
    background-color: $ui-dark-bg;
    border-color: darken($ui-dark-bg, 5%);
    color: #ffffff;

    &:hover {
      background-color: darken($ui-dark-bg, 3%);
      border-color: darken($ui-dark-bg, 5%);
      color: #ffffff;
    }
  }

  &-alert {
    background-color: $c_coral_dark_10;
    border-color: darken($c_coral_dark_10, 5%);
    color: #ffffff;

    &:hover {
      background-color: darken($c_coral_dark_10, 3%);
      border-color: darken($c_coral_dark_10, 5%);
      color: #ffffff;
    }
  }

  &-cancel {
    background-color: $c_chrome_180;
    border-color: $c_chrome_180;
    color: #ffffff;

    &:hover {
      background-color: $c_chrome_190;
      border-color: $c_chrome_190;
      color: #ffffff;
    }
  }

  &-invisible {
    background-color: transparent;
    border-color: transparent;
    color: $c_txt_grey-0;

    .icon {
      fill: $c_txt_grey-2;
    }

    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: $c_txt_grey-0;

      .icon {
        fill: $c_txt_grey-1;
      }
    }
  }

  &-shy {
    background-color: transparent;
    border-color: transparent;
    color: $c_txt_grey-0;

    &[disabled] {
      opacity: 1;
      &:hover {
        background-color: transparent;
        border-color: transparent;
      }
    }

    .icon {
      fill: $c_txt_grey-2;
    }

    &:hover {
      background-color: $c_subtle_grey_light_20;
      border-color: $c_subtle_grey_light_20;
      color: $c_txt_grey-0;

      .icon {
        fill: $c_txt_grey-1;
      }
    }

    &.active {
      background-color: darken($ui-dark-bg, 3%);
      border-color: darken($ui-dark-bg, 5%);
      color: #ffffff;
    }
  }

  &-slim {
    background-color: transparent;
    border-color: transparent;
    color: $c_txt_grey-3;

    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: $c_txt_grey-0;
    }

    &.active {
      background-color: transparent;
      border-color: transparent;
      color: $c_blue_dark_10;
    }
  }
}

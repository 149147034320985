&__text {
  margin-right: 1rem;
  max-width: 100%;
  overflow: hidden;
  user-select: none;
  width: 100%;
  &-title {
    line-height: 1.2;
    color: $c_txt_blue-0;
  }
  &-title,
  &-subtitle {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-subtitle {
    color: $c_chrome_120;
    font-size: $fontSize-xxs;
    line-height: 1;
  }
}

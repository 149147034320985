&__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 0.5rem;
  min-width: 0;
  flex-grow: 1;
  &-left {
    min-width: 0; // To truncate text
  }
}

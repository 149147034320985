.page {
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  &--fullscreen {
    position: fixed;
    background-color: $c_chrome_240;
    height: 100%;
    left: 0 !important;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    transition: all 0.3s ease;
    width: 100%;
    z-index: $z-index-full-screen-page;
  }
}

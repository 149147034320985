&__field {
  position: relative;
  &-error {
    position: absolute;
    bottom: 0;
    color: $c_white;
    font-size: 11px;
    background-color: $c_coral_dark_10;
    left: 0;
    right: 0;
    border-radius: 0px 0px 3px 3px;
    z-index: 1;
    padding: 1px 0.5rem;
  }

  &-label {
    display: none;
  }
  &-loader {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  &-input {
    flex-grow: 1;
    width: 100%;
    .login__field--has-loader & {
      padding-right: 4rem;
    }
  }
  &-icon {
    position: absolute;
    fill: $c_white;
    left: -2rem;
    top: 50%;
    transform: translateY(-50%);
  }
  &-wrapper {
    padding: .5rem 0;
  }
}

&__bar {
  height: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0.5rem;
  cursor: pointer;

  .gantt-task--not-clickable & {
    cursor: auto;
  }

  .gantt-task--no-end-date & {
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      height: 100%;
      border-right-style: dashed;
      border-right-color: $c_coral_dark_10;
      border-right-width: 3px;
      z-index: 2;
    }
  }

  &-background {
    color: $c_aqua_dark_20;
    background-color: $c_mint2;
    text-shadow: 0.5px 0.5px 1px #fff;
    .gantt-task--theme-grey & {
      background-color: $c_chrome_200;
      color: $c_chrome_80;
    }
  }
  &-background,
  &-foreground {
    align-items: flex-start;
    border-radius: $bd_radius_lg;
    border: 1px solid $c_aqua_dark_20;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    white-space: nowrap;
    .gantt-task--no-end-date & {
      border-radius: $bd_radius_lg 0 0 $bd_radius_lg;
    }
    .gantt-task--theme-grey & {
      border: 1px solid $c_chrome_200;
    }
  }
  &-foreground {
    background-color: $c_aqua_dark_20;
    text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.8);
    color: rgba(255, 255, 255, 0.9);
    z-index: 1;
    overflow: hidden;
    .gantt-task--theme-grey & {
      background-color: $c_chrome_140;
    }
  }
}

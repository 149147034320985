.label {
  &--style {
    &-upper {
      color: $c_txt_grey-3;
      display: inline-block;
      font-size: $fontSize-sm;
      letter-spacing: 0.1em;
      position: relative;
      text-transform: uppercase;
      user-select: none;
    }
  }
}

&__role-info {
    display: flex;
    height: 110px;
    padding: 1.5em 24px;
    &-donut {
        flex-shrink: 0;
        height: 68px !important;
        width: 68px !important;
    }
    &-mentor {
        border-left: 1px solid $c_subtle_grey_dark_10;
        margin-left: 1em;
        padding-left: 1em;
    }
}
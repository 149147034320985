.dashboard-filter {
  background-color: $c_chrome_240;
  height: 5rem;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  @import 'left';
  @import 'right';
  @import 'inner';
}

&__expand {
  &:hover {
    .icon {
      fill: $c_blue_dark_10;
      stroke-width: 1;
      stroke: $c_blue_dark_10;
    }
  }
  .organisation-board-role--area-goals-with-gaps & {
    .icon {
      fill: $c_coral_dark_10;
      stroke: $c_coral_dark_10;
    }
  }

  .organisation-board-role--area-goals-with-ongoing-gaps & {
    .icon {
      fill: $c_amber;
      stroke: $c_amber;
    }
  }

  .organisation-board-role__name--expanded & {
    transform: rotateX(180deg);
  }
}

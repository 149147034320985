&__icon {
  opacity: 0;
  fill: $c_white;
  height: 15px;
  overflow: visible;
  stroke: $c_white;
  transition: opacity 0.15s ease;
  width: 15px;
  .checkbox__input:checked + .checkbox__label & {
    opacity: 1;
  }
  .checkbox__input:checked ~ & {
    opacity: 1;
  }
}

.designboard-knowledge-area {
  align-items: center;
  cursor: pointer;
  display: flex;
  border-radius: $bd_radius_sml;

  flex-wrap: wrap;
  padding-bottom: 0.5em;
  position: relative;
  .designboard-role__knowledge-areas--additional & {
    &:before {
      border-bottom: 1px dashed $c_chrome_200;
    }
  }
  &:before {
    content: '';
    border-bottom: 1px solid $c_chrome_200;
    flex-shrink: 0;
    height: 1px;
    width: 12px;
  }
  &:nth-child(1):nth-last-child(1) {
    &:before {
      height: 31px;
      position: relative;
      top: -14px;
      width: 13px;
    }
  }
  &--hidden {
    opacity: 0 !important;
  }
  @import 'knowledge-elements';
  @import 'section';
  @import 'title';
}
&__input {
  background-color: $c_subtle_grey;
  border-radius: $bd_radius_sml;
  border: none;
  color: $c_txt_grey-0;
  font-size: $fontSize-md;
  height: 3rem;
  width: 100%;
  text-align: center;
  margin: 0 0.25rem;
  min-width: 0px;
  flex-grow: 1;
  &:disabled {
    opacity: 0.3;
  }
}

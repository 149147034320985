&__header {
  align-items: center;
  background-color: $c_aqua_dark_10;
  background: linear-gradient(
    to left,
    darken($c_aqua_dark_20, 5%) 40%,
    $c_aqua_dark-10 100%
  );
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  height: 4rem;
  position: sticky;
  top: 0px;
  z-index: 2;

  .organisation-board-unit--state-limited-visibility & {
    background: linear-gradient(
      to left,
      darken($c_chrome_160, 5%) 40%,
      $c_chrome_160 100%
    );
  }

  &-close {
    align-items: center;
    display: flex;
    &-button {
      @extend %reset-button;
      cursor: pointer;
      .icon {
        fill: $c_white;
      }
    }
  }
  &-context-menu-trigger {
    &:hover {
      .icon {
        fill: $c_white;
      }
    }
    /* .organisation-board-unit--read-only & {
      display: none;
    } */

    .icon {
      fill: $c_white;
    }
  }
  &-read-only-text {
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  &-title {
    display: flex;
    align-items: center;
  }
  &-title-text {
    color: $c_white;
    font-size: 1.2rem;
  }
  &-subtitle {
    color: $c_white;
    font-size: 0.833rem;
  }
}

.designboard-header {
  height: 4rem;
  background-color: $c_white;
  border-bottom: 1px solid $c_bd_grey_base;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  flex-shrink: 0;
  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $z-index-editor-header;
  }
  @import 'left';
  @import 'right';
}

&__header {
  &-left {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    
    @include for-tablet-landscape-up {
      grid-template-columns: auto auto auto;
    }

    .switch__label {
      font-size: $fontSize-md;
    }
  }
}

&__person {
  &-wrapper {
    align-items: center;
    background-color: $c_white;
    border: 1px solid $c_subtle_grey_light_10;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;
    min-height: 55px;
    padding: 1em;
    width: 100%;
  }
  &-input {
    margin: 0 !important;
    &-error {
        bottom: -24px !important;
    }
    &-field {
      flex-grow: 1;
      margin: 0 .5em;
    }
  }
  &-remove-icon {
      width: 25px !important;
  }
}

&__icon {
  fill: $c_chrome_120;
  height: 1.2rem !important;
  width: 1.2rem !important;
  .status-button--size-large & {
    height: 1.7rem !important;
    width: 1.7rem !important;
  }

  .status-button--color-yellow &,
  .status-button--color-green &,
  .status-button--color-red & {
    fill: $c_white;
  }
  .status-button--color-light-green & {
    fill: $c_aqua_dark_10;
  }
  .status-button--color-white & {
    fill: $c_chrome_120;
  }
  .status-button--state-loading & {
    display: none !important;
  }
}

.element-history-event-approval {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  &--nested {
    border-left: 1px solid $c_chrome_200;
    margin-bottom: 0rem;
    margin-left: 1rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    position: relative;
    &:last-of-type {
      border: none;
      padding-left: 1rem;
      padding-top: 0;
      margin-bottom: 1rem;
      &:before {
        left: 0;
        top: 0;
        border-left: 1px solid $c_chrome_200;
        position: absolute;
        height: 50%;
      }
    }
    &:before {
      content: '';
      border-bottom: 1px solid $c_chrome_200;
      flex-shrink: 0;
      height: 1px;
      width: 1rem;
      .element-history-event-approval:last-of-type & {
        content: '';
        border-bottom: 1px solid $c_chrome_200;
        border-left: 1px solid $c_chrome_200;
        flex-shrink: 0;
        height: 50%;
        width: 1rem;
      }
    }
  }
  @import 'inner';
  @import 'left';
  @import 'signature-button';
}

.select {
  appearance: none;
  border-radius: $bd_radius_sml;
  border: none;
  height: 3rem;
  background: $c_subtle_grey;
  border-color: $c_subtle_grey;
  color: $c_txt_grey-0;
  padding: 0 2rem 0 0.5rem;
  flex-grow: 1;
  &--size-small {
    height: 2rem;
  }
  &--size-xs {
    height: 1.5rem;
  }
  &-wrapper {
    display: flex;
    position: relative;
    align-items: center;
  }
  
  @import 'option';
  @import 'icon';
}

%uiInputCore {
  -webkit-appearance: none;
  border-radius: $bd_radius_sml;
  border: $ui_border_size solid;
  font-size: $uiFontSizeMedium;
  height: $uiMediumHeight;
  line-height: 1.4;
  outline: 0;
  padding: 0 $uiInputPadding 0;
  vertical-align: middle;

  &::-ms-clear {
    display: none;
  }

  &.disabled,
  &[disabled] {
    cursor: default;
    opacity: 0.45;
  }

  &.ui-small {
    height: $uiSmallHeight;
    font-size: $uiFontSizeSmall;
    padding: 3px $uiInputPadding 3px;
  }

  &.ui-large {
    height: $uiLargeHeight;
    font-size: $uiFontSizeLarge;
    padding: 5px $uiInputPadding 7px;
  }

  &.ui-rounded {
    border-radius: calc($uiMediumHeight / 2);
  }
}

.ui-input {
  @extend %uiInputCore;
  background-color: $c_subtle_grey;
  border-color: $c_subtle_grey;
  color: $c_txt_grey-0;
  transition: all 0.3s ease;

  &:focus {
    box-shadow: inset 0 0px 5px -1px rgba($c_black, 0.2);
    background-color: $c_subtle_grey_light_10;
    border-color: $c_subtle_grey_light_10;
    color: $c_txt_grey-0;
  }

  &-dark {
    background-color: #595959;
    border-color: #595959;
    color: #efefef;

    &:focus {
      background-color: #303030;
      border-color: #303030;
      color: #ffffff;
    }

    &.ui-val-success {
      background-color: #595959;
      border-color: $c_aqua;
      color: $c_aqua;
    }

    &.ui-val-error {
      background-color: #595959;
      border-color: $c_coral_dark_10;
      color: $c_coral_dark_10;
    }
  }

  &-outline {
    background-color: $c_white;
    border-color: $c_subtle_grey;
    color: $c_txt_grey-0;

    &:focus {
      background-color: $c_white;
      border-color: $c_subtle_grey_dark_10;
      color: $c_txt_grey-0;
    }
  }

  &-invisible {
    background: none;
    border: none;
    height: auto;
    padding: 0;

    &:focus {
      background: none;
      border: none;
      box-shadow: none;
      outline: none;
    }
  }
}

.ui-input-icon {
  position: relative;
  display: inline-block;

  .icon {
    position: absolute;
  }

  &.ui-wrap-small {
    .icon {
      top: 8px;
      left: -30px;
      width: $iconSmall;
      height: $iconSmall;
    }
  }

  &.ui-wrap-medium {
    .icon {
      top: 14px;
      left: -34px;
      width: $iconMedium;
      height: $iconMedium;
    }
  }

  &.ui-wrap-large {
    .icon {
      top: 16px;
      left: -38px;
      width: $iconLarge;
      height: $iconLarge;
    }
  }
}

.ui-textarea {
  @extend %uiInputCore;
  resize: vertical;
}

// Checkbox Radio
///////////////////////////////////////////////////
%uiCheckRadioLabel {
  display: block;
}

%uiCheckRadioInput {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ui-field-checkbox {
  position: relative;
  display: block;
  @include clearfix;
  user-select: none;

  .ui-checkbox {
    display: block;
    width: 24px;
    height: 24px;
    outline: none;
    cursor: pointer;
    padding: 0;
    margin: 0 8px 0 0;
    float: left;
    border-radius: $bd_radius_sml;

    .icon {
      opacity: 0;
      position: absolute;
      width: 18px;
      height: 18px;
      top: 4px;
      left: 4px;
      pointer-events: none;
      fill: $c_white;
    }
  }

  .cb-label {
    display: block;
    font-weight: 200;
    font-size: $fontSize-md;
    padding-left: 36px;
    padding-top: 4px;
  }

  label {
    display: inline-block;
    cursor: pointer;
    @extend %uiCheckRadioLabel;
  }

  input[type='checkbox'] {
    @extend %uiCheckRadioInput;
    border-radius: 3px;
  }

  input[type='checkbox']:checked {
    + label {
      .ui-checkbox {
        background: transparent;
      }

      .icon {
        opacity: 1;
      }
    }
  }

  &--outline {
    @extend .ui-field-checkbox;

    .ui-checkbox {
      background: transparent;
      border: 1px solid rgba($c_white, 0.8);
    }

    input[type='checkbox']:checked {
      + label {
        .ui-checkbox {
          background: rgba($c_black, 0.1);
          background: transparent;
        }
      }
    }
  }
}

// Select
///////////////////////////////////////////////////
%uiSelectCore {
  -webkit-appearance: none;
  border-radius: $bd_radius_sml;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-family: $fontFamily;
  font-size: $uiFontSizeMedium;
  height: $uiMediumHeight;
  line-height: $uiMediumHeight;
  outline: none;
  padding: 0 30px 0 $uiInputPadding;
  position: relative;
  vertical-align: middle;
  z-index: 1;

  &.disabled,
  &[disabled] {
    cursor: default;
    opacity: 0.45;
  }

  // MS fix
  &::-ms-expand {
    display: none;
  }

  // Firefox fix
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';

  &.ui-small {
    height: $uiSmallHeight;
    line-height: $uiSmallHeight;
  }

  &.ui-large {
    height: $uiLargeHeight;
    line-height: $uiLargeHeight;
  }

  &::-ms-expand {
    display: none;
  }
}

.ui-select-element {
  @extend %uiSelectCore;
  background: $c_subtle_grey;
  border-color: $c_subtle_grey;
  color: $c_txt_grey-0;

  &.ui-val-success {
    background: $c_subtle_grey;
    border-color: $c_aqua;
    color: $c_txt_grey-0;
  }

  &.ui-val-error {
    background: $c_coral_light_20;
    border-color: $c_coral_dark_10;
    color: $c_txt_grey-0;
  }

  &-shy {
    background: transparent;
    border-color: transparent;
    color: $c_txt_grey-0;

    &:hover {
      background: $c_subtle_grey_light_20;
      border-color: $c_subtle_grey_light_20;
      color: $c_txt_grey-0;
    }

    &.ui-val-success {
      background: #595959;
      border-color: $c_aqua;
      color: $c_aqua;
    }

    &.ui-val-error {
      background: #595959;
      border-color: $c_coral_dark_10;
      color: $c_coral_dark_10;
    }
  }
}

.ui-select {
  background-color: $c_subtle_grey;
  border: none;
  border-color: $c_subtle_grey;
  border-radius: $bd_radius_sml;
  color: $c_txt_grey-0;
  display: inline-block;
  font-size: 14px;
  height: 50px;
  padding: 0 12px;
  position: relative;
  width: 100%;

  &:focus {
    box-shadow: inset 0 0px 5px -1px rgba($c_black, 0.2);
    background-color: $c_subtle_grey_light_10;
    border-color: $c_subtle_grey_light_10;
    color: $c_txt_grey-0;
    outline: none;
  }

  .icon {
    position: absolute;
    top: 50%;
    margin-top: -7px;
    right: $gutter-sm;
    z-index: 1;
    pointer-events: none;
  }

  &-dark {
    background-color: $c_chrome_230;
    width: auto;

    &:focus {
      box-shadow: none;
      background-color: $c_chrome_230;
      border-color: $c_subtle_grey_light_10;
      color: $c_txt_grey-0;
      outline: none;
    }
  }
}

.ui-field {
  margin-bottom: $gutter-sm;
  position: relative;

  label {
    display: block;
    margin-bottom: $gutter-xs;
    color: $c_txt_grey-3;
    font-size: $fontSize-sm;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  .ui-input {
    width: 100%;
    margin-bottom: $fontSize-xl;
  }

  .ui-select {
    width: 100%;
    margin-bottom: $fontSize-xl;
  }

  .ui-field__error {
    height: $fontSize-xl;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;

    .block {
      background: $c_coral_dark_10;
      height: $fontSize-xl;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: $gutter-xs $gutter-sm;
      font-size: $fontSize-sm;
      border-radius: 0 0 $bd_radius_sml $bd_radius_sml;
      color: $c_white;
      opacity: 0;
      transform: translateY(-$fontSize-xl);
      transition: all 0.3s ease;
    }
  }

  &.ui-field--errors {
    .ui-input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:focus {
        box-shadow: none;
      }
    }

    .ui-field__error {
      .block {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

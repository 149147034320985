.icon {
  flex-shrink: 0;
  &--color {
    &-blue {
      fill: $c_blue_dark_10 !important;
    }
    &-green {
      fill: $c_aqua_dark_20 !important;
    }
    &-dark-grey {
      fill: $c_chrome_80 !important;
    }
    &-grey {
      fill: $c_chrome_120 !important;
    }
    &-light-grey {
      fill: $c_chrome_180 !important;
    }
    &-red {
      fill: $c_coral_dark_20 !important;
    }
    &-white {
      fill: $c_white;
    }
  }
  .ui-btn & {
    margin-right: 0.5rem;
  }

  &-button-wrapper {
    cursor: pointer;
    @extend %reset-button;
  }

  &.ui-x-small {
    width: $iconXSmall;
    height: $iconXSmall;
  }
  &.ui-small {
    width: $iconSmall;
    height: $iconSmall;
  }

  &.ui-medium {
    width: $iconMedium;
    height: $iconMedium;
  }

  &.ui-large {
    width: $iconLarge;
    height: $iconLarge;
  }

  &.ui-xlarge {
    width: $iconXLarge;
    height: $iconXLarge;
  }
}

.organisation-board-goal {
  height: 35px;
  min-width: 50px;
  position: relative;
  user-select: none;
  width: 50px;

  .organisation-board-unit--read-only & {
    display: none;
  }

  @import 'box';
  @import 'checkbox';
  @import 'counter';
  @import 'indicator';
  @import 'legend';
  @import 'line';
  @import 'load-overlay';
  @import 'no-goal';
  @import 'text';
}

&__cell {
  border-width: 0px 0.5px 0px 0.5px;
  border-color: $c_chrome_230;
  border-style: solid;
  &--grey {
    background-color: $c_chrome_230;
  }
  &--no-border {
    border: none;
  }
  &--left-border {
    border-left: .5px solid $c_chrome_230;
  }
}

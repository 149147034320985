&__right {
  @extend %reset-button;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  .menu-open & {
    transform: translateX(-($mainBarOpenWidth - $mainBarWidth));
  }
}

&__completion {
  color: $c_chrome_0;
  font-size: 11px;
  pointer-events: none;
  position: absolute;
  transition: all .3s ease;
  z-index: 1;
  .progress-diagram:hover & {
    opacity: 0;
  }
  &-line {
    background-color: $c_chrome_0;
    width: 2px;
    height: 2rem;
  }
  &-text {
    position: absolute;
    top: calc(100% + 0.2rem);
    left: 50%;
    transform: translateX(-50%)
  }
  &-triangle {
    @include triangle(.5rem, .5rem, 'up', $c_chrome_0);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%)
  }
}
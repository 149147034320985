&__text {
  flex-grow: 1;
  max-width: 80%;
  &-subtitle {
    font-size: 9px;
    color: $c_txt_grey-3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-title {
    font-size: 12px;
    line-height: 1;
    white-space: normal;
    .context-menu-item--disabled & {
      color: $c_txt_grey-3;
    }
  }
}

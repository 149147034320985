&__scroll {
    display: flex;
    justify-content: space-between;
    &-button {
        background: transparent;
        border: 0;
        cursor: pointer;
    }
    &-icon {
        fill: #007065;
        height: 20px !important;
        width: 20px !important;
    }
}
&__input {
  @extend %reset-appearance;
  border: 1px solid $c_aqua_dark_20;
  height: 1.5rem;
  text-align: center;
  width: 2.5rem;
  -moz-appearance: textfield;

  &[disabled] {
    border: 1px solid $c_aqua;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

&__overlay {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: background-color 0.3s ease;
  width: 100%;
  position: absolute;
  height: 100%;
  .status-button--color-green & {
    background-color: $c_aqua_dark_10;
  }
  .status-button--color-yellow & {
    background-color: $c_amber;
  }
  .status-button--color-red & {
    background-color: $c_coral_dark_10;
  }
  .status-button--color-white & {
    background-color: $c_white;
  }
  .status-button--color-light-green & {
    background-color: $c_mint2;
  }
  &-text {
    font-size: $fontSize-lg;
    color: $c_chrome_100;
    .status-button--color-green & {
      color: $c_white;
    }
    .status-button--color-yellow & {
      color: $c_white;
    }
    .status-button--color-red & {
      color: $c_white;
    }
    .status-button--color-white & {
      color: $c_chrome_120;
    }
    .status-button--color-light-green & {
      color: $c_aqua_dark_10;
    }
  }
}

&__options {
    display: flex;
    flex-shrink: 0;
    margin-bottom: 1em;
    &-btn {
        border-radius: 0;
    }
    &-btn-icon {
        margin-right: 1em;
    }
    &-filter-input {
        background: $c_chrome_240;
        height: 50px;
        padding: 0 24px;
        width: 100%;
        &:focus {
            background: $c_chrome_240;
        }
    }
}
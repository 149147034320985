&__input {
  background-color: $c_subtle_grey;
  border-radius: $bd_radius_sml;
  border: none;
  color: $c_txt_grey-0;
  font-size: $fontSize-md;
  height: 3rem;
  outline: none;
  padding: 0 0.5rem;
  transition: all 0.3s ease;
  font-family: inherit;
  width: 100%;

  &:disabled {
    opacity: 0.3;
  }

  &:focus {
    box-shadow: inset 0 0px 5px -1px rgba($c_black, 0.7);
    background-color: $c_subtle_grey_light_10;
    color: $c_txt_grey-0;
  }

  .date-field--no-value & {
    color: #808080;
  }

  &-wrapper {
    position: relative;
  }
}

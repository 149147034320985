.modal {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-modal;
  display: flex;
  align-items: center;
  justify-content: center;

  @import 'box';
}

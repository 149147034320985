&__input {
  flex-grow: 1;
  border-radius: 0;
  border: none;
  padding: 0 0.5rem;
  font-size: $fontSize-sm;

  .table-cell-editable--text-uppercase & {
    text-transform: uppercase;
  }
}

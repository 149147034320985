.knowledge-element {
  align-items: center;
  display: flex;
  transition: all 0.3s ease;
  background-color: $c_white;
  border-bottom: 1px solid $c_bd_grey_base;
  min-height: 3.5rem;
  position: relative;
  /* &--highlight {
    border-left: 7px solid $c_coral_dark_10;
  } */

  &--top-border {
    border-top: 1px solid $c_bd_grey_base;
  }

  @import 'button';
  @import 'description';
  @import 'highlight';
  @import 'info';
  @import 'lock-button';
  @import 'status-button';
}

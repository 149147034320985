.document {
  align-items: center;
  background-color: $c_chrome_250;
  border-radius: $bd_radius_sml;
  border: 2px solid $c_chrome_240;
  display: flex;
  margin: 0 0 1rem;
  min-height: 80px;
  padding: 1rem;
  transition: all 0.3 ease;

  @import 'button';
  @import 'delete';
  @import 'icon';
  @import 'info';
}

&__control {
  background-color: $c_subtle_grey !important;
  border-radius: $bd_radius_sml;
  border: none !important;
  box-shadow: none !important;
  color: $c_txt_grey-0;
  min-height: 3rem !important;
  outline: none;
  padding: 0.5rem 0;
  transition: all 0.3s ease;
  &--is-focused {
    outline: none;
    box-shadow: inset 0 0px 5px -1px rgba($c_black, 0.7) !important;
    background-color: $c_subtle_grey_light_10 !important;
  }
  &--is-disabled {
    opacity: 0.5;
  }
}

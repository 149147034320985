&__icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid $c_chrome_220;
  .dropdown-button--color-green & {
    border-left: 1px solid $c_aqua_dark_20;
  }
}

.checkbox {
  align-items: center;
  position: relative;
  display: flex;
  @import 'icon';
  @import 'input';
  @import 'label';
  @import 'text';
  @import 'tooltip';
}

.session-status-button {
  @extend %reset-button;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 8rem;
  padding: 0 1rem 0 2rem;
  align-self: stretch;
  cursor: pointer;
  &--color {
    &-white {
      background-color: $c_white;
      border-left: 1px solid $c_bd_grey_base;
    }
    &-green {
      background-color: $c_aqua_dark_10;
      border-left: 1px solid $c_bd_grey_base;
    }
    &-light-green {
      background-color: $c_mint2;
      border-left: 1px solid $c_mint2;
    }
  }
  @import 'icon';
  @import 'text';
}

.element-description {
  padding: 1rem;
  &--full-width {
    width: 100%;
    max-width: none;
  }

  .rich-text-preview & {
    background-color: $c_chrome_250;
    border: 1px solid $c_chrome_230;
  }

  @import 'body';
  @import 'header';
}

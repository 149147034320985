&__label {
  background: $c_chrome_220;
  border-radius: 2px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  .checkbox--style-stroked & {
    background-color: transparent;
    border: 2px solid $c_chrome_160;
  }
  .checkbox__input[disabled] ~ & {
    opacity: 0.4;
    cursor: auto;
  }
  .checkbox__input:checked ~ & {
    background: $c_aqua_dark_10;
    border: 2px solid $c_aqua_dark_10;
  }
}

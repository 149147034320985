&__organisation-unit {
  &--child {
    margin-left: 48px;
  }
  &--moving {
    border: 1px dashed $c_blue_dark_20;
    background-color: $c_blue_light_20;
    padding: 0 0.5rem;
  }
  &--hide {
    display: none;
  }
  &-addbox,
  &-wrapper {
    align-items: center;
    background-color: $c_white;
    border: 1px solid $c_subtle_grey_light_10;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-wrap: wrap;
    margin: 0.75rem 0;
    min-height: 55px;
    padding: 1em;
  }
  &-addbox {
    margin-left: 48px;
  }
  &-btn-icon {
    margin-left: 0.5em;
  }
  &-button {
    margin-left: 0.5rem;
  }
  &-delete-icon {
    fill: $c_chrome_120;
  }
  &-delete-icon-wrapper {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-right: 0.5em;
  }
  &-form {
    display: flex;
    width: 100%;
    flex-grow: 1;
    .input-wrapper {
      flex-grow: 1;
      display: flex;
    }
  }
  &-info {
    flex-grow: 1;
  }
  &-input {
    font-size: inherit;
    width: 100%;
    display: flex;
    flex-grow: 1;
    .input-wrapper {
      flex-grow: 1;
    }
  }
  &-list {
    @extend %reset-list;
  }
  &-radio-button {
    margin-left: auto;
  }
  &-subtitle {
    color: $c_chrome_120;
    font-size: 12px;
    line-height: 1;
  }
  &-text {
    flex-grow: 1;
  }
  &-title-icon {
    cursor: pointer;
    fill: $c_chrome_120;
    height: 15px !important;
    margin-left: 0.5em;
    opacity: 0;
    transition: opacity 0.3s ease;
    width: 15px !important;
    .organisation-unit-modal__organisation-unit-title:hover & {
      opacity: 1;
    }
  }
  &-wrapper {
    cursor: pointer;
    width: 100%;
    .organisation-modal__organisation-list--editor & {
      cursor: auto;
    }
  }
}

&__focus-header {
  &-checkbox {
    margin-right: 1rem;
  }
  &-left {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: white;
    z-index: 1;
    border-right: 1px solid $c_bd_grey_base;
    position: relative;
  }
  &-right {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: white;
    z-index: 1;
    position: relative;
  }
  &-placeholder-cell {
    height: 40px;
    border-right: 1px solid $c_bd_grey_base;
    flex-shrink: 0;
  }
  &-focused-cell {
    display: flex;
    align-items: center;
    height: 40px;
    position: relative;
    font-size: $fontSize-sm;
    padding: 0 0.5rem;
    border-bottom: 1px solid $c_bd_grey_base;
    border-right: 1px solid $c_bd_grey_base;
    width: 100%;
  }
  &-search-input {
    font-size: $fontSize-sm !important;
    height: 1.5rem !important;
    min-width: 10rem;
  }
}

&__header-cell {
  display: flex;
  align-items: center;
  font-size: $fontSize-xs;
  color: rgba(255, 255, 255, 0.7);
  padding-left: 0.5rem;
  white-space: nowrap;
  &--day {
    padding: 0;
    justify-content: center;
  }
  &--day-first, &--left-border {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }
  &--day-hidden {
    span {
      visibility: hidden;
    }
  }
  &--day-today {
    span {
      z-index: 1;
      position: relative;
    }
    &:before {
      content: '';
      position: absolute;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 0.75rem;
      background-color: $c_coral_dark_10;
    }
    &.gantt-chart__header-cell--day-hidden {
      &:before {
        visibility: hidden;
      }
    }
  }
}

&__name {
    color: $c_blue_dark_20;
    font-size: 11px;
    max-width: 100%;
    &-initials {
        border-bottom: none !important;
        text-decoration: none !important;
        text-transform: uppercase;
    }
    &-text {
        font-size: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
&__body {
  display: flex;
  flex-direction: column;

  &-categories {
    @extend %reset-list;
    padding: 1.5em 24px 3em;
  }

  &-categories-item {
    align-items: center;
    background-color: $c_white;
    border: 1px solid $c_subtle_grey_light_10;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;
    min-height: 55px;
    padding: 1em;
  }

  &-categories-item-icon-wrapper {
    align-items: center;
    cursor: pointer;
    display: flex;
  }

  &-categories-item-delete-icon {
    fill: $c_chrome_120;
  }

  &-categories-item-radio-button {
    margin-left: .5em;
  }

  &-categories-item-info {
    margin-right: auto;
  }

  &-categories-item-name-icon {
    cursor: pointer;
    fill: $c_chrome_120;
    height: 15px !important;
    margin-left: 0.5em;
    opacity: 0;
    transition: opacity 0.3s ease;
    width: 15px !important;

    .categories-modal__body-categories-item-info:hover & {
      opacity: 1;
    }
  }

  &-categories-item-subtitle {
    color: $c_chrome_120;
    font-size: 12px;
    line-height: 1;
  }

  &-info-message {
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
}
&__form {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    width: 100%;
    &-btn-cancel,
    &-btn-submit {
        height: auto;
    }
    &-btn-submit {
        margin: 0 .5em 0 2em;
    }
    &-label {
        margin-bottom: 1em;
    }
    &-input {
        width: 100%;
        &:focus {
            outline: none;
        }
    }
}
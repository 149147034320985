.training-session-card {
  @extend %card-border;
  @include box_shadow(1);
  background-color: $c_white;
  border-radius: $bd_radius_sml;
  transition: box-shadow 0.3s ease;
  position: relative;
  &:hover {
    @include box_shadow(2);
    color: $c_black;
  }
  @import 'body';
  @import 'context-menu';
  @import 'detail';
  @import 'divider';
  @import 'footer';
  @import 'header';
  @import 'inner';
  @import 'person-counter';
  @import 'progress-bar';
}

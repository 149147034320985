&__item {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: 0.5rem;
  .content-administration__legend & {
    margin-bottom: 0;
  }
  &-text {
    margin-left: 0.25rem;
  }
  .legend--no-padding & {
    margin-bottom: 0;
  }
}

.gantt-task {
  height: 3rem;
  position: relative;
  @import 'bar';
  @import 'cursor-box';
  @import 'content';
  @import 'drag-handle';
  @import 'end-date-alert';
  @import 'main-content';
  @import 'secondary-content';
  @import 'subtitle';
  @import 'time-progress';
  @import 'title';
}

.status-button {
  display: flex;
  height: 2.5rem;
  width: 2.5rem;
  flex-shrink: 0;
  position: relative;

  .dashboard & {
    // Accomendate border when in dashboard
    height: calc(2.5rem - 2px);
    width: calc(2.5rem - 2px);
  }

  .knowledge-element &,
  .section-list-item & {
    border-left: 1px solid $c_bd_grey_base;
  }
  .section-list-item & {
    height: auto;
  }

  &--full-width {
    width: 100%;
    .dashboard & {
      // Accomendate border when in dashboard
      height: calc(2.5rem - 2px);
      width: 100%;
    }
  }
  &--size {
    &-large {
      height: 3.5rem;
      width: 3.5rem;
    }
  }
  @import 'action';
  @import 'actions';
  @import 'button';
  @import 'icon';
  @import 'overlay';
  @import 'text';
}

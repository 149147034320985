&__toggle {
  position: relative;
  height: $main-header-height;
  &-button {
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    transform: translate(15px, -50%);
    transition: transform 0.3s ease;
    width: 2.5rem;
    height: 2.5rem;
    .menu-open & {
      transform: translate(210px, -50%) rotate(180deg);
    }
  }

  .icon {
    transform: translateX(3px);
  }
}

.error-page {
  height: 100%;
  color: $c_white;
  background-image: url('/assets/images/login.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  @import 'button';
  @import 'content';
  @import 'overlay';
  @import 'title';
}

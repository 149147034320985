.app-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#portal-root {
  position: relative;
  z-index: $z-index-portal-root;
}

#cursor-content-root {
  position: relative;
  z-index: $z-index-portal-root;
}

.rc-tooltip {
  ul {
    margin-top: 0;
  }
  li {
    margin-bottom: 0;
  }
}

.root {
  height: 100%;
  overflow: hidden;
}

.root-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.system-blockui-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: $z-index-systemlayer;
}

.react-datepicker-popper {
  z-index: $z-index-datepicker;
}

.react-datepicker-wrapper {
  width: 100%;
}

#crisp-chatbox {
  z-index: $z-index-crisp-chatbox !important;
}

#crisp-chatbox a {
  background-color: $c_aqua_dark_20 !important;
  z-index: $z-index-crisp-chatbox !important;
}

&__info {
    margin-right: 1em;
    max-width: 100%;
    overflow: hidden;
    &-name {
        color: $c_txt_blue-0;
    }
    &-name,
    &-subtitle {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &-subtitle {
        color: $c_chrome_120;
        font-size: 12px;
    }
}
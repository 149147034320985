&__multi-value {
  background-color: $c_white !important;
  &__label {
    font-size: $fontSize-md !important;
  }
  &__remove {
    &:hover {
      background-color: transparent !important;
    }
  }
}

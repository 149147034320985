&__content {
  $iconSize: 125px;
  left: 50%;
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  max-width: 500px;
  &-button {
    height: auto;
    padding: 10px 30px 10px;
  }
  &-icon {
    position: absolute;
    fill: $c_white;
    width: calc($iconSize / 2) !important;
    height: calc($iconSize / 2) !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &-icon-wrapper {
    background-color: $c_aqua_dark_10;
    border-radius: calc($iconSize / 2);
    width: $iconSize;
    height: $iconSize;
    position: relative;
    margin: 0 auto 2em;
  }
  &-title {
    color: $c_txt_grey-2;
    margin-bottom: 1.5em;
  }
}

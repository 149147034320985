// <CG: Prime
///////////////////////////////////////////////////
$c_prime_light_20: #def1f7;
$c_prime_light_10: #b5e7f7;
$c_prime: #52cbfb;
$c_prime_dark_10: #019cd5;
$c_prime_dark_20: #005d97;
// <END
// <CG: Sekunda
///////////////////////////////////////////////////
$c_sekunda_light_20: #ffdbe0;
$c_sekunda_light_10: #ffb8bd;
$c_sekunda: #ff828a;
$c_sekunda_dark_10: #e3515c;
$c_sekunda_dark_20: #ae1935;
// <END
// <CG: Blue
///////////////////////////////////////////////////
$c_blue_light_20: #def1f7;
$c_blue_light_10: #b5e7f7;
$c_blue: #52cbfb;
$c_blue_dark_10: #019cd5;
$c_blue_dark_20: #005d97;
// <END
// <CG: Mint2
///////////////////////////////////////////////////
$c_mint2_light_20: #e0ffff;
$c_mint2_light_10: #d4f7f1;
$c_mint2: #a7efe3;
$c_mint2_dark_10: #7ee7d6;
$c_mint2_dark_20: #53dfc8;
// <END
// <CG: Mint
///////////////////////////////////////////////////
$c_mint_light_20: #e0ffff;
$c_mint_light_10: #c1f3f3;
$c_mint: #a9efe3;
$c_mint_dark_10: #48b8b9;
$c_mint_dark_20: #007f80;
// <END
// <CG: Aqua
///////////////////////////////////////////////////
$c_aqua_light_20: #dbfdf9;
$c_aqua_light_10: #a3eee2;
$c_aqua: #67c9bb;
$c_aqua_dark_10: #009d8c;
$c_aqua_dark_20: #008c7d;
$c_aqua_dark_30: mix(black, $c_aqua, 70%);
// <CG: Green
///////////////////////////////////////////////////
//$c_green:#2FB58B;
$c_green: #2fb58b;
// <END
// <CG: Coral
///////////////////////////////////////////////////
$c_coral_light_20: #ffdbe0;
$c_coral_light_10: #ffb8bd;
$c_coral: #ff828a;
$c_coral_dark_10: #e3515c;
$c_coral_dark_20: #ae1935;
$c_coral_dark_30: mix(black, $c_coral, 30%);
$c_coral_dark_40: mix(black, $c_coral, 40%);
$c_coral_dark_50: mix(black, $c_coral, 50%);
$c_coral_dark_60: mix(black, $c_coral, 60%);
$c_coral_dark_70: mix(black, $c_coral, 70%);
// <END
///////////////////////////////////////////////////
$c_tan_light_20: #fdfbf3;
$c_tan_light_10: #f9f7ed;
$c_tan: #f5f3e7;
$c_tan_dark_10: #f1efe1;
$c_tan_dark_20: #edebdb;
$c_tan_dark_30: #6d6739;
// <END
// <CG: Yellow
///////////////////////////////////////////////////
$c_yellow_light_20: #ffffcd;
$c_yellow_light_10: #fff697;
$c_yellow: #ffdb57;
$c_yellow_dark_10: mix(black, $c_yellow, 10%);
$c_yellow_dark_20: mix(black, $c_yellow, 20%);
$c_yellow_dark_30: mix(black, $c_yellow, 30%);
$c_yellow_dark_40: mix(black, $c_yellow, 40%);
$c_yellow_dark_50: mix(black, $c_yellow, 50%);
$c_yellow_dark_60: mix(black, $c_yellow, 60%);
$c_yellow_dark_70: mix(black, $c_yellow, 70%);
// <END
// <CG: Yellow
///////////////////////////////////////////////////
$c_amber: #f79646;
$c_amber_light_10: mix(white, $c_amber, 10%);
$c_amber_light_20: mix(white, $c_amber, 20%);
$c_amber_dark_10: mix(black, $c_amber, 10%);
$c_amber_dark_20: mix(black, $c_amber, 20%);
// <END
// <CG: Subtlegrey
///////////////////////////////////////////////////
$c_subtle_grey_light_20: #f4f3f3;
$c_subtle_grey_light_10: #f3f2f1;
$c_subtle_grey: #edebea;
$c_subtle_grey_dark_10: #e7e5e3;
$c_subtle_grey_dark_20: #e1dedd;
// <END
// <CG: Greytones
///////////////////////////////////////////////////
$c_chrome_0: #000000;
$c_chrome_10: #0a0a0a;
$c_chrome_20: #141414;
$c_chrome_30: #1e1e1e;
$c_chrome_40: #282828;
$c_chrome_50: #323232;
$c_chrome_60: #3c3c3c;
$c_chrome_70: #464646;
$c_chrome_80: #505050;
$c_chrome_90: #5a5a5a;
$c_chrome_100: #646464;
$c_chrome_110: #6e6e6e;
$c_chrome_120: #787878;
$c_chrome_130: #828282;
$c_chrome_140: #8c8c8c;
$c_chrome_150: #969696;
$c_chrome_160: #a0a0a0;
$c_chrome_170: #aaaaaa;
$c_chrome_180: #b4b4b4;
$c_chrome_190: #bebebe;
$c_chrome_200: #c8c8c8;
$c_chrome_210: #d2d2d2;
$c_chrome_220: #e6e6e6;
$c_chrome_230: #f0f0f0;
$c_chrome_240: #f7f7f7;
$c_chrome_250: #fdfdfd;
$c_chrome_255: #ffffff;
// <END
// <CG: Greytones
///////////////////////////////////////////////////
$c_white: #fff;
$c_black: #000;
// <END

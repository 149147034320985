&__name {
  align-items: center;
  display: flex;
  height: 35px;
  min-width: 245px;
  padding: 0 0.5rem 0 1rem;
  width: 245px;

  &-context-menu {
    &-icon {
      fill: $c_chrome_170;
    }

    &-delete-role-icon {
      fill: $c_coral_dark_20;
    }
  }

  &-edit-icon {
    fill: $c_chrome_120;
    flex-shrink: 0;
    height: 1rem !important;
    margin: 0 0.5rem;
    opacity: 0;
    width: 1rem !important;
    transition: opacity 0.3s ease;

    .organisation-board-role__name:hover & {
      opacity: 1;
    }
  }

  &-expand-icon {
    stroke-width: 2px;
    height: 10px !important;
    width: 10px !important;

    .organisation-board-role--area-goals-with-gaps & {
      fill: $c_coral_dark_10;
      stroke: $c_coral_dark_10;
    }

    .organisation-board-role--area-goals-with-ongoing-gaps & {
      fill: $c_amber;
      stroke: $c_amber;
    }

    .organisation-board-role__name--expanded & {
      transform: rotateX(180deg);
    }

    &-wrapper {
      margin-left: auto;
    }
  }

  &-expansion-wrapper {
    align-items: center;
    cursor: auto;
    display: flex;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--expandable {
      cursor: pointer;
    }
  }

  &-input {
    background: none;
    font-size: 12px;
    height: auto;
    padding: 0;

    &:focus {
      background: none;
      box-shadow: none;
    }
  }

  &-text {
    //flex: 1;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

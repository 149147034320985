&__button {
  @extend %reset-button;
  display: inline-flex;
  align-items: center;
  border: 1px solid $c_chrome_220;
  border-radius: $bd_radius_sml;
  cursor: pointer;
  .dropdown-button--color-green & {
    background-color: $c_aqua_dark_10;
    border: 1px solid $c_aqua_dark_10;
    color: $c_white;
  }
}

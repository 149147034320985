&__toolbar {
  background-color: $c_chrome_240;
  padding: 0rem;
  margin: 0;
  .rdw-link-modal-target-option {
    display: none;
  }
  .rdw-inline-wrapper,
  .rdw-list-wrapper,
  .rdw-history-wrapper,
  .rdw-link-wrapper,
  .rdw-remove-wrapper {
    margin: 0;
    align-items: center;
  }

  .rdw-option-wrapper {
    background-color: $c_chrome_240;
    border: none;
    padding: 1rem 0.7rem;
    margin: 0;

    img {
      opacity: 0.7;
    }

    &.rdw-option-active,
    &:hover {
      background-color: $c_chrome_220;
      box-shadow: none !important;
    }

    .rt-editor--invisible & {
      padding: 1em;
    }
  }

  .rt-editor--invisible & {
    background-color: transparent;
    border: none;
    margin: 0 -4px 1em;
    padding: 0;
  }

  .modal-content__body & {
    flex-shrink: 0;
  }
}

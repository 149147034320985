&__text {
  margin-top: 0.3rem;
  font-size: $fontSize-xxs;
  text-transform: uppercase;
  .donut--grey & {
    color: $c_chrome_120;
  }
  .donut--green & {
    color: $c_aqua_dark_20;
  }
  .donut--light-grey & {
    color: $c_chrome_200;
  }
}

&__item {
  margin-bottom: 0;
  transition: width 0.3s ease, background-color 0.1s ease;
  position: relative;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &--expanded {
    &:hover {
      background: none;
    }
  }

  &--nested {
    width: $mainBarOpenWidth;
  }

  &--initials {
    cursor: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    height: 2rem;

    &:hover {
      background: none;
    }
  }
  &--margin {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    margin-top: 2rem;
  }
  &-expand-icon {
    fill: $c_white;
    opacity: 1;
    opacity: 0.6;
    .navigation__item--expanded & {
      transform: rotate(180deg);
    }
  }

  &-icon {
    fill: $c_white;
    transform: scale(1);
    opacity: 1;
    transition: all 0.2s ease;

    .menu-open & {
      transform: scale(0.9);
      opacity: 0.6;
    }
  }
  &-title {
    display: inline-block;
    padding-left: 1.4rem;
    vertical-align: top;
    color: $c_white;
    user-select: none;
    white-space: nowrap;
    flex-grow: 1;
    text-align: left;
    .navigation__item--initials & {
      padding-left: 0;
    }

    .navigation__item--nested & {
      padding-left: 46.4px;
    }
  }
  &-title-text {
    display: block;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease 0.1s, transform 0.3s ease-in 0.3s;

    .navigation__item--nested & {
      font-size: $fontSize-sm;
    }

    @for $i from 1 through 10 {
      .menu-open .navigation__item:nth-of-type(#{$i}) & {
        transition: opacity 0.3s ease #{$i * 0.04}s, transform 0.3s ease-in #{$i * 0.02}s;
      }
    }
    .menu-open .navigation__item--nested & {
      opacity: 0.9;
    }

    .menu-open & {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  &-link {
    @extend %reset-button;
    display: flex;
    height: 3rem;
    width: 100%;
    align-items: center;
    padding: 0 23px;
    width: $mainBarWidth;
    overflow: hidden;
    transition: width 0.3s ease;
    cursor: pointer;

    .navigation__item--nested & {
      height: 2.5rem;
    }
    .menu-open &,
    .navigation__item--nested & {
      width: $mainBarOpenWidth;
    }

    &--selected {
      background-color: rgba(0, 0, 0, 0.1);
    }
    .navigation__item--initials & {
      cursor: auto;
      padding-left: 0;
    }
  }
}

&__section-title {
  position: relative;
  margin-left: 1rem;
  &:before {
    content: '';
    border-left: 1px dashed $c_chrome_200;
    display: block;
    position: absolute;
    top: -1rem;
    bottom: 0;
    width: 1px;
    left: 0;
  }
  padding: 1rem 1rem 0;
}

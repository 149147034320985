&__info {
  flex-grow: 1;
  padding-left: 1rem;
  &-icon {
    width: 11px !important;
    height: 11px !important;
    margin-left: 0.1em;
    margin-right: 0.3em;
    vertical-align: bottom;
    fill: $c_mint_dark_20;
  }
  &-mediator-text {
    .knowledge-element--task & {
      color: $c_blue_dark_20;
    }
  }
  &-meta {
    text-transform: uppercase;
    font-size: $fontSize-xxs;
    color: $c_coral_dark_10;
    line-height: 1;
    font-weight: bold;
  }
  &-status-text {
    transition: color 0.3s ease;
    .knowledge-element--highlight & {
      color: $c_coral_dark_10;
    }
  }
  &-study {
    color: $c_chrome_120;
    margin-left: 0.5em;
    font-style: italic;
  }
  &-subtitle {
    color: $c_chrome_120;
    font-size: 12px;
    line-height: 1;
  }
}

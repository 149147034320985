.gantt-chart {
  width: 100%;
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  .ReactVirtualized__Grid,
  .ReactVirtualized__List {
    @include custom-scrollbar(0.75rem);
  }
  @import 'cell';
  @import 'date-indicator';
  @import 'footer';
  @import 'grid';
  @import 'header-cell';
  @import 'header-underlay';
  @import 'section-title';
  @import 'task-indicator';
  @import 'task-indicators';
  @import 'tasks';
  @import 'title-placeholder';
}

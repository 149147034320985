&__button {
  @extend %reset-button;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: background-color 0.3s ease;
  width: 100%;
  flex-direction: column;
  .status-button--full-width & {
    padding: 0 0.5rem;
  }
  .status-button--color-green & {
    background-color: $c_aqua_dark_10;
  }
  .status-button--color-yellow & {
    background-color: $c_amber;
  }
  .status-button--color-red & {
    background-color: $c_coral_dark_10;
  }
  .status-button--color-white & {
    background-color: $c_white;
  }
  .status-button--color-light-green & {
    background-color: $c_mint2;
  }
  .status-button--color-grey & {
    background-color: $c_chrome_230;
  }
}

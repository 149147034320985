&__tab {
  @extend %reset-button;
  height: 2rem;
  position: relative;
  cursor: pointer;
  color: $c_chrome_100;
  transition: background-color 0.3s ease;

  &-text {
    z-index: 1;
    position: relative;
    pointer-events: none;
  }

  .tabs--padding-md & {
    padding: 0 1rem;
  }

  .tabs--tab-color-grey & {
    background-color: $c_chrome_220;
  }

  &:hover {
    background-color: $c_chrome_240;
    color: $c_chrome_100;
    .tabs--active-tab-color-grey & {
      background-color: transparent;
    }
  }
  &--state-active {
    color: $c_white;
    transition: color 0.3s ease-in;
    &:hover {
      background-color: transparent;
      color: $c_white;
    }
    .tabs--active-tab-color-grey & {
      color: $c_chrome_100;
      &:hover {
        color: $c_chrome_100;
      }
    }
  }
}

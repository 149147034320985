&__element-list {
  &-person {
    @extend %card-styling;
    margin-bottom: 1rem;
    padding: 0.5rem 0;
    .list-card {
      min-height: auto;
      margin-bottom: 0;
    }
    &-expand-icon {
      .bulk-completion-modal__element-list-person--state-expanded & {
        transform: rotate(180deg);
      }
    }
  }
}

.main-aside {
  background: $c_white;
  border-right: 1px solid $c_bd_grey_base;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: $main-header-height;
  transition: transform 0.3s ease;
  width: $main-aside-width;
  -webkit-overflow-scrolling: touch;

  .main-body--fullscreen & {
    top: 0 !important;
  }

  &--hidden {
    transform: translate(-$main-aside-width);
    transition: transform 0.3s ease;
    z-index: 1;
  }

  &--visible {
    left: 0;
    z-index: 1;
  }

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $c_aqua_dark_10;
    outline: 1px solid slategrey;
  }

  &__toggle-show {
    align-items: center;
    border-bottom: 15px solid transparent;
    border-left: 25px solid $c_aqua_dark_10;
    border-top: 15px solid transparent;
    cursor: pointer;
    display: flex;
    height: 75px;
    left: $main-aside-width;
    margin: auto auto auto 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
    z-index: $z-index-full-screen-aside-toggle;

    &-icon {
      fill: $c_white;
      transform: translateX(-25px);
    }

    &--collapsed {
      transform: translateX(-$main-aside-width) translateY(-50%);
      transition: transform 0.3s ease;
    }
  }
}

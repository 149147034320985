&__mentorship {
  display: flex;
  position: relative;
  &--flag {
    &:after {
      content: '*';
      position: absolute;
      right: -8px;
      top: -2px;
      color: $c_coral_dark_20;
      font-weight: bold;
      font-size: 1.2em;
    }
  }

  &-initials {
    color: $c_aqua_dark_10;
    font-size: 11px;
    .organisation-statusbox-role--theme-red & {
      color: $c_coral_dark_20;
    }
    .organisation-statusbox-role--theme-grey & {
      color: $c_chrome_210;
    }
  }
  &-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
}

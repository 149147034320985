.login {
  height: 100%;
  color: $c_white;
  background-image: url('/assets/images/login.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  @import 'button';
  @import 'checkbox';
  @import 'error';
  @import 'field';
  @import 'footer';
  @import 'form';
  @import 'ie-banner';
  @import 'logo';
  @import 'main';
  @import 'mobile';
  @import 'overlay';
}

.area-element-connection-count {
  color: $c_chrome_120;
  flex-shrink: 0;
  font-size: $fontSize-xs;
  cursor: help;
  .designboard-knowledge-area & {
    width: 2.5rem;
    text-align: center;
  }
  &--color {
    &-red {
      color: $c_coral_dark_20;
    }
  }
}

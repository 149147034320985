.list-header {
  align-items: center;
  border-bottom: 1px solid $c_bd_grey_base;
  display: flex;
  justify-content: space-between;
  min-height: 3rem;
  &--show-top-border {
    border-top: 1px solid $c_bd_grey_base;
  }
  @import 'icon';
  @import 'left';
  @import 'title';
}

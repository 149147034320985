&__header {
  align-items: center;
  display: flex;
  padding: 1rem;
  &-closer {
    flex-shrink: 0;
  }
  &-text {
    flex-grow: 1;
    margin-right: 1rem;
  }
  &-title,
  &-subtitle {
    margin: 0;
  }

  &-subtitle {
    font-size: $fontSize-sm;
    color: $c_txt_grey-2;
    margin-top: 0.25rem;
  }
}

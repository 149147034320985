.organisation-header {
  @include box_shadow(2);
  background-color: $c_white;
  padding: 1rem;
  width: 100%;
  border-bottom: 1px solid $c_subtle_grey_light_10;

  &-filter-input,
  &-filter-select {
    height: 30px;
    width: auto;
  }

  @import 'field';
  @import 'setting';
  @import 'top';
}

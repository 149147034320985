&__title {
  @extend %card-border;
  align-items: center;
  background-color: $c_white;
  color: $c_prime_dark_20;
  display: flex;
  flex-shrink: 0;
  margin-right: 12px;
  padding: .5em;
  width: 250px;
  &-icon {
    fill: $c_aqua_dark_10;
    margin-right: .5em;
  }
}

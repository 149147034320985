&__header {
  align-items: center;
  background-color: $c_chrome_240;
  border-bottom: 1px solid $c_bd_grey_base;
  display: flex;
  padding: 1rem 1.5rem;

  &-left {
    flex-grow: 1;
  }

  &-title {
    p {
      font-size: $uiFontSizeLarge;
    }
  }
}

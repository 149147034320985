&__button {
  @extend %reset-button;
  height: 1rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    margin-top: 3px;
    color: $c_aqua_dark_20;
  }
}

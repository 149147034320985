&__notification-text {
    font-size: 13px;
    color: $c_txt_grey-3;
    margin-top: 0.5em;
    &:before {
        content: '';
        display: inline-block;
        height: 5px;
        width: 5px;
        border-radius: 5px;
        background-color: $c_sekunda_dark_10;
        vertical-align: middle;
        margin-right: 5px;
    }
}
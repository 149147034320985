&__header {
  display: flex;
  padding: 1.5em 24px 3em;
  &-btn-icon {
    margin-right: 0.5em;
  }
  
  &-inactive-text {
    margin-left: 0.2em;
    color: $c_chrome_180;
  }
  &-left {
    flex-grow: 1;
  }
  &-title {
    align-items: center;
    display: flex;
    margin: 0;
  }
}

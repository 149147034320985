@keyframes animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

&__list {
  @extend %reset-list;
  animation: .15s ease-out 0s 1 animation;
  background-color: $c_white;
  border: 1px solid $c_chrome_220;
  border-radius: $bd_radius_sml;
  position: fixed;
  transform: translateY(5px);
  z-index: $z-index-dropdown;
  @include box_shadow(2);
}

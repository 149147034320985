&__title {
  @extend %truncate;
  font-size: $fontSize-sm;

  .table-cell--disabled & {
    opacity: 0.5;
  }

  .table-cell--title-color-red & {
    color: $c_coral_dark_20;
  }

  span[data-tip] {
    cursor: help;
  }

}

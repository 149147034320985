&__info-section {
  &-admin-checkbox {
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &-admin-text {
    text-transform: uppercase;
    font-size: 11px;
    min-height: 1.25rem;
    color: $c_chrome_120;
    margin-bottom: 1rem !important;
  }
  &-field {
    margin-bottom: 0.5rem;
    &-button {
      @extend %reset-button;
      cursor: pointer;
      background-color: $c_subtle_grey;
      display: block;
      border-radius: $bd_radius_sml;
      padding: 0 12px;
      height: 3rem;
      width: 100%;
      text-align: left;
      margin-bottom: 1.5rem;
      &[disabled] {
        cursor: default;
        opacity: 0.45;
        color: $c_black;
      }
    }
    &-label {
      display: block;
      margin-bottom: 1rem;
    }
  }
  &-fields {
    max-width: 500px;
  }
}

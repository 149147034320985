.organisation {
  height: 100%;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @import 'context-menu';
  @import 'tooltip';
}

&__img {
  align-items: center;
  background-color: $c_aqua_dark_10;
  border-radius: 100%;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  margin-right: 1em;
  width: 40px;
  color: $c_white;

  .icon {
    fill: $c_white;
    width: 1rem !important;
    height: 1rem !important;
  }

  &-initials {
    text-transform: uppercase;
  }
}

&__header {
  &-filter {
    padding: 1em 24px;

    &-input {
      height: 30px;
      width: 100%;
    }
  }

  &-title {
    align-items: center;
    border-bottom: 1px solid $c_subtle_grey_light_10;
    border-top: 1px solid $c_subtle_grey_light_10;
    display: flex;
    padding: 1.5em 24px !important;

    &-icon-wrapper {
      cursor: pointer;
      margin-left: auto;
    }

    &-icon {
      fill: $c_aqua_dark_10;
      height: 20px !important;
      width: 20px !important;
    }

    &-text {
      overflow: hidden;
    }
  }
}

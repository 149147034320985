.notification {
  border-radius: $bd_radius_sml !important;
  border-top: 5px solid !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.13), 0 6px 6px rgba(0, 0, 0, 0.15) !important;
  height: 70px !important;
  margin-top: 1rem !important;
  padding: 1rem !important;
  &:first-child {
    margin-top: 5rem !important;
  }

  &-dismiss {
    display: none;
  }
  &-error {
    background-color: $c_white !important;
    border-color: $c_coral_dark_10 !important;
    .notification-title,
    .notification-message {
      color: $c_coral_dark_10 !important;
    }
  }
  &-success {
    background-color: $c_white !important;
    border-color: $c_aqua_dark_10 !important;
    .notification-title,
    .notification-message {
      color: $c_aqua_dark_20 !important;
    }
  }
}

&__text {
  text-align: center;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  border-top: 1px dashed $c_chrome_180;
  padding-top: 0.5rem;
  pointer-events: none;
  user-select: none;
}

&__option {
  &-expand-icon {
    transition: transform 0.3s ease;
    .check-list__option-nest--state-expanded & {
      transform: rotate(-180deg);
    }
  }
  &-right {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
  }
}

&__section {
  padding: 1.5em 1.5rem 3em;

  &--no-padding {
    padding: 1.5em 0 3em;
  }

  &-button {
    .icon {
      fill: $c_aqua_dark_10;
    }
  }

  &-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    @media (max-width: 1023px) {
      margin: 0;
    }
  }
  &-header {
    align-items: center;
    display: flex;
    padding-bottom: 1rem;
    border-bottom: 1px solid $c_subtle_grey_light_10;
    .knowledge-area__section--no-header-border & {
      border: none;
    }
    .knowledge-area__section--no-padding & {
      padding: 1rem 1.5rem;
    }
  }
  &-more-knowledge {
    margin-top: 2em;
  }
  &-more-knowledge-help {
    color: $c_chrome_120;
    font-size: 12px;
    font-style: italic;
    padding: 1rem 1.5rem;
  }
  &-show-more {
    align-items: center;
    display: flex;
    padding: 1rem;
  }
  &-show-more-icon {
    fill: $c_chrome_120;
    flex-shrink: 0;
    height: 10px !important;
    margin-left: 0.5em;
    transition: transform 0.3s ease;
    vertical-align: middle;
    width: 10px !important;
    .knowledge-area__section-more-knowledge--expanded & {
      transform: rotateX(180deg);
    }
  }
  &-show-more-text {
    color: $c_chrome_120;
    cursor: pointer;
    font-size: 12px;
    margin: 0 auto;
  }
  &-title {
    color: $c_chrome_120;
    flex-grow: 1;
    font-size: 12px;
    margin-right: 1rem;
    text-transform: uppercase;
  }
  &-title-icon {
    fill: $c_chrome_120;
  }
}

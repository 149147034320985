&__title {
  display: flex;
  flex-grow: 1;
  &-input {
    font-size: 12px !important;
    background: none;
    height: auto;
    padding: 0;
    flex-grow: 1;
    &:focus {
      background: none;
      box-shadow: none;
    }
  }
  &-input-wrapper {
    flex-grow: 1;
  }
  &-subtitle {
    display: flex;
    font-size: 9px;
    &-text {
      &-required {
        color: $c_coral_dark_10;
      }
      &-mediator {
        color: $c_chrome_120;
        .designboard-knowledge-element__title-subtitle-text-required ~ &:before {
          content: '\00a0-\00a0';
        }
      }
    }
  }
  &-name {
    display: flex;
    &-icon {
      cursor: pointer;
      fill: $c_chrome_120;
      flex-shrink: 0;
      height: 15px !important;
      margin-left: 0.5em;
      opacity: 0;
      transition: opacity 0.3s ease;
      width: 15px !important;
      .designboard-knowledge-element__title-name:hover & {
        opacity: 1;
      }
    }
    &-text {
      @extend %truncate;
      font-size: 12px;
      .designboard-knowledge-element--no-access & {
        color: $c_chrome_120;
      }
    }
  }
  &-wrapper {
    cursor: pointer;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0px;
  }
}

&__organisation {
    background-color: $c_chrome_240;
    flex-grow: 1;
    overflow-y: auto;
    padding: 1.5em 24px 3em;
    &-unit {
        &--child {
            margin-left: 24px;
        }
    }
    &-unit-list {
        @extend %reset-list;
    }
    &-unit-radio-button {
        margin-left: auto;
    }
    &-unit-text {
        flex-grow: 1;
    }
    &-unit-wrapper {
        align-items: center;
        background-color: $c_white;
        border: 1px solid $c_subtle_grey_light_10;
        box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08);
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1em;
        min-height: 55px;
        padding: 1em;
        width: 100%;
    }
}
&__button {
    background: $c_coral_dark_10;
    border: 1px solid $c_coral_dark_10;
    color: $c_white;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 48px;
    position: relative;
    text-align: left;
    width: 176px;

    &:focus {
        outline: none;
    }

    &--complete {
        background: $c_aqua_dark_10;
        border: 1px solid $c_aqua_dark_10;
    }
    &-icon {
        fill: $c_white;
        height: 25px !important;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 25px !important;
    }
}
.dashboard-content {
  flex-grow: 1;
  min-width: 0;
  .ReactVirtualized__Grid {
    &::-webkit-scrollbar {
      height: 13px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 0.4rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $c_chrome_200;
      background: linear-gradient(to right, $c_aqua_dark-10, $c_aqua_dark_20);
      outline: none;
      border-radius: 0.4rem;
    }
  }
  @import 'empty-text';
  @import 'name-box';
  @import 'persons-row';
}

.error-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  &--full-height {
    height: 100%;
  }

  .role-nav &,
  .knowledge-area & {
    padding: 3rem 1rem;
  }

  @import 'badge';
  @import 'body';
  @import 'title';
}

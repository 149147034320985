&__icon {
  &-left {
    fill: $c_aqua_dark_10;
    flex-shrink: 0;
    height: 1.5rem !important;
    margin-right: 1rem;
    width: 1.5rem !important;
  }
  &-right {
    fill: $c_chrome_180;
    flex-shrink: 0;
    height: 14px !important;
    margin-left: auto;
    transition: transform 0.3s ease;
    width: 14px !important;
    .card-link:hover & {
      transform: translateX(2px);
    }
  }
}

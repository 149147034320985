&__section {
  margin-bottom: 2rem;
  &-add-text {
    align-items: center;
    color: $c_blue_dark_20;
    cursor: pointer;
    display: inline;
    font-size: 12px;
    margin: 1em 0;
  }
  &-add-icon {
    fill: $c_chrome_120;
    margin-right: 0.5em;
  }
  &-additional-options {
    align-items: center;
    display: flex;
  }
  &-admin-checkbox {
    margin-left: auto;
  }
  &-body {
    padding: 0 2rem;
    &--grid {
      display: inline-grid;
      grid-auto-flow: column;
      column-gap: 2rem;
    }
  }
  &-body-title {
    color: $c_chrome_120;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin-bottom: 2rem;
  }
  &-deactivate-btn,
  &-delete-btn {
    margin-right: 1em;
    margin-top: 2rem;
  }
  &-empty-text {
    margin: 1rem 0;
  }
  &-field {
    flex-basis: calc(100% / 4 - 20px);
    flex-shrink: 0;
    margin: 0 10px 1em;
    min-width: 0;
    @media (max-width: 1140px) {
      flex-basis: calc(100% / 2 - 20px);
    }
    @media (max-width: 1024px) {
      flex-basis: 100%;
      margin: 0 0 1em;
    }
  }
  &-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    @media (max-width: 1024px) {
      margin: 0;
    }
  }
  &-header {
    align-items: center;
    display: flex;
    margin-bottom: 2em;
    border-bottom: 1px solid $c_bd_grey_base;
    padding: 1rem;
  }
  &-password-btn {
    margin-top: 0.5em;
  }
  &-title {
    color: $c_chrome_120;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }
  &-title-icon {
    fill: $c_chrome_120;
  }
}

.input-label {
  align-self: flex-start;
  display: inline-block;
  margin-bottom: 0.5rem;
  color: $c_txt_grey-3;
  font-size: $fontSize-sm;
  position: relative;
  user-select: none;
  .radio-button ~ & {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    align-self: center;
  }

  &--no-margin {
    margin-bottom: 0;
  }

  &--required {
    &:after {
      content: '*';
      color: $c_coral_dark_10;
      font-size: $fontSize-lg;
      position: absolute;
      top: -0.2em;
      right: -0.5em;
    }
  }
  &--size {
    &-sm {
      font-size: $fontSize-xs;
    }
  }

  &--theme {
    &-caps {
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
  }
}

.image {
  background-color: $c_chrome_60;
  display: flex;
  justify-content: center;
  @import 'img';
  a {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

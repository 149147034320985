&__info {
  display: block;
  width: 100%;
  .drag-and-drop-handle ~ & {
    margin-left: 1rem;
  }
  &-enabled-options {
    color: $c_chrome_120;
    font-size: 12px;
    line-height: 1;
    &-required {
      color: $c_coral_dark_10;
    }
    &-mediator {
      color: $c_chrome_120;
      .editor-element__info-enabled-options-required ~ &:before {
        content: '-';
      }
    }
  }
  &-input {
    color: inherit;
    font-size: inherit;
    width: 100%;
  }
  &-title {
    @extend %reset-button;
    line-height: 1;
    display: block;
    font-size: inherit;

    color: $c_black;
  }
  &-title-icon {
    cursor: pointer;
    fill: $c_chrome_120;
    height: 15px !important;
    margin-left: 0.5em;
    opacity: 0;
    transition: opacity 0.3s ease;
    width: 15px !important;
    .editor-element:hover & {
      opacity: 1;
    }
  }
  &-title-input {
    background: none;
    height: auto;
    padding: 0;
    width: 100%;
    &:focus {
      background: none;
      box-shadow: none;
    }
  }
}

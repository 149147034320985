&__section {
  border-bottom: 1px solid $c_subtle_grey_light_10;
  padding: 1.5em 24px 3em;
  &-button {
    margin-bottom: 2rem;
  }
  &-field {
    flex-basis: calc(100% / 2 - 20px);
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0 10px 2em;
    min-width: 0;
    @media (max-width: 1024px) {
      flex-basis: 100%;
      margin: 0 0 1em;
    }
  }

  &-grid,
  &-setting-fields {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    @media (max-width: 1024px) {
      margin: 0;
    }
  }
  &-header {
    align-items: center;
    display: flex;
    margin-bottom: 2em;
  }
  &-info {
    margin-bottom: 2rem;
    font-style: italic;
  }
  &-setting-fields {
    max-width: 600px;
  }
  &-status {
    margin: 0 0 1rem !important;
  }
  &-text-list {
    margin: 2rem 0;
  }
  &-title {
    color: $c_chrome_120;
    font-size: 12px;
    text-transform: uppercase;
  }
  &-title-icon {
    fill: $c_chrome_120;
  }
}

&__icon {
  fill: $c_chrome_160;
  margin: 0 0.5rem;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  stroke: $c_chrome_160;
  stroke-width: .5px;
  height: 40px !important;

  .table-header-cell--state-sort-active & {
    opacity: 1;
  }
  .table-header-cell--state-sort-desc & {
    transform: rotateX(180deg);
  }
}

.organisation-statusbox-knowledge {
  @extend %reset-button;
  border-bottom: 1px solid $c_chrome_210;
  height: 35px;
  //@include competenceMatrixCell();
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: center;
  height: 35px;
  .organisation-board-unit--read-only &,
  &--theme-grey {
    cursor: auto;
  }

  @import 'dash';
  @import 'content';
  @import 'point';
  @import 'star';
}

&__trigger {
  cursor: pointer;
  &--padding {
    &-large, &-lg {
      padding: 1rem;
    }
  }
  &-icon {
    height: 10px !important;
    margin-left: 0.5rem;
    width: 10px !important;
    fill: $c_chrome_170;
    .context-menu__trigger--large &,
    .context-menu__trigger--lg & {
      height: 1rem !important;
      width: 1rem !important;
    }
    .context-menu__trigger:hover &,
    .context-menu--open & {
      fill: $c_blue_dark_10;
    }
  }
}

&__title {
  @extend %truncate;
  font-size: $fontSize-sm;

  .table-cell-editable--text-uppercase & {
    text-transform: uppercase;
  }

  .table-cell--title-color-red & {
    color: $c_coral_dark_20;
  }

  span[data-tip] {
    cursor: help;
  }
}

.statusbox {
    align-items: center;
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 5%;
    padding: 0 3px;
    @media(max-width: 1760px) {
        min-width: 10%;
    }
    @media(max-width: 1200px) {
        min-width: 20%;
    }
    @import 'inner';
    @import 'mediator';
    @import 'progress';
}
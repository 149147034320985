&__box {
  background: $c_white;
  border-radius: 4px;
  border: 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  max-height: 95%;
  max-width: 1000px;
  min-width: 300px;
  outline: none;
  overflow: auto;
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top; */

  .modal--full-width & {
    width: 100%;
  }

  &--after-open {
    transform: scale(1) translate(-50%, -50%);
    transition: all 200ms ease-in;
  }

  &--before-close {
    transform: scale(0.5) translate(-50%, -50%);
    transition: all 100ms ease-in;
  }
}

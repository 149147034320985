&__icon {
  width: 1.3rem !important;
  height: 1.3rem !important;
  fill: $c_aqua_dark_10 !important;

  &-wrapper {
    border: 1px solid $c_aqua_dark_10;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3rem;
    margin-right: 1rem;
  }
}
&__header {
    background-color: $c_chrome_240;
    display: flex;
    height: 50px;
    min-height: 20px;
    overflow: hidden;
    padding: 0 24px;
    position: absolute;
    top: 86px;
    transform: translate3d(0, 0, 0);
    transition: all .5s ease;
    width: 100%;
    z-index: 1;
    .dashboard--changing-element-mediator & {
        top: 136px;
    }
    &-person-icon {
        fill: $c_aqua_dark_10;
        flex-shrink: 0;
        margin-right: .5em;
        height: 17px !important;
        width: 17px !important;
    }
    &-person {
        @extend %card-border;        
        align-items: center;
        background-color: $c_white;
        display: flex;
        justify-content: center;
        overflow: hidden;
        padding: 0 .5em;
        width: 100%;
    } 
    &-person-wrapper {
        display: flex;
        flex-basis: 0;
        flex-grow: 1;
        min-width: 5%;
        padding: 0 3px;
    }
    &-persons {
        display: flex;
        flex-grow: 1;
        overflow: hidden;
    }
    &-persons-wrapper {
        display: flex;
        transition: transform .3s ease;
        width: 100%;
    }
    &-name {
        @extend %truncate;
        color: $c_prime_dark_20;
    }
    &-title {
        @extend %card-border; 
        align-items: center;
        background-color: $c_white;
        color: $c_prime_dark_20;
        display: flex;
        flex-shrink: 0;
        margin-right: 12px;
        padding: .5em;
        width: 251px;
    }
    &-title-icon {
        fill: $c_aqua_dark_10;
        flex-shrink: 0;
        margin-right: .5em;
    }
}
&__clear-button {
  @extend %reset-button;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
}

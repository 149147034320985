&__line {
  height: 5px;
  background-color: $c_chrome_220;
  .password-score-bar--weak & {
    &:nth-of-type(1) {
      background-color: $c_coral_dark-20;
    }
  }
  .password-score-bar--medium & {
    &:nth-of-type(1),
    &:nth-of-type(2) {
      background-color: $c_yellow_dark-20;
    }
  }
  .password-score-bar--good & {
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3) {
      background-color: $c_blue_dark-10;
    }
  }
  .password-score-bar--strong & {
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      background-color: $c_aqua-dark-10;
    }
  }
}

&__inner {
  align-items: center;
  color: $c_aqua_dark_10;
  cursor: pointer;
  display: flex;
  height: 18px;
  justify-content: center;
  position: relative;
  text-align: center;
  transition: all 0.3s ease;
  width: 100%;
  &:hover {
    @include box_shadow(2);
  }
  .dashboard--changing-element-status & {
  }
  .dashboard--changing-element-mediator & {
    &--complete,
    &--invalid,
    &--irrelevant,
    &--red,
    &--yellow,
    &--undetermined {
      cursor: auto;
      opacity: 0.1;
    }
    &--incomplete,
    &--required {
      cursor: pointer;
      &:hover {
        @include box_shadow(2);
      }
    }
  }
  &:first-of-type {
    margin-left: 0px;
  }
  &--complete {
    background: $c_aqua_dark_10;
    color: $c_white;
  }
  &--invalid {
    background: $c_coral_dark_10;
    color: $c_white;
  }
  &--irrelevant {
    background: $c_chrome_220;
  }
  &--incomplete {
    background: $c_mint2;
  }
  &--locked {
    cursor: auto;
    &:hover {
      box-shadow: none;
    }
  }
  &--yellow {
    background: $c_amber;
    color: $c_white;
  }
  &--red {
    background: $c_coral_dark_10;
    color: $c_white;
  }
  &--required {
    background: $c_coral_dark_10;
    color: $c_white;
  }
  &--undetermined {
    background: $c_prime;
  }
  &.selected {
    @include box_shadow(4);
    background: $c_blue_dark_20;
    color: white;
  }
}
.organisation-statusbox-progress {
  border-radius: 5px;
  flex-shrink: 0;
  height: 3px;
  overflow: hidden;
  width: 30px;

  .organisation-statusbox-role &,
  .organisation-statusbox-knowledge & {
    margin-top: 0.2rem;
  }

  &--color {
    &-green {
      background-color: $c_aqua_light_10;
    }
    &-red {
      background-color: $c_coral_light_20;
    }
    &-grey {
      background-color: $c_chrome_240;
    }
  }
  @import 'bar';
}

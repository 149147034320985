&__title {
  @extend %truncate;
  font-size: $fontSize-sm;
  .table-header-cell--state-sort-active & {
    font-weight: bold;
  }

  &-tooltip {
    margin-left: 0.5rem;
  }
}

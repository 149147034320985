&__button {
  @extend %reset-button;
  width: 20px;
  height: 20px;
  background-color: $c_chrome_220;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: $fontSize-xs;
  color: $c_aqua_dark_20;
  font-weight: bold;
  border-radius: 2px;
  &[disabled] {
    opacity: 0.4;
    cursor: auto;
  }

  &--green {
    color: $c_white;
    background-color: $c_aqua_dark_10;
  }
  &-icon {
    fill: $c_white;
    height: 15px !important;
    overflow: visible;
    stroke: $c_white;
    width: 15px !important;
  }
}

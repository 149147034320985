&__title {
  @extend %reset-legend;
  color: $c_txt_grey-3;
  display: inline-block;
  font-size: $fontSize-sm;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  user-select: none;
  margin-bottom: .5rem;
}

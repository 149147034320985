.training-session {
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  @import 'footer';
  @import 'element';
  @import 'elements';
  @import 'header';
  @import 'main';
  @import 'participant';
  @import 'participants';
  @import 'settings';
}

&__permission {
  border-radius: $bd_radius_sml;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: $c_white;
  align-items: center;

  &--has-children {
    padding: 0;
    display: block;
  }

  .organisation-settings__permissions-list--nested & {
    padding: 0.5rem 1rem 0.5rem 2rem;
    &:nth-child(odd) {
      background-color: $c_subtle_grey-light-10;
    }
  }

  &:nth-child(odd) {
    background-color: $c_subtle_grey-light-20;
  }

  &-inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }

  &-label {
    margin-right: 1rem;
  }

  &-switch {
    flex-shrink: 0;
  }

  &-subtitle {
    color: $c_txt_grey-3;
    font-size: $fontSize-sm;
  }
}

.overlay {
  @include box-shadow(1);
  background-color: $c_white;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: $mainBarWidth;
  position: fixed;
  right: 0;
  top: $main-header-height;
  z-index: $z-index-overlay;
  border-top: 3px solid $c_aqua_dark_10;
  transition: all 0.3s ease;

  .menu-open & {
    left: 0px;
  }

  .page--fullscreen ~ #overlay-root & {
    top: 0;
    left: 0;
  }

  @import 'body';
  @import 'close';
  @import 'header';
}

&__description {
    background-color: $c_white;
    box-shadow: inset 0px 5px 5px 0px rgba(0, 0, 0, 0.08);
    flex-basis: 100%;
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transform: rotateX(90deg);
    transform-origin: top;
    transition: all .3s ease;
    .knowledge-element.show-description & {
        background-color: $c_chrome_240;
        height: 200px;
        opacity: 1;
        transform: rotateX(0deg);
    }
    &-button {
        background: none;
        border: none;
        height: 25px;
        margin-right: .5em;
        padding: 0;
        width: 25px;
        &:focus {
            outline: none;
        }
    }
    &-container {
        height: 200px;
        overflow-y: auto;
        padding: 2em 24px;
        transform: translateX(50px);
        transition: transform .3s ease;
        .knowledge-element.show-description & {
            transform: translateX(0px);
        }
    }
    &-icon {
        cursor: pointer;
        fill: $c_chrome_120;
        height: 25px !important;
        transition: fill .3s ease;
        width: 25px !important;
        .knowledge-element.show-description & {
            fill: $c_blue_dark_10;
        }
    }
    &-link {
        display: block;
        margin: 0.5em 0;
    }
    &-link-label {
        color: $c_chrome_120;
        font-size: 12px;
        text-transform: uppercase;
    }
    &-text {
        @extend %rich-text;
        color: $c_chrome_80;
        margin-bottom: 1em;
    }
}
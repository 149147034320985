&__header {
  background-color: $c_white;
  border-bottom: 1px solid $c_bd_grey_base;
  &-top {
    padding: 1rem;
    height: $header-top-height;
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-top: 1px solid $c_bd_grey_base;
    &-left,
    &-right {
      display: flex;
    }
  }
  &-button {
    height: 2rem;
    margin-left: 1rem;
  }
  &-column-button {
    @extend %reset-button;
    background-color: $c_chrome_180;
    margin: 0 0.25rem;
    padding: 0.25rem 0.5rem;
    border-radius: $bd_radius_sml;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: $c_white;
    span {
      margin-left: 0.25rem;
    }
    .icon {
      fill: $c_white;
    }
  }
  &-search-input {
    min-width: 16rem !important;
    min-height: 2rem !important;
    
  }
  &-column-select {
    margin-right: 1rem;
    .multi-select__control {
      height: 2rem !important;
      min-height: 0 !important;
      padding: 0 !important;
    }
  }
}

.table-header-cell {
  border: 1px solid $c_bd_grey_base;
  display: flex;
  align-items: center;
  .content-administration & {
    border-top-width: 0px !important;
    border-left-width: 0px !important;
  }
  &--align-end {
    align-items: flex-end;
  }
  &--center-content {
    display: flex;
    justify-content: center;
  }
  &--sortable {
    cursor: pointer;
    user-select: none;
  }
  &--hidden {
    visibility: hidden;
  }
  &--rotated {
    white-space: nowrap;
    position: relative;
    display: block !important;
    border-width: 0px 0px 1px 0px !important;
    &.table-header-cell--state-sort-active {
      font-weight: bold;
    }
    div {
      transform: translate(-8px, 27px) rotate(315deg);
      width: 250px;
      height: 30px;
      border-bottom: 1px solid $c_bd_grey_base;
      display: flex;
      align-items: center;
      z-index: 1;
      position: relative;
    }
  }
  @import 'content';
  @import 'context-menu';
  @import 'icon';
  @import 'subtitle';
  @import 'title';
}

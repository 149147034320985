&__content {
  @include custom-scrollbar();
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  .aside--no-scroll & {
    overflow: hidden;
  }
}

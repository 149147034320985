.tabs {
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  &--rounded {
    border-radius: $bd_radius_sml;
  }
  &--round {
    border-radius: 50px;
  }
  &--show-background {
    background-color: $c_subtle_grey;
  }
  &--show-border {
    border: 1px solid $c_subtle_grey_dark_20;
  }
  .aside & {
    margin: 1rem;
  }
  @import 'active-background';
  @import 'tab';
}

&__list {
  @extend %reset-list;
  border-top: 1px solid $c_subtle_grey_light_10;
  .side-nav-list-section--virtual & {
    flex-grow: 1;
  }
  .side-nav-title + & {
    border-top: none;
  }
}

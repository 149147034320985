&__handle {
    cursor: grab;
    margin-right: .7em;
    width: 20px;
    .designboard-knowledge-element--add-element &,
    &--disabled {
        cursor: not-allowed;
    }
    &-line {
        border-bottom: 1px solid $c_chrome_120;
        display: block;
        height: 1px;
        margin: 3px 0;
        .designboard-knowledge-element--add-element &,
        .designboard-knowledge-element__handle--disabled & {
            border-bottom: 1px solid $c_chrome_200;
        }
    }
}
&__form {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: 100%;
  .inline-select-editor--inline-label & {
    align-items: center;
    flex-direction: row;
  }
  &-btn-cancel,
  &-btn-submit {
    height: auto;
  }
  &-btn-submit {
    margin: 0 0.5em 0 2em;
  }
  &-label {
    margin: 0 0 1em 0;
    .inline-select-editor--inline-label & {
      margin: 0 1em 0 0;
    }
  }
  &-select {
    width: 100%;
    &:focus {
      outline: none;
    }
    &[disabled] {
      opacity: 0.45;
    }
  }
}

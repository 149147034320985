.box-message {
  display: flex;
  padding: 1rem;
  background-color: $c_blue_light_20;
  color: $c_blue_dark_20;
  align-items: center;
  white-space: pre-wrap;
  &--padding-horizontal {
    &-l {
      padding: 1.5rem;
    }
  }
  &--type {
    &-error {
      color: $c_white;
      background-color: $c_coral_dark_20;
    }
    &-warning {
      color: $c_yellow_dark_50;
      background-color: $c_yellow_light_20;
    }
  }
  &--spacing {
    &-md {
      margin-bottom: 1rem;
    }
  }
  @import 'icon';
  @import 'text';
}

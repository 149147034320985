&__active-background {
  background-color: $c_aqua_dark_10;
  border-radius: $bd_radius_sml;
  height: 2rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .tabs--active-tab-color-grey & {
    background-color: $c_chrome_240;
  }
}
